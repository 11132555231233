<template>
  <Accordion
    :info="info"
    @is-active="setActiveTab"
    :verified="isFilled"
    icon="https://res.cloudinary.com/djalafcj9/image/upload/v1668692227/getequityV2/fou-info_wiqluf.svg"
  >
    <form
      @submit.prevent="checkFormValidation"
      class="tw-flex tw-justify-between tw-mt-12 md:tw-px-8 lg:tw-px-16 tw-flex-wrap founder__form tw-relative"
    >
      <Loader v-if="loading" />
      <div
        class="tw-flex tw-flex-wrap tw-w-full tw-justify-between"
        v-for="(founder, index) in founders"
        :key="index"
      >
        <div class="tw-w-full tw-mb-2 founder-form__div">
          <InputField
            label="First Name"
            :currentValue="founder.fname"
            name="fname"
            @input-changed="storeValue($event, index)"
            :validate="validateForm"
            @is-valid="handleValidation"
          />
        </div>
        <div class="tw-w-full tw-mb-2 founder-form__div">
          <InputField
            label="Last Name"
            name="lname"
            :currentValue="founder.lname"
            @input-changed="storeValue($event, index)"
            :validate="validateForm"
            @is-valid="handleValidation"
          />
        </div>
        <div class="tw-w-full tw-mb-2 founder-form__div">
          <InputField
            label="Email Address"
            :currentValue="founder.email"
            name="email"
            @input-changed="storeValue($event, index)"
            :validate="validateForm"
            @is-valid="handleValidation"
          />
        </div>
        <div class="tw-w-full tw-mb-2 founder-form__div">
          <InputField
            label="Role"
            :currentValue="founder.role"
            name="role"
            @input-changed="storeValue($event, index)"
            :validate="validateForm"
            @is-valid="handleValidation"
          />
        </div>
        <div class="tw-w-full">
          <span
            class="tw-mt-2 tw-flex tw-justify-between tw-items-center member__button tw-cursor-pointer tw-w-max"
            @click="removeFounder(index, founder._id)"
            v-if="founders.length > 1"
          >
            <img
              class="tw-mr-2"
              src="@/assets/img/delete.svg"
              alt="delete icon"
            />
            Remove
          </span>
        </div>
      </div>
      <div class="tw-w-full">
        <span
          class="tw-mt-3 tw-flex tw-h-8 tw-items-center member__button tw-cursor-pointer"
          @click="addFounder"
        >
          <img src="@/assets/img/add-circle.svg" class="tw-w-5 tw-h-5" />
          <p class="tw-text-sm tw-ml-2">Add another Founder</p>
        </span>
      </div>
      <div
        class="tw-w-full tw-mt-8"
        v-if="
          ($route.name === 'CreateAsset' && !$route.query.preview) ||
            ($route.name !== 'CreateAsset' &&
              !dealInfo &&
              !$route.query.preview) ||
            ($route.name !== 'CreateAsset' &&
              dealInfo &&
              
              !$route.query.preview)
        "
      >
        <div class="tw-w-32">
          <DealButton
            text="Continue"
            class="tw-text-sm"
            :disabled="
              loading || ($route.name === 'CreateAsset' && !$route.query?.id)
            "
          />
        </div>
      </div>
    </form>
  </Accordion>
</template>

<script>
import InputField from "@/components/general/InputField";
import DealButton from "@/components/deals/DealsButton";
import Accordion from "@/components/setup/Accordion";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "founder",
  emits: ["is-active"],
  data() {
    return {
      info: {
        title: "Founder Info",
        description: "",
      },
      loading: false,
      founders: [{ fname: "", lname: "", email: "", role: "" }],
      validateForm: false,
      validationResult: [],
    };
  },
  components: {
    InputField,
    DealButton,
    Accordion,
  },
  inject: ["activeTab"],
  watch: {
    asset(val) {
      if (
        val &&
        val.founders &&
        val.founders.length > 0 &&
        !this.$route.query.new
      ) {
        this.setValues();
      }
    },
    validationResult: {
      deep: true,
      handler(val) {
        if (
          val.length / 4 === Object.keys(this.founders).length &&
          !val.includes(false)
        ) {
          this.saveFounders();
        }
      },
    },
  },
  mounted() {
    if (this.hasFounderInfo()) {
      this.setValues();
    } else {
      this.founders = [{ fname: "", lname: "", email: "", role: "" }];
    }
  },
  computed: {
    ...mapGetters("Asset", ["asset"]),
    ...mapGetters("Deals", ["dealInfo"]),
    isFilled() {
      let isFilled = true;
      this.founders.map((founder) => {
        const hasEmpty = Object.values(founder).includes("");
        if (hasEmpty) {
          isFilled = false;
          return;
        }
      });
      return isFilled;
    },
  },
  methods: {
    ...mapActions("Asset", ["updateAsset", "fetchAsset", "deleteFounder"]),
    setActiveTab(title) {
      this.$emit("is-active", title);
    },
    hasFounderInfo() {
      return (
        (this.asset &&
          this.asset.founders?.length > 0 &&
          this.$route.query.assetName) ||
        (this.asset &&
          this.asset.founders?.length > 0 &&
          this.$route.name === "DealInfo")
      );
    },
    storeValue(val, index) {
      this.founders[index][val.name] = val.value;
    },
    setValues() {
      let founders = [];
      this.asset.founders.map((founder) => {
        let obj = {
          fname: founder.fname,
          lname: founder.lname,
          email: founder.email,
          role: founder.role,
          _id: founder._id,
        };
        founders.push(obj);
      });
      this.founders = founders;
    },
    checkFormValidation() {
      this.validationResult = [];
      this.validateForm = true;
      setTimeout(() => {
        this.validateForm = false;
      }, 1000);
    },

    handleValidation(value) {
      this.validationResult.push(value);
    },
    addFounder() {
      this.founders.push({ fname: "", lname: "", email: "", role: "" });
    },
    async saveFounders() {
      this.loading = true;
      try {
        await this.updateAsset({
          asset: { founders: this.founders },
          assetId: this.$route.query.id ? this.$route.query.id : this.asset._id,
        });
        this.fetchAsset(
          this.$route.query.id ? this.$route.query.id : this.asset._id
        );
        this.$emit("is-active", "Socials");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        return error;
      }
    },
    async removeFounder(index, id) {
      console.log(index, id);
      if (id) {
        try {
          this.loading = true;
          await this.deleteFounder({ id, asset: this.asset._id });
          this.loading = false;
        } catch (error) {
          this.loading = false;

          return error;
        }
      }
      this.founders.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.founder {
  height: 72px;
  border: 1px solid #f1f3f6;
  box-sizing: border-box;
  border-radius: 4px;
  transition: height 800ms ease-out 0s;
  overflow-y: hidden;
  max-width: 818px;
  margin-left: auto;
  margin-right: auto;
  &--long {
    overflow-y: scroll;
    height: 700px;
  }
  &__button {
    width: 24px;
    height: 24px;
    &:hover {
      background: inherit;
    }
  }
  &__input {
    &::placeholder,
    &--empty {
      color: #a3a8b3;
    }
    border: 1px solid #dde1e9;
    box-sizing: border-box;
    border-radius: 4px;
  }
  &__form {
    gap: 20px;
    justify-content: center;
  }
  &-form {
    &__div {
      margin-bottom: 24px;
    }
  }
}

.member {
  &__button {
    &:hover {
      background: transparent;
      color: inherit;
    }
  }
}

.required {
  color: indianred;
}
@media (min-width: 768px) {
  .gap {
    gap: 20px;
  }

  .founder {
    &__form {
      gap: 20px;
      padding-left: 0;
      padding-right: 0;
    }
    &-form__div {
      flex-basis: 48%;
      max-width: 335px;
    }
  }
}
</style>
