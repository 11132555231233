<template>
  <section id="deals">
    <div v-if="!loading" class="container tw-px-4">
      <div
        class="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-mb-10 lg:tw-px-4 md:tw-items-center"
      >
        <h3 class="tw-text-2xl tw-font-bold tw-text-secondary">
          My Deals
        </h3>
        <div class="sm:tw-flex">
          <div
            class="tw-flex tw-justify-end tw-items-center tw-mb-4 md:tw-mb-0"
          >
            <Search
              class="sm:tw-mr-2"
              :showSearchField="showSearchField"
              @search-click="showSearchField = !showSearchField"
              @search-input="filterDeals"
            />
            <div
              class="tw-border-l tw-border-black-light tw-pl-2 sm:tw-mr-4 tw-h-6 tw-flex tw-items-center"
            >
              <Filter
                title="Deals"
                :filterOptions="filterOptions"
                @filter-click="showFilterOptions = !showFilterOptions"
                :showFilterOptions="showFilterOptions"
                @filter-selected="filterDeals"
                @mouseleave="startTimeout"
                @mouseenter="deleteTimeout"
              />
            </div>
          </div>
          <DealsButton
            :disabled="deals.length >= 5 && plan.type.toLowerCase() === 'free'"
            @click="
              deals.length >= 5 && plan.type.toLowerCase() === 'free'
                ? null
                : (createDeal = true)
            "
            class="deals__button"
            text="Create a deal"
          />
        </div>
      </div>
      <SubscriptionLimitStatus
        @show-plans="
          $router.push({
            name: 'SubscriptionPlans',
            query: { plans: true },
          })
        "
      />
      <div v-if="deals && deals.length > 0" class="lg:tw-px-4">
        <div
          class="tw-w-40 tw-flex tw-items-center tw-text-sm tw-cursor-pointer hover:tw-text-primary tw-text-black-light tw-mb-2"
          @click="toggleView = !toggleView"
        >
          <img
            v-if="!toggleView"
            class="tw-w-5 tw-h-4"
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1641456560/getequityV2/view-list_clrihl.png"
            alt="list view"
            loading="lazy"
          />
          <img
            v-if="toggleView"
            class="tw-w-5 tw-h-4"
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1641456560/getequityV2/view-grid_smsaje.png"
            alt="card view"
            loading="lazy"
          />
          <span v-if="!toggleView" class="tw-ml-2">Switch to list view</span>
          <span v-if="toggleView" class="tw-ml-2">Switch to card view</span>
        </div>
        <div>
          <div v-show="!toggleView" class="card-container">
            <DealsCard v-for="deal in deals" :key="deal._id" :deal="deal" />
          </div>
          <div
            v-show="toggleView"
            class="table-container tw-bg-white tw-rounded-lg"
          >
            <DealsTable
              :tableHeaders="tableHeaders"
              :tableRows="tableRows"
              :columnNumber="tableHeaders.length - 2"
              :columnWidth="160"
            />
          </div>
          <div class="">
            <Pagination
              :totalPage="requestPages"
              :currentPage="requestPage"
              :maxVisible="9"
              @pageChange="fetchDeals"
            />
          </div>
        </div>
      </div>
      <EmptyState v-if="deals && deals.length === 0">
        <template v-slot:message>
          <p class="message tw-text-center tw-text-gray tw-my-3">
            There are no deals to show at the moment
          </p>
          <p
            @click="createDeal = true"
            class="tw-text-primary tw-cursor-pointer"
          >
            Create a deal
          </p>
        </template>
      </EmptyState>
      <AssetSearch
        :show="createDeal"
        @close-asset-search="createDeal = false"
        @asset-selected="goToDealsForm"
      />
    </div>
    <Loader v-if="loading" />
  </section>
</template>

<script>
  import SubscriptionLimitStatus from "@/components/account/subscription/SubscriptionLimitStatus.vue";
import DealsCard from "@/components/deals/DealCard";
import DealsButton from "@/components/deals/DealsButton";
import AssetSearch from "@/components/deals/assets/AssetSearch";
import Filter from "@/components/general/Filter";
import Search from "@/components/general/Search";
import Pagination from "@/components/navigation/Pagination";
import ContentLoader from "@/components/notification/ContentLoader";
import EmptyState from "@/components/notification/EmptyState";
import DealsTable from "@/components/tables/DealsTable";
import filter from "@/mixins/filter";
import search from "@/mixins/search";
import { formatAmountToDollar } from "@/utils/helpers.js";
import { mapActions, mapGetters } from "vuex";
  export default {
    name: "Deals",
    data() {
      return {
        filterOptions: ["Pending", "Active", "Rejected"],
        tableHeaders: [
          "Deal name",
          "% Raised",
          "Deal Value",
          "Market Cap",
          "Status",
          "",
          "",
        ],
        tableRows: [],
        loading: false,
        createDeal: false,
        deals: "",
        showSearchField: false,
        toggleView: false,
      };
    },
    mixins: [filter, search],
    components: {
      DealsButton,
      Filter,
      DealsCard,
      DealsTable,
      AssetSearch,
      EmptyState,
      ContentLoader,
      Search,
      Pagination,
      SubscriptionLimitStatus,
    },
    created() {
      this.fetchDeals();
    },
    computed: {
      ...mapGetters("organisationModule", ["plan"]),
      ...mapGetters("Asset", ["draftAssets"]),
      ...mapGetters("Deals", [
        "dealRequests",
        "totalRequests",
        "requestPage",
        "requestPages",
      ]),
    },
    mounted() {
      if (this.$route.query.showAssetSearch) {
        this.createDeal = true;
      }
    },

    methods: {
      ...mapActions("Asset", ["fetchAssetDraft"]),
      ...mapActions("Deals", ["getDealRequests"]),
      ...mapActions("organisationModule", ["getOrganisationPlan"]),

      formatAmountToDollar,

      async fetchDeals(page) {
        this.loading = true;
        const query = { page: page ? page : 1, limit: 15 };
        try {
          await Promise.all([
            this.getDealRequests(query),
            this.fetchAssetDraft(),
            this.getOrganisationPlan(),
          ]);
          this.loading = false;
        } catch (error) {
          this.loading = false;
          return error;
        }
      },
      filterDeals({ value, type }) {
        if (value && value !== "All") {
          const deals = this.dealRequests;
          const filterDeals = deals.filter((deal) => {
            if (type === "name") {
              return deal[type].toLowerCase().includes(value);
            } else {
              return deal[type] === value;
            }
          });
          this.deals = filterDeals;
        } else {
          this.deals = this.dealRequests;
        }
        this.showFilterOptions = false;
      },
      goToDealsForm(assetId) {
        this.$router.push({ name: "CreateDeal", query: { assetId } });
      },
      updateTableRow() {
        this.tableRows = this.deals.map((deal) => {
          let raised = null;
          if (deal.token?.total_raised) {
            raised =
              deal.token?.total_raised && deal.token?.raise_amount
                ? ((deal.token.total_raised * 10) / deal.token.raise_amount) *
                  100
                : 0;
          } else {
            raised =
              deal?.total_raised &&
              deal?.raise_amount &&
              deal?.total_raised !== 0 &&
              deal?.raise_amount !== 0
                ? (deal.total_raised / deal.raise_amount) * 100
                : 0;
          }
          return {
            name: `<span class="tw-font-semibold">${deal.name}</span>`,
            raised: `${raised.toFixed(2)}%`,
            currentValue: formatAmountToDollar(deal.price?.sell, 2, deal.currency || 'USD'),
            marketCap: formatAmountToDollar(deal.total_supply, 2, deal.currency || 'USD'),
            status: deal.status,
            id: `<span class="tw-hidden">${deal._id}</span>`,
          };
        });
      },
    },
    watch: {
      dealRequests(val) {
        this.deals = val;
        this.updateTableRow();
      },
    },
  };
</script>

<style lang="scss" scoped>
  #deals {
    width: 100%;
    overflow: hidden;
    padding-bottom: 80px;

    .container {
      @include fadeIn;

      @media screen and (min-width: 1024px) {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .card-container {
      @media screen and (min-width: 640px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0.5rem;
      }

      @media screen and (min-width: 768px) {
        column-gap: 1rem;
      }

      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .deals__button {
      @media screen and (min-width: 768px) {
        max-width: 160px;
      }
    }

    .table-container {
      overflow: auto;
    }
  }
</style>
