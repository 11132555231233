<template>
  <Modal :show="true" @close-modal="$emit('close-spv-options')">
    <div class="spv-option tw-px-4 lg:tw-px-12 tw-font-normal">
      <img
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1667563098/getequityV2/Group_35_spfcdv.png"
        alt="done icon"
        class="tw-w-24 tw-h-24 tw-block tw-mx-auto tw-mb-8"
      />
      <h1
        class="tw-font-CircularStd-medium tw-font-medium tw-mb-4 tw-text-xl tw-text-center tw-text-secondary"
      >
        Your deal request has been submitted
      </h1>
      <p
        class="tw-text-center tw-mt-4 tw-text-black-light tw-w-full tw-mx-auto tw-mb-10 tw-max-w-[382px] tw-text-base tw-font-normal"
      >
        Final step - Link this deal to an SPV. Create a new SPV or select from
        SPVs you already have.
      </p>
      <div class="tw-mb-8">
        <button
          class="tw-rounded tw-border tw-border-blue-bg200 tw-bg-[#FAFBFF] tw-py-2 tw-px-8 tw-text-secondary tw-font-CircularStd-medium tw-font-medium tw-mb-3 tw-text-base tw-cursor-pointer tw-flex tw-justify-between tw-w-full hover:tw-bg-blue-bg200 hover:tw-text-secondary"
          :class="[
            spvOption === option.value ? 'tw-bg-blue-bg200 tw-border-0' : null,
          ]"
          v-for="(option, index) in spvOptions"
          :key="index"
          @click="spvOption = option.value"
        >
          <p class="tw-text-left">{{ option.name }}</p>
          <img
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1661343817/getequityV2/arrow_dx8yhh.svg"
            alt="right arrow"
          />
        </button>
      </div>
      <!-- <BtnComponent title="Continue" @click="setOption" /> -->
      <router-link
        class="tw-font-CircularStd-medium tw-font-medium tw-block spv-option__text spv-option__text--block tw-text-sm tw-text-center tw-mt-16 tw-cursor-pointer"
        :to="{ name: 'Deals' }"
      >
        Skip for now
      </router-link>
    </div>
  </Modal>
</template>

<script setup>
  import Modal from "@/components/general/Modal";
  import BtnComponent from "@/components/general/BtnComponent";
  import { computed, defineEmits, defineProps, ref, watch } from "vue";
  import { useRouter } from "vue-router";
  import { setItem } from "@/utils/storage";
  import { useStore } from "vuex";
  const emit = defineEmits([
    "close-spv-options",
    "show-spv-list",
    "upload-spv-doc",
  ]);
  const props = defineProps({
    dealId: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
  });

  const spvOptions = ref([
    {
      name: "Create new SPV",
      value: "new",
    },
    {
      name: "Upload SPV Document",
      value: "upload",
    },
    {
      name: "Select from your SPVs",
      value: "select",
    },
  ]);
  const spvOption = ref(null);

  const router = useRouter();
  const store = useStore();

  const dealInfo = computed(() => store.getters["Deals/dealInfo"]);

  const setOption = () => {
    switch (spvOption.value) {
      case "upload":
        emit("upload-spv-doc");

        break;
      case "select":
        emit("show-spv-list");
        break;
      case "new":
        storeDealInfo();
        router.push({ name: "CreateSpv" });
        break;
    }
  };
  const storeDealInfo = () => {
    setItem("dealId", dealInfo.value._id);
  };
  watch(spvOption, () => {
    setOption();
  });
</script>

<style lang="scss" scoped>
  .spv-option {
    &__div {
      background: #f7f9fd;
      border: 1px solid #e1e6f0;
      box-sizing: border-box;
      border-radius: 4px;
      transition: all 300ms ease-in;
      height: 48px;
      &--active,
      &:hover {
        border: 1px solid #4d84ff;
      }
    }
    &__text {
      color: #8791a7;
      transition: all 300ms ease-in;
      &--active {
        color: #020b27;
      }
      &--block {
        display: block;
      }
    }
  }
</style>
