<template>
	<button
		class="tw-bg-primary deals__button tw-w-full tw-text-white tw-py-3 tw-px-8 tw-box-border tw-rounded tw-font-medium"
	>
		<img
			v-if="loading"
			class="tw-m-auto"
			src="@/assets/img/spinner.svg"
			alt="animated spinner"
		/>
		<template v-else>{{ text }}</template>
	</button>
</template>

<script>
export default {
	name: "DealsButton",
	props: {
		text: {
			type: String,
			required: true,
			default: () => "",
		},
    loading: {
      type: Boolean,
      default: () => false
    }
	},
};
</script>
<style lang="scss" scoped>
.deals__button {
	cursor: pointer;
	transition: all 300ms ease-in-out;
	&:disabled {
		cursor: not-allowed;
		background-color: #e0e0e0;
	}
	&:hover {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
			#4d84ff;
	}
}
</style>
