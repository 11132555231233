<template>
  <div>
    <div
      id="side-modal"
      class="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-screen tw-z-30"
      @click="$emit('close', title)"
    >
      <div class="container">
        <div
          class="card tw-fixed tw-h-screen tw-overflow-y-scroll tw-top-0 tw-right-0 tw-bg-white tw-pb-4"
          @click.stop
        >
          <div v-if="showBackButton" class="tw-relative">
            <img
              class="back-btn tw-absolute tw-rounded-full tw-cursor-pointer tw-p-1"
              src="@/assets/img/back-icon.svg"
              alt="back"
              @click="$emit('go-back', form)"
            />
          </div>
          <div class="tw-relative">
            <img
              class="mobile-close-btn tw-absolute tw-rounded-full tw-cursor-pointer tw-p-1"
              src="@/assets/img/close-icon.svg"
              alt="close"
              @click="$emit('close', title)"
            />
          </div>
          <div>
            <Header :title="title" :bgChange="bgChange" />
            <SubHeading :title="subTitle" class="tw-mt-5" />
            <slot name="content">content</slot>
          </div>
        </div>
      </div>
    </div>
    <img
      class="close-btn tw-hidden tw-fixed tw-rounded-full tw-cursor-pointer tw-p-1 tw-z-40"
      src="@/assets/img/close-icon.svg"
      alt="close"
      @click="$emit('close', title)"
    />
  </div>
</template>

<script>
  import Header from "@/components/general/sidemodal/SideModalHeader.vue";
  import SubHeading from "@/components/general/sidemodal/SideModalSubHeading.vue";

  export default {
    name: "SideModal",

    emits: ["close", "mounted", "go-back"],

    components: {
      Header,
      SubHeading,
    },

    props: {
      title: { type: String, default: () => "", required: true },
      subTitle: {
        type: String,
        default: () => "",
        required: false,
      },
      form: {
        type: String,
        default: () => "",
        required: false,
      },
      showBackButton: {
        type: Boolean,
        default: () => true,
        required: false,
      },
      bgChange: {
        type: Boolean,
        default: () => false,
      },
    },

    mounted() {
      this.$emit("mounted", this.title);
    },
  };
</script>

<style lang="scss" scoped>
  #side-modal {
    @include fadeIn;
    background-color: $modal-bg;
    overflow: scroll;

    .container {
      .card {
        min-width: 100%;
        max-width: 100%;
        // margin: 0rem auto 0 auto;

        @media screen and (min-width: 480px) {
          min-width: 470px;
          max-width: 470px;
        }
      }
      .mobile-close-btn {
        top: 0.4rem;
        right: 1rem;

        @media screen and (min-width: 640px) {
          display: none;
        }
      }
      .back-btn {
        top: 0.4rem;
        left: 1rem;
      }
    }
  }
  .close-btn {
    @media screen and (min-width: 640px) {
      display: block;
      top: 0.4rem;
      right: 30rem;
    }
  }
</style>
