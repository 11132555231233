<template>
  <div class="">
    <label :for="label"></label>
    <select
      class="tw-border-black-light2 tw-h-12 tw-rounded tw-p-2 tw-cursor-pointer tw-text-secondary focus:tw-border-primary tw-w-full"
      name="country code"
      id="country-code"
      v-model="country"
    >
      <option
        class="tw-flex tw-items-center"
        v-for="(country, index) in countryList"
        :value="country.countryShortCode"
        :key="index"
      >
        <p>
          {{ country.countryName }}
        </p>
      </option>
    </select>
    <img
      src="@/assets/img/solid-chevron-down.svg"
      class="tw-absolute tw-right-2 tw-bottom-6 tw--z-10"
      alt="chevron icon"
    />
  </div>
</template>

<script>
  import countryList from "@/utils/countryRegionData.js";

  export default {
    name: "OnboardingCountryInput",

    emits: ["set"],

    props: {
      label: { type: String, default: () => "", required: true },
      currentValue: {
        type: String,
        required: false,
      },
    },

    mounted() {
      if (this.currentValue) {
        this.country = this.currentValue;
      }
    },

    data() {
      return {
        countryList,
        country: "",
      };
    },

    mounted() {
      if (this.currentValue) {
        this.country = this.currentValue;
      } else {
        this.country = "NG";
      }
      this.emitCountry(this.country);
    },

    methods: {
      emitCountry(country) {
        this.$emit("set", country);
      },
    },

    watch: {
      country(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.emitCountry(newValue);
        }
      },
      currentValue(val) {
        if (val && val !== this.country) {
          this.country = val;
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
