<template>
  <Accordion
    :info="info"
    @is-active="setActiveTab"
    :verified="isFilled"
    icon="https://res.cloudinary.com/djalafcj9/image/upload/v1668692227/getequityV2/address_ju41nx.svg"
  >
    <form
      @submit.prevent="checkFormValidation"
      class="tw-flex tw-justify-between tw-mt-12 md:tw-px-8 lg:tw-px-16 tw-flex-wrap address__form tw-relative"
    >
      <Loader v-if="loading" />
      <div class="address-form__div">
        <CountryDropdown
          :currentValue="country"
          class="tw-w-full tw-rounded tw-h-12 address__input address__input--no-border"
          :class="address.country ? null : 'address__input--empty'"
          label="Country"
          @set="setCountry"
        />
        <p
          class="tw-mb-5 tw-mt-1 tw-text-sm required tw-w-full"
          v-if="
            v$.address.country.$dirty && v$.address.country.$errors.length > 0
          "
        >
          {{ v$.address.country.$errors[0].$message }}
        </p>
      </div>
      <div class="address-form__div">
        <StateDropdown
          label="State/Region"
          :country="address.country"
          :currentValue="address.state"
          class="tw-w-full tw-rounded tw-h-12 address__input address__input--no-border"
          :class="address.state ? null : 'address__input--empty'"
          @set="setState"
        />
        <p
          class="tw-mb-5 tw-mt-1 tw-text-sm required tw-w-full"
          v-if="v$.address.state.$dirty && v$.address.state.$errors.length > 0"
        >
          {{ v$.address.state.$errors[0].$message }}
        </p>
      </div>
      <div class="address-form__div">
        <InputField
          label="City"
          name="city"
          :currentValue="address.city"
          @input-changed="storeValue"
          :validate="validateForm"
          @is-valid="handleValidation"
        />
      </div>
      <div class="address-form__div">
        <InputField
          label="Zip code"
          name="zipcode"
          :currentValue="address.zipcode"
          @input-changed="storeValue"
          :validate="validateForm"
          @is-valid="handleValidation"
        />
      </div>
      <TextField
        height="tw-h-32"
        name="address"
        label="Address"
        class="tw-w-full"
        :currentValue="address.address"
        @input-changed="storeValue"
        :validate="validateForm"
        @is-valid="handleValidation"
      />

      <div
        class="tw-w-full tw-mt-8"
        v-if="
          ($route.name === 'CreateAsset' && !$route.query.preview) ||
            ($route.name !== 'CreateAsset' &&
              !dealInfo &&
              !$route.query.preview) ||
            ($route.name !== 'CreateAsset' &&
              dealInfo &&
              
              !$route.query.preview)
        "
      >
        <div class="tw-w-32">
          <DealButton
            text="Continue"
            class="tw-text-sm"
            :disabled="
              ($route.name === 'CreateAsset' && !$route.query?.id) || loading
            "
          />
        </div>
      </div>
    </form>
  </Accordion>
</template>

<script>
  import InputField from "@/components/general/InputField";
  import TextField from "@/components/general/TextField";
  import useValidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import countryRegionState from "@/assets/js/countryRegionData";
  import DealButton from "@/components/deals/DealsButton";
  import CountryDropdown from "@/components/onboarding/OnboardingCountryInput";
  import StateDropdown from "@/components/onboarding/OnboardingStateInput";
  import Accordion from "@/components/setup/Accordion";
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: "Address",
    emits: ["is-active"],
    data() {
      return {
        info: {
          title: "Address",
          description: "",
        },
        countryRegionState,
        v$: useValidate(),
        loading: false,
        address: {
          country: "Nigeria",
          state: null,
          zipcode: null,
          city: null,
          address: null,
        },
        country: "",
        state: "",
        cities: "",
        countryIsoCode: "",
        stateIsoCode: "",
        validateForm: false,
        validationResult: [],
      };
    },
    components: {
      InputField,
      TextField,
      DealButton,
      CountryDropdown,
      StateDropdown,
      Accordion,
    },
    inject: ["activeTab"],
    validations() {
      return {
        address: {
          country: {
            required,
          },
          state: {
            required,
          },
        },
      };
    },
    watch: {
      "address.country"(val) {
        if (val) {
          this.getCountryIsoCode();
        }
      },
      asset(val) {
        if (val && val.address && !this.$route.query.new) {
          this.setValues();
        }
      },
      validationResult: {
        deep: true,
        handler(val) {
          if (
            val.length === Object.keys(this.address).length &&
            !val.includes(false)
          ) {
            this.addAddress();
          }
        },
      },
    },
    computed: {
      ...mapGetters("Asset", ["asset"]),
      ...mapGetters("Deals", ["dealInfo"]),
      isFilled() {
        return (
          !Object.values(this.address).includes(null) &&
          !Object.values(this.address).includes("")
        );
      },
    },
    mounted() {
      if (
        this.asset &&
        this.asset.address &&
        this.$route.query.assetName &&
        !this.$route.query.new
      ) {
        this.setValues();
      } else {
        this.address.country = "";
        this.address.state = "";
        this.address.zipcode = "";
        this.address.city = "";
        this.address.address = "";
      }
    },
    methods: {
      ...mapActions("Asset", ["updateAsset", "fetchAsset"]),
      setActiveTab(title) {
        this.$emit("is-active", title);
      },
      storeValue(val) {
        this.address[val.name] = val.value;
      },

      setCountry(country) {
        if (country !== this.address.country) {
          this.address.country = country;
          this.getCountryIsoCode();
        }
      },

      setState(state) {
        this.address.state = state;
      },
      setValues() {
        this.address.country = this.asset.country;
        this.address.state = this.asset.state;
        this.address.zipcode = this.asset.zipcode;
        this.address.city = this.asset.city;
        this.address.address = this.asset.address;
      },
      getCountryIsoCode() {
        const countryInfo = this.countryRegionState.filter(
          (country) =>
            country.countryName === this.address.country ||
            country.countryShortCode === this.address.country
        );
        this.countryIsoCode = countryInfo[0]?.countryShortCode;
        this.address.country = countryInfo[0]?.countryShortCode;
        this.country = countryInfo[0]?.countryShortCode;
      },
      checkFormValidation() {
        this.validationResult = [];
        this.validateForm = true;
        this.v$.address.$touch();
        if (this.address.country) {
          this.handleValidation(true);
        } else {
          this.handleValidation(false);
        }
        if (this.address.state) {
          this.handleValidation(true);
        } else {
          this.handleValidation(false);
        }
        setTimeout(() => {
          this.validateForm = false;
        }, 1000);
      },

      handleValidation(value) {
        this.validationResult.push(value);
      },
      async addAddress() {
        this.loading = true;
        try {
          await this.updateAsset({
            asset: this.address,
            assetId: this.$route.query.id
              ? this.$route.query.id
              : this.asset._id,
          });
          this.fetchAsset(
            this.$route.query.id ? this.$route.query.id : this.asset._id
          );
          this.$emit("is-active", "Founder Info");
          this.loading = false;
        } catch (error) {
          this.loading = false;
          return error;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .address {
    height: 72px;
    border: 1px solid #f1f3f6;
    box-sizing: border-box;
    border-radius: 4px;
    transition: height 800ms ease-out 0s;
    overflow-y: hidden;
    max-width: 818px;
    margin-left: auto;
    margin-right: auto;
    &--long {
      overflow-y: scroll;
      height: 900px;
    }
    &__button {
      width: 24px;
      height: 24px;
      &:hover {
        background: inherit;
      }
    }
    &__input {
      &::placeholder,
      &--empty {
        color: #a3a8b3;
      }
      border: 1px solid #dde1e9;
      box-sizing: border-box;
      border-radius: 4px;
      &--no-border {
        border: none;
      }
    }
    &__form {
      gap: 20px;
      justify-content: center;
    }
    &-form {
      &__div {
        flex-basis: 100%;
        @media (min-width: 1024px) {
          max-width: 335px;
          flex-basis: 48%;
        }
      }
    }
  }

  .required {
    color: indianred;
  }
</style>
