<template>
  <section class="deal tw-px-10 tw-relative">
    <div class="md:tw-flex tw-justify-between tw-mb-10" v-if="dealInfo">
      <h1
        class="tw-font-bold tw-text-2xl tw-flex tw-gap-x-3 tw-mb-8 md:tw-mb-0"
      >
        <BackBtn />
        {{ dealInfo.name }}
      </h1>
      <div class="tw-h-8 tw-flex" v-if="dealInfo.status === 'Approved'">
        <div class="deal-members__div tw-flex tw-items-center">
          <img
            :src="member.user.selfie || member.user.image"
            :alt="index"
            class="tw-rounded-full tw-w-8 tw-h-8 tw-flex tw-items-center tw-object-fill"
            v-for="(member, index) in dealMembers"
            v-show="index < 9"
            :key="index"
          />
          <div
            class="tw-rounded-full tw-w-8 tw-h-8 deal-members-count__div tw-flex tw-items-center tw-justify-center"
            v-if="dealMembers && dealMembers.length >= 9"
          >
            <p class="deal-members-count__text">{{ dealMembers.length - 8 }}</p>
          </div>
        </div>

        <button
          v-if="dealInfo.deal_access !== 'Public'"
          class="tw-ml-1 tw-bg-white tw-flex tw-justify-center tw-items-center tw-rounded-full tw-w-8 tw-h-8 add-member__button hover:tw-bg-transparent"
          title="Add member to this deal"
          @click="showMemberForm = true"
        >
          <img src="@/assets/img/add-circle-blue.svg" alt="add icon" class="" />
        </button>
      </div>
      <AddMember v-if="showMemberForm" @show-member-form="handleMember" />
      <!-- <button>Add Update</button> -->
    </div>
    <div class="tw-bg-white tw-p-8 tw-rounded">
      <div class="deal-nav__div">
        <nav class="deal__nav tw-flex tw-w-full">
          <button
            class="tw-text-lg tw-font-medium deal-nav__button tw-mr-3"
            :class="[
              currentSection === 'AssetDetails'
                ? 'deal-nav__button--active'
                : null,
            ]"
            @click="currentSection = 'AssetDetails'"
          >
            Asset Details
          </button>
          <button
            class="tw-text-lg tw-font-medium deal-nav__button tw-mr-3"
            :class="[
              currentSection === 'SpvDetails'
                ? 'deal-nav__button--active'
                : null,
            ]"
            @click="currentSection = 'SpvDetails'"
          >
            SPV Details
          </button>
          <button
            class="tw-text-lg tw-font-medium deal-nav__button deal-nav__button--long tw-mr-3"
            :class="[
              currentSection === 'DealConfiguration'
                ? 'deal-nav__button--active'
                : null,
            ]"
            @click="currentSection = 'DealConfiguration'"
          >
            Deal Configuration
          </button>
          <button
            v-if="dealInfo?.token"
            class="tw-text-lg tw-font-medium deal-nav__button tw-mr-3"
            :class="[
              currentSection === 'DealUpdates'
                ? 'deal-nav__button--active'
                : null,
            ]"
            @click="currentSection = 'DealUpdates'"
          >
            Deal Updates
          </button>
          <button
            class="tw-text-lg tw-font-medium deal-nav__button tw-mr-3"
            v-if="dealInfo && dealInfo.token"
            :class="[
              currentSection === 'DealTransactionTable'
                ? 'deal-nav__button--active'
                : null,
            ]"
            @click="currentSection = 'DealTransactionTable'"
          >
            Transactions
          </button>
          <button
            class="tw-text-lg tw-font-medium deal-nav__button tw-mr-3"
            :class="[
              currentSection === 'Members' ? 'deal-nav__button--active' : null,
            ]"
            v-if="
              dealInfo && dealInfo.deal_access === 'Private' && dealInfo.token
            "
            @click="currentSection = 'Members'"
          >
            Members
          </button>
          <button
            class="tw-text-lg tw-font-medium deal-nav__button tw-mr-3"
            v-if="dealInfo && dealInfo?.token"
            :class="[
              currentSection === 'Investors'
                ? 'deal-nav__button--active'
                : null,
            ]"
            @click="currentSection = 'Investors'"
          >
            Investors
          </button>
          <button
            v-if="dealInfo?.token"
            class="tw-text-lg tw-font-medium deal-nav__button tw-mr-3"
            :class="[
              currentSection === 'DealOrderBookTable'
                ? 'deal-nav__button--active'
                : null,
            ]"
            @click="currentSection = 'DealOrderBookTable'"
          >
            Pending Purchases
          </button>
        </nav>
      </div>
      <div class="tw-mt-8">
        <keep-alive>
          <component
            :spvId="dealInfo && dealInfo.spv ? dealInfo.spv : ''"
            :is="component"
            :tokenId="dealInfo ? dealInfo.token?._id : ''"
            @show-member-form="showMemberForm = true"
          ></component>
        </keep-alive>
      </div>
    </div>
    <Loader v-if="loading" />
  </section>
</template>

<script setup>
  import DealTransactionTable from "@/components/tables/DealTransactionTable.vue";
  import Members from "./DealInfo/Members.vue";
  import AddMember from "@/components/deals/AddMember.vue";
  import DealOrderBookTable from "@/components/tables/DealOrderBookTable.vue";
  import DealConfiguration from "../deals/createDeal/Index.vue";
  import Investors from "./DealInfo/Investors.vue";
  import AssetDetails from "../deals/createAsset/Index.vue";
  import SpvDetails from "@/components/spv/SpvDetails.vue";
  import BackBtn from "@/components/general/BackBtn.vue";
  import DealUpdates from "@/components/deals/DealUpdates.vue";

  import { computed, ref, watch } from "vue";
  import { useRoute } from "vue-router";
  import { useStore } from "vuex";

  const route = useRoute();
  const store = useStore();

  const loading = ref(false);
  const currentSection = ref("AssetDetails");
  const showMemberForm = ref(false);

  const dealInfo = computed(() => store.getters["Deals/dealInfo"]);
  const dealMembers = computed(() => store.getters["Deals/dealMembers"]);

  const fetchDealMembers = async (id) => {
    await store.dispatch("Deals/fetchDealMembers", id);
  };
  const getDealInfo = async (id) => {
    loading.value = true;
    try {
      await store.dispatch("Deals/fetchDealInfo", id);
      loading.value = false;
    } catch (error) {
      loading.value = false;
      return error;
    }
  };
  const handleMember = (val) => {
    showMemberForm.value = val;
  };
  const component = computed(() => {
    let component = null;
    switch (currentSection.value) {
      case "AssetDetails":
        component = AssetDetails;
        break;
      case "SpvDetails":
        component = SpvDetails;
        break;
      case "DealConfiguration":
        component = DealConfiguration;
        break;
      case "DealUpdates":
        component = DealUpdates;
        break;
      case "DealTransactionTable":
        component = DealTransactionTable;
        break;
      case "Members":
        component = Members;
        break;
      case "Investors":
        component = Investors;
        break;
      case "DealOrderBookTable":
        component = DealOrderBookTable;
        break;
    }
    return component;
  });

  watch(dealInfo, (value) => {
    if (value.status === "Approved" && value.token?._id) {
      fetchDealMembers(value.token?._id);
    }
  });

  getDealInfo(route.params.dealId);
</script>

<style lang="scss" scoped>
  .deal {
    height: calc(100vh - 120px);
    overflow-y: scroll;
    &-members {
      &__div {
        display: flex;
        gap: 4px;
      }
      &-count {
        &__div {
          background: rgba(0, 0, 0, 0.4);
        }
        &__text {
          color: #fff;
          font-size: 18px;
        }
      }
    }
    &__nav {
      width: fit-content;
      min-width: 120vw;
    }
    &-nav {
      &__div {
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &__button {
        color: #8791a7;
        width: fit-content;
        padding: 0 8px;
        height: 40px;
        display: block;
        min-width: 130px;
        transition: all 300ms ease-in-out;
        &:hover {
          background: transparent;
          border-radius: 4px 4px 0px 0px;
          border-bottom: 2px solid #4d84ff;
        }
        &--long {
          width: 180px;
        }
        &--active {
          color: #001140;
          font-weight: 500;
          border-radius: 4px 4px 0px 0px;
          border-bottom: 2px solid #4d84ff;
        }
      }
    }
  }

  .add-member {
    &__button {
      border: 1px dashed #8791a7;
    }
  }
</style>
