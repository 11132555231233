<template>
  <section id="investors">
    <Loader v-if="loading" />
    <div v-else class="container tw-px-4">
      <div class="tw-bg-white tw-rounded-lg lg:tw-mx-4">
        <div class="tw-w-64 tw-my-10 tw-ml-auto">
          <!-- <DealsButton
            text="Add members to this deal"
            @click="$emit('show-member-form', true)"
          /> -->
        </div>
        <div>
          <div class="table-container">
            <InvestorsTable
              :hasStatus="false"
              v-if="dealInvestors && dealInvestors.length !== 0"
              :tableHeaders="tableHeaders"
              :tableRows="tableRows"
              :columnNumber="tableHeaders.length - 1"
              :columnWidth="170"
              :totalPage="1"
              :currentPage="1"
              @gotoPage="null"
            />
            <EmptyState v-if="dealInvestors && dealInvestors.length === 0">
              <template v-slot:message>
                <p
                  v-if="status === 'All members'"
                  class="message tw-text-center tw-text-gray tw-my-3"
                >
                  There are no members on this account.
                </p>
                <p
                  v-if="status === 'Waitlist'"
                  class="message tw-text-center tw-text-gray tw-my-3"
                >
                  There are no pending members on this account.
                </p>
                <p
                  v-if="status === 'Deactivated'"
                  class="message tw-text-center tw-text-gray tw-my-3"
                >
                  There are no deactivated members on this account.
                </p>
              </template>
            </EmptyState>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DealsButton from "@/components/deals/DealsButton";
import EmptyState from "@/components/notification/EmptyState.vue";
import InvestorsTable from "@/components/tables/InvestorTable.vue";
import { truncate } from "@/utils/helpers.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Investors",
  components: {
    InvestorsTable,
    EmptyState,
    DealsButton,
  },
  data() {
    return {
      loading: false,
      status: "",
      tableHeaders: [
        "Name",
        "Email",
        "Phone number",
        "Address",
        "Investment amount",
      ],
      tableRows: [],
    };
  },
  emits: ["show-member-form", "add-member-form"],
  props: {
    tokenId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("Deals", ["dealInvestors"]),
  },
  mounted() {
    if (this.tokenId) {
      this.getAllInvestors();
    }
  },
  watch: {
    dealInvestors(newValue, oldValue) {
      deep: true;
      immediate: true;
      if (newValue !== oldValue) {
        this.updateTableRow();
      }
    },
  },
  methods: {
    ...mapActions("Deals", ["fetchDealInvestors"]),
    async getAllInvestors() {
      this.loading = true;
      try {
        await this.fetchDealInvestors(this.tokenId);
        this.updateTableRow();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        return error;
      }
    },

    updateTableRow() {
      this.tableRows = this.dealInvestors.map((investor) => {
        let name = `${investor.fname} ${investor.lname}`;
        return {
          name: name,
          email: truncate(investor.email, 25),
          phone: truncate(investor.phone ? investor.phone : "-", 25),
          address: truncate(
            investor.homeAddress ? investor.homeAddress : "-",
            25
          ),
          investment: `${new Intl.NumberFormat().format(investor.balance)}`,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#investors {
  width: 100%;
  overflow: hidden;

  .container {
    @include fadeIn;

    @media screen and (min-width: 1024px) {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .table-container {
    overflow: auto;
  }

  .message {
    max-width: 250px;
  }
}
</style>
