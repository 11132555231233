<template>
  <Modal :show="true" @close-modal="$emit('close-spv-info')">
    <div class="tw-w-full tw-w-[250px] md:tw-w-[480px] tw-box-border">
      <template v-if="showBack">
        <div class="tw-w-full tw-mb-10">
          <button
            @click="$emit('close-spv-info')"
            class="tw-flex tw-gap-x-3 tw-items-center tw-text-base tw-text-secondary tw-font-CircularStd-medium tw-font-medium hover:tw-bg-white hover:tw-text-secondary"
          >
            <img src="@/assets/img/arrow-back.svg" alt="" /> Go Back
          </button>
        </div></template
      >
      <div class="tw-w-12 tw-h-12 tw-mb-2">
        <img src="@/assets/img/spv.svg" />
      </div>
      <h1
        class="tw-font-CircularStd-medium tw-text-medium tw-text-secondary tw-text-2xl tw-tracking-[-0.1px]"
      >
        {{ spv.name }}
      </h1>
      <p class="tw-mt-3 tw-text-base tw-text-gray-light2">{{ orgName }}</p>
      <div
        class="tw-border-t tw-border-b tw-border-[#DDE1E9] tw-py-4 tw-mt-4 tw-flex tw-gap-x-8 md:tw-gap-x-28 tw-mb-12"
      >
        <div class="">
          <p class="tw-text-sm tw-text-gray-light2 tw-mb-2">Country</p>
          <p
            class="tw-font-CircularStd-medium tw-text-medium tw-text-secondary tw-text-base"
          >
            {{ spvCountry }}
          </p>
        </div>
        <div class="">
          <p class="tw-text-sm tw-text-gray-light2 tw-mb-2">Date of Creation</p>
          <p
            class="tw-font-CircularStd-medium tw-text-medium tw-text-secondary tw-text-base"
          >
            {{ dateOfCreation }}
          </p>
        </div>
      </div>
      <template v-if="spv.url">
        <h2 class="tw-text-secondary tw-font-bold tw-text-lg tw-mb-4">
          SPV Documents
        </h2>
        <DocumentViewer :doc="{ url: spv.url, name: spv.name }" />
      </template>
      <div class="md:tw-flex tw-gap-x-3 tw-justify-end" v-if="showBack">
        <button
          class="tw-rounded tw-mt-12 tw-bg-[#E6EDFF] tw-h-12 tw-w-40 tw-font-CircularStd-medium tw-text-medium tw-mx-auto md:tw-mx-0 tw-text-base tw-px-12 tw-block"
          @click="$emit('close-spv-info')"
        >
          Go Back
        </button>
        <button
          class="tw-rounded tw-mt-12 tw-bg-primary tw-h-12 tw-w-40 tw-font-CircularStd-medium tw-text-medium tw-mx-auto md:tw-mx-0 tw-text-base tw-text-white tw-px-8 tw-block"
          @click="useSpv"
        >
          Use this SPV
        </button>
      </div>
      <button
        v-else
        class="tw-rounded tw-mt-12 tw-bg-[#E6EDFF] tw-h-12 tw-w-max tw-font-CircularStd-medium tw-text-medium tw-text-base tw-px-16 tw-ml-auto tw-block"
        @click="$emit('close-spv-info')"
      >
        Done
      </button>
    </div>
  </Modal>
</template>

<script setup>
  import Modal from "../general/Modal.vue";
  import DocumentViewer from "@/components/general/DocumentViewer.vue";
  import { useStore } from "vuex";
  import { computed, ref } from "vue";

  const emits = defineEmits(["close-spv-info", "use-spv"]);
  const store = useStore();

  const orgName = computed(
    () => store.state.organisationModule.organisationDetails.name
  );
  const { spv, showBack } = defineProps({
    spv: {
      type: Object,
      required: true,
    },
    showBack: {
      type: Boolean,
      required: false,
      default: false,
    },
  });

  const spvCountry = computed(() => {
    return spv.meta
      ? spv.meta.country_of_incorporation.split(" ")[0]
      : spv.country;
  });
  const dateOfCreation = computed(() => {
    return spv.meta
      ? spv.meta.date_of_creation.split("T")[0]
      : spv.createdAt.split("T")[0];
  });

  const useSpv = () => {
    emits("use-spv", spv);
  };
</script>

<style lang="scss" scoped></style>
