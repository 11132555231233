<template>
  <div
    @click.self="$emit('show-info')"
    class="tw-border tw-border-[#E6EDFF] tw-rounded tw-py-5 tw-px-8 tw-flex tw-bg-[#FAFBFF] hover:tw-bg-gray-light tw-cursor-pointer tw-flex md:tw-gap-5"
  >
    <div class="tw-rounded-full tw-w-10 tw-h-10 tw-mr-3">
      <img src="@/assets/img/spv.svg" />
    </div>
    <div class="">
      <p
        class="tw-text-secondary tw-font-medium tw-font-CircularStd-medium tw-text-lg"
      >
        {{ spv.name || spv.proposed_name1 }}
      </p>
      <div class="">
        <p
          class="tw-text-[15px] tw-text-gray-light2 tw-font-normal"
          v-if="spv.should_manage_spv"
        >
          Managed by GetEquity
        </p>
        <p class="tw-text-[15px] tw-text-gray-light2" v-else>
          Managed by {{ orgName }}
        </p>

        <div
          class="tw-font-CircularStd-medium tw-text-medium tw-text-sm tw-rounded tw-px-4 tw-py-2 tw-w-max tw-h-10 tw-mt-6"
          :class="spvTypeClass"
        >
          {{ spvType }}
        </div>
      </div>
    </div>
    <img class="tw-ml-auto" src="@/assets/img/caret-right-grey.svg" alt="" />
  </div>
</template>

<script setup>
  import { computed } from "vue";
  import { useStore } from "vuex";

  defineEmits(["show-info"]);

  const store = useStore();

  const { spv } = defineProps({
    spv: {
      type: Object,
      required: true,
    },
  });

  const spvTypeClass = computed(
    () => "tw-bg-[#E6EDFF] tw-text-[#2A62F5]"
    // spvType.value === "Uploaded SPV Doc"
    //   ? "tw-bg-[#E6EDFF] tw-text-[#2A62F5]"
    //   : "tw-bg-[#FAFBFF] tw-text-secondary"
  );

  const spvType = computed(() =>
    spv.meta ? "Uploaded SPV Doc" : "Created on GetEquity"
  );

  const orgName = computed(
    () => store.state.organisationModule.organisationDetails.name
  );
</script>

<style lang="scss" scoped></style>
