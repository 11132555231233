<template>
  <section
    class="upload-spv tw-relative tw-mt-10"
    :class="[showBack ? 'tw-max-w-[639px] tw-mx-auto' : null]"
  >
    <Loader v-if="loading" />
    <div class="tw-w-full" v-if="showBack">
      <button
        @click="$emit('show-spv-options')"
        class="tw-flex tw-gap-x-3 tw-items-center tw-text-base tw-text-secondary tw-font-CircularStd-medium tw-font-medium hover:tw-bg-white hover:tw-text-secondary"
      >
        <img src="@/assets/img/arrow-back.svg" /> Go Back
      </button>
    </div>
    <div class="upload-spv__container">
      <div class="upload-spv-heading__div tw-my-8 tw-mx-8 lg:tw-mx-0">
        <h1
          class=" tw-font-bold tw-text-lg tw-mb-1 tw-text-secondary tw-flex tw-gap-x-3 tw-items-center"
        >
          <BackBtn v-if="!showBack" />
          Upload SPV
        </h1>
        <p class="tw-text-sm tw-text-black-light tw-font-light">
          Provide a valid SPV document. File should be in docx or pdf format.
        </p>
      </div>
      <form
        @submit.prevent="checkFormValidation"
        class="upload-spv__form tw-w-full tw-pb-8"
        :class="[!showBack ? 'upload-spv__form--border tw-p-6' : null]"
      >
        <h2
          class=" tw-text-secondary tw-flex tw-items-center tw-font-CircularStd-medium tw-font-medium"
        >
          <img src="@/assets/img/file.svg" class="tw-mr-2" alt="file icon" />
          SPV Document
        </h2>
        <div
          class="tw-max-w-[690px] tw-mx-auto  tw-mt-6"
          v-for="(doc, index) in spv"
          :key="index"
        >
          <DragNDrop
            :validate="validate"
            class="upload-spv__dragdrop"
            accept=".pdf"
            :currentValue="doc.url"
            @file-added="setSpv($event, index)"
            :startUpload="startUpload"
            @reupload="startUpload = false"
            @upload-complete="addSpv($event, index)"
            @is-valid="handleValidation"
          >
            <p
              class="tw-text-sm tw-text-secondary tw-w-full tw-text-center tw-mb-2 "
              v-if="!spv.url"
            >
              Drag and drop file
            </p>
          </DragNDrop>
          <div
            class="tw-mt-4 md:tw-flex tw-justify-between tw-items-center  tw-mb-6"
          >
            <p
              class="tw-text-sm tw-text-black-light tw-text-left tw-mb-4 md:tw-mb-0"
            >
              Document should be good condition and clearly visible.
            </p>
          </div>
          <hr />
          <div class="tw-mt-8 md:tw-mt-12 md:tw-flex tw-gap-x-3 lg:tw-gap-x-10">
            <div class="tw-mb-6 md:tw-mb-0 tw-w-full md:tw-w-1/2">
              <label
                for="country"
                class="tw-text-secondary tw-text-base tw-font-CircularStd-medium tw-font-medium tw-mb-2"
                >Name of SPV</label
              >
              <InputField
                class="tw-mt-2"
                name="name"
                label="Provide Name"
                :validate="validate"
                :currentValue="doc.name"
                @input-changed="storeValue($event, index)"
                @is-valid="handleValidation"
              />
            </div>
            <div class="tw-mb-6 md:tw-mb-0 tw-w-full md:tw-w-1/2">
              <label
                for="country"
                class="tw-text-secondary tw-text-base tw-font-CircularStd-medium tw-font-medium tw-mb-2"
                >Country of Incorporation</label
              >
              <Dropdown
                class="tw-mt-2"
                name="country"
                label="Select Country"
                :validate="validate"
                :currentValue="doc.country"
                :options="uploadSpvCountries"
                @input-changed="storeValue($event, index)"
                @is-valid="handleValidation"
              />
            </div>
          </div>
          <div class="tw-mt-8 md:tw-mt-12 md:tw-flex tw-gap-x-3 lg:tw-gap-x-10">
            <div class="tw-mb-3 tw-w-full md:tw-w-1/2 lg:tw-max-w-[325px]">
              <label
                for="country"
                class="tw-text-secondary tw-text-base tw-font-CircularStd-medium tw-font-medium tw-mb-2"
                >Tax Identification Number</label
              >
              <InputField
                class="tw-mt-2"
                name="tin"
                label="Enter TIN"
                :validate="validate"
                :currentValue="doc.tin"
                @input-changed="storeValue($event, index)"
                @is-valid="handleValidation"
              />
            </div>
            <div class="tw-mb-6 md:tw-mb-0 tw-w-full md:tw-w-1/2"></div>
          </div>
          <div class="tw-w-full">
            <button
              v-if="spv.length > 1"
              type="button"
              @click="removeDocument(index)"
              class="tw-mt-3 tw-flex tw-h-8 tw-items-center tw-cursor-pointer tw-text-sm tw-ml-auto hover:tw-bg-white hover:tw-text-secondary"
            >
              <img
                class="tw-mr-2"
                src="@/assets/img/delete.svg"
                alt="delete icon"
              />Delete
            </button>
          </div>
        </div>
        <div
          class="tw-max-w-[690px] tw-mx-auto tw-flex tw-mt-4 tw-border-t tw-pt-10"
        >
          <div class="tw-w-32">
            <BtnComponent
              title="Submit"
              :loading="loading"
              class="tw-text-sm"
            />
          </div>
          <button
            class="tw-flex tw-gap-x-2 tw-text-sm tw-text-[#06206B] tw-h-8 tw-items-center hover:tw-bg-white hover:tw-text-[#06206B] tw-ml-auto"
            type="button"
            @click="addSpvDoc"
            v-if="!showBack"
          >
            <img src="@/assets/img/add-circle.svg" class="tw-w-5 tw-h-5" />
            Add Document
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
  import DragNDrop from "@/components/general/DragNDrop";
  import BtnComponent from "@/components/general/BtnComponent";
  import Dropdown from "@/components/general/Dropdown";
  import InputField from "@/components/general/InputField.vue";
  import BackBtn from "@/components/general/BackBtn.vue";

  import { useStore } from "vuex";
  import { computed, ref, watch } from "vue";

  const emits = defineEmits(["spv-uploaded", "show-spv-options"]);

  defineProps({
    showBack: {
      type: Boolean,
      required: false,
      default: false,
    },
  });

  const spv = ref([
    {
      name: null,
      url: "",
      tin: null,
      country: null,
    },
  ]);
  const loading = ref(false);
  const startUpload = ref(false);
  const isValidated = ref(false);
  const validate = ref(false);
  const validationResult = ref([]);
  const docUploaded = ref([]);

  const store = useStore();

  const uploadSpvCountries = computed(
    () => store.getters["Spv/uploadSpvCountries"]
  );

  const setSpv = (value, index) => {
    spv.value[index].url = value;
  };
  const checkFormValidation = () => {
    validationResult.value = [];
    validate.value = true;
    isValidated.value = true;
    docUploaded.value = [];
    setTimeout(() => {
      validate.value = false;
    }, 1000);
  };
  const storeValue = (object, index) => {
    spv.value[index][object.name] = object.value;
  };

  const handleValidation = (value) => {
    validationResult.value.push(value);
  };

  const addSpv = (url, index) => {
    spv.value[index].url = url;
    docUploaded.value.push(true);
    if (docUploaded.value.length === spv.value.length) {
      if (spv.value.length > 1) {
        uploadSpv();
      } else {
        uploadSingleSpv();
      }
    }
  };
  const uploadSpv = async () => {
    try {
      let message = null;
      await Promise.all(
        spv.value.map(async (doc) => {
          const response = await store.dispatch("Spv/uploadSpv", doc);
          message = response.data.message;
        })
      );
      store.dispatch("showToast", {
        description: message,
        display: true,
        type: "success",
      });
      loading.value = false;
      emits("spv-uploaded");
    } catch (error) {
      loading.value = false;
    }
  };
  const uploadSingleSpv = async () => {
    try {
      const response = await store.dispatch("Spv/uploadSpv", spv.value[0]);
      const message = response.data.message;
      store.dispatch("showToast", {
        description: message,
        display: true,
        type: "success",
      });
      loading.value = false;
      emits("spv-uploaded", response);
    } catch (error) {
      loading.value = false;
    }
  };
  const addSpvDoc = () => {
    spv.value.push({
      name: null,
      url: null,
      tin: null,
      country: null,
    });
  };

  const removeDocument = (index) => {
    spv.value.splice(index, 1);
  };

  watch(startUpload, (val) => {
    if (val) {
      loading.value = true;
    }
  });
  watch(
    validationResult,
    (val) => {
      if (val.length === spv.value.length * 4 && !val.includes(false)) {
        startUpload.value = true;
      }
    },
    { deep: true }
  );
</script>

<style lang="scss">
  .upload-spv {
    border-radius: 4px;
    &__container {
      max-width: 818px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 100px;
    }
    &__form {
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      &--border {
        border: 1px solid #f1f3f6;
      }
    }
  }
  .date {
    &__input {
      height: 48px;
      transition: all 0.3s ease;
      border: 1px solid #e1e6f0 !important;
      flex-grow: 1;
      &--error {
        border: 1px solid #db4343 !important;
      }
    }
  }
  .dp__main {
    @apply md:tw-w-full;
  }
  .dp__input {
    border: 0;
    height: 46px;
  }
</style>
