<template>
  <InputFieldLabel
    :label="label"
    :inputActive="inputActive"
    @label-click="focusInput"
  >
    <textarea
      maxlength=""
      :ref="name"
      class="tw-w-full tw-transition-all custom-form__textarea tw-pl-5 tw-pt-5"
      :class="[
        height,
        v$.value.$error ? 'custom-form__textarea--error' : null,
        disabled ? 'tw-cursor-not-allowed' : null,
      ]"
      v-model="value"
      @focus="inputActive = true"
      @input="$emit('input')"
      @blur="!value || value.length === 0 ? (inputActive = false) : null"
    />
    <p class="tw-text-red-error" v-if="v$.value.$error">
      {{ v$.$errors[0].$message }}
    </p>
  </InputFieldLabel>
</template>

<script>
  import InputFieldLabel from "./InputFieldLabel";
  import { required } from "@vuelidate/validators";
  import useValidate from "@vuelidate/core";
  export default {
    name: "AssetTextField",
    data() {
      return {
        inputActive: false,
        value: null,
        v$: useValidate(),
      };
    },
    components: {
      InputFieldLabel,
    },
    props: {
      label: {
        type: String,
        required: true,
        default: () => "",
      },
      currentValue: {
        type: String,
        required: false,
        default: () => "",
      },
      name: {
        type: String,
        required: true,
        default: () => "",
      },
      disabled: {
        type: Boolean,
        required: false,
      },
      validate: {
        type: Boolean,
        required: true,
      },
      height: {
        type: String,
        required: false,
        default: () => "tw-h-12",
      },
    },
    validations() {
      return {
        value: {
          required,
        },
      };
    },
    emits: ["input-changed", "input", "is-valid"],
    watch: {
      value(val) {
        if (val && val.length > 0) {
          this.isValid = true;
          this.inputActive = true;
        }
        if (!val || val.length === 0) {
          this.isValid = false;
        }
        this.$emit("input-changed", { name: this.name, value: val });
      },
      currentValue(val) {
        if (val !== this.value) {
          this.value = val;
        }
      },
      validate(val) {
        if (val) {
          this.validateInput();
        }
      },
    },
    methods: {
      validateInput() {
        this.v$.value.$touch();
        this.isValid = !this.v$.value.$error ? true : false;
        this.$emit("is-valid", this.isValid);
      },
      focusInput() {
        this.$refs[this.name].focus();
      },
    },
    mounted() {
      this.isValid = this.value ? true : false;
      this.value = this.currentValue;
    },
  };
</script>

<style lang="scss" scoped>
  .custom-form {
    &__textarea {
      border: 1px solid #dde1e9;
      border-radius: 4px;
      &--error {
        border: 1px solid #db4343;
      }
    }
  }
</style>
