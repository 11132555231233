<template>
  <table id="members-table" class="tw-w-full tw-mb-8">
    <thead class="header">
      <tr class="header-row">
        <th class="header-data tw-font-normal tw-py-6" ref="tableHead">
          <div
            class="tw-justify-self-start tw-pl-10"
            v-for="(header, index) in tableHeaders"
            :key="'header' + index"
          >
            {{ header }}
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr class="body-row">
        <td class="body-data" ref="tableRow">
          <div
            class="data tw-py-6 tw-border-t tw-border-gray-light hover:tw-bg-gray-lightest"
            :ref="rowDetails"
            v-for="(details, index) in tableRows"
            :key="'row' + index"
          >
            <div
              class="tw-self-center tw-pl-10"
              v-for="(detail, index) in details"
              :key="'colunm' + index"
            >
              <p v-html="detail" class="tw-text-sm"></p>
            </div>
            <!-- <span class="tw-flex tw-self-center tw-pl-11">
              <img
                src="https://res.cloudinary.com/djalafcj9/image/upload/v1635311248/getequityV2/options-horizontal_ps9ovq.webp"
                class="tw-cursor-pointer"
                alt="share"
                loading="lazy"
              />
            </span> -->
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <Pagination
        :totalPage="totalPage"
        :currentPage="currentPage"
        :maxVisible="9"
        @pageChange="gotoPage"
      />
    </tfoot>
  </table>
</template>

<script>
  import Pagination from "@/components/navigation/Pagination.vue"

  export default {
    name: "MembersTable",

    components: {
      Pagination,
    },

    props: {
      tableHeaders: { type: Array, default: () => [], required: true },
      tableRows: { type: Array, default: () => [], required: true },
      columnNumber: { type: Number, default: () => 0, required: true },
      columnWidth: { type: Number, default: () => 0, required: true },
      totalPage: { type: Number, default: () => 0, required: true },
      currentPage: { type: Number, default: () => 0, required: true },
      hasStatus: { type: Boolean, required: false, default: () => true },
    },

    emits: ["gotoPage"],

    data() {
      return {
        rows: [],
        type: "",
        colunmSize: "",
      }
    },

    mounted() {
      this.setTableColumn()
    },

    methods: {
      rowDetails(el) {
        this.rows.push(el)
      },

      setTableColumn() {
        this.colunmSize = `repeat(${this.columnNumber}, ${
          this.columnWidth
        }px) ${180}px`
        this.setColumnStatus()

        this.$refs.tableHead.style.gridTemplateColumns = this.colunmSize
        this.rows.forEach((row) => {
          row.style.gridTemplateColumns = this.colunmSize
        })
      },

      setColumnStatus() {
        this.rows.forEach((row) => {
          let columns = row.querySelectorAll("p")
          if (columns[columns.length - 1].innerHTML === "Active") {
            columns[columns.length - 1].classList.add("active")
          } else if (columns[columns.length - 1].innerHTML === "Deactivated") {
            columns[columns.length - 1].classList.add("deactivated")
          } else if (this.hasStatus) {
            columns[columns.length - 1].classList.add("pending")
          }
        })
      },

      gotoPage(page) {
        this.$emit("gotoPage", page)
      },
    },
  }
</script>

<style lang="scss" scoped>
  #members-table {
    @include fadeIn;

    .header {
      .header-row {
        .header-data {
          display: grid;
          column-gap: 2rem;
        }
      }
    }

    .table-body {
      .body-row {
        .body-data {
          .data {
            display: grid;
            column-gap: 2rem;
            font-size: 0.7rem;
          }

          // Added with javascript
          .active,
          .pending,
          .deactivated {
            text-align: center;

            padding: 0.5rem;
            margin-right: 1.4rem;
            border-radius: 5px;
          }

          .active {
            color: $success;
            background-color: $success-light;
          }
          .pending {
            color: $warning;
            background-color: $warning-light;
          }

          .deactivated {
            color: $error;
            background-color: $error-light;
          }
        }
      }
    }
  }
</style>
