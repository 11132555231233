<template>
  <div id="table-header" class="tw-relative">
    <div class="sm:tw-hidden">
      <label :for="label"></label>
      <select
        class="tw-w-full tw-bg-gray-light tw-text- tw-text-sm tw-rounded tw-p-4 tw-cursor-pointer tw-border-0 focus:tw-border-primary"
        name="country code"
        id="country-code"
        v-model="selected"
      >
        <option
          class="tw-flex tw-items-center"
          v-for="(option, index) in options"
          :value="option"
          :key="index"
        >
          <p>
            {{ option }}
          </p>
        </option>
      </select>
      <img
        src="@/assets/img/chevron-down.svg"
        class="tw-absolute tw-right-2 tw-bottom-6"
        alt="chevron icon"
      />
    </div>
    <div class="tw-hidden sm:tw-block">
      <ul class="tw-flex tw-border-b tw-overflow-auto">
        <li
          class="tw-border-b-4  tw-cursor-pointer tw-whitespace-nowrap tw-transition-all tw-duration-300 hover:tw-border-primary tw-py-4 tw-mx-8"
          :class="[
            tab === option
              ? 'tw-border-primary tw-text-gray-black'
              : 'tw-border-white tw-text-gray',
          ]"
          v-for="(option, index) in options"
          :key="index"
          @click="updateTab"
        >
          {{ option }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableHeader",

  emits: ["emitOption"],

  props: {
    options: { type: Array, default: () => [], required: true },
    label: { type: String, default: () => "", required: true },
    tab: { type: String, default: () => "", required: true },
  },

  data() {
    return {
      selected: "",
    };
  },

  mounted() {
    if (this.$route.params.option === "secondary") {
      this.selected = "Secondaries";
      return;
    }
    if (this.$route.params.option === "tokens") {
      this.selected = "My Tokens";
      return;
    }
    this.selected = this.options[0];
  },

  methods: {
    updateTab(event) {
      this.selected = event.target.innerText;
    },

    emitOption(option) {
      if (this.$route.query.option === "secondary") {
        this.$router.push({ name: "Trade" });
        this.selected = "Secondaries";
      }
      this.$emit("emitOption", option);
    },
  },

  watch: {
    selected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.emitOption(newValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
