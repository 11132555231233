<template>
  <Accordion
    :info="info"
    @is-active="setActiveTab"
    :verified="isFilled"
    icon="https://res.cloudinary.com/djalafcj9/image/upload/v1668692227/getequityV2/ass-info_aa18ib.svg"
  >
    <form
      @submit.prevent="checkFormValidation"
      class="asset-info__form lg:tw-flex tw-justify-between tw-mt-12 md:tw-px-8 lg:tw-px-16 tw-flex-wrap tw-relative"
    >
      <Loader v-if="loading" />
      <div class="tw-w-full lg:tw-w-48">
        <DragNDrop
          accept="image/png, image/jpeg, image/jpg,"
          :currentValue="assetInfo.image"
          @file-added="setImage"
          :validate="validateForm"
          :startUpload="uploadFile"
          @reupload="uploadFile = false"
          @upload-complete="addAsset"
          @is-valid="handleValidation"
        />
      </div>
      <div class="tw-w-full lg:tw-w-8/12 tw-pt-6 lg:tw-pt-0">
        <InputField
          class="tw-mb-6"
          :validate="validateForm"
          label="Asset Name"
          :currentValue="assetInfo.name"
          name="name"
          @input-changed="storeValue"
          @is-valid="handleValidation"
        />
        <Dropdown
          :options="industries"
          class="tw-mb-6"
          name="industry"
          label="Industry"
          :validate="validateForm"
          :currentValue="assetInfo.industry"
          @input-changed="storeValue"
          @is-valid="handleValidation"
        />
        <InputFieldLabel
          label="Date of Incorporation"
          :inputActive="(dateActive = true)"
        >
          <Datepicker
            @focus="dateActive = true"
            @blur="dateActive = true"
            class="tw-w-full tw-pl-5 tw-text-secondary asset-info__datepicker tw-h-12"
            :class="[
              !assetInfo.dateOfIncorporation && isValidated ? null : 'tw-mb-5',
            ]"
            :upperLimit="new Date()"
            placeholder="Date of Incorporation"
            name="dateOfIncorporation"
            v-model="dateOfIncorporation"
          ></Datepicker>
        </InputFieldLabel>
        <p
          class=" tw-mt-1 tw-text-sm required"
          v-if="!assetInfo.dateOfIncorporation && isValidated"
        >
          This field is required
        </p>
        <Dropdown
          :options="companyTypes"
          name="company_type"
          class="tw-mb-6"
          :validate="validateForm"
          :currentValue="assetInfo.company_type"
          label="Company Type"
          @input-changed="storeValue"
          @is-valid="handleValidation"
        />
        <Dropdown
          :options="stages"
          name="stage"
          class="tw-mb-6"
          :validate="validateForm"
          label="Stage"
          :currentValue="assetInfo.stage"
          @input-changed="storeValue"
          @is-valid="handleValidation"
        />
        <TextField
          height="tw-h-32"
          name="about"
          label="Asset Summary"
          :currentValue="assetInfo.about"
          @input-changed="storeValue"
          class="tw-mb-6"
          :validate="validateForm"
          @is-valid="handleValidation"
        />
      </div>
      <div
        class="tw-w-full tw-mt-8"
        v-if="
          ($route.name === 'CreateAsset' && !$route.query.preview) ||
            ($route.name !== 'CreateAsset' &&
              !dealInfo &&
              !$route.query.preview) ||
            ($route.name !== 'CreateAsset' &&
              dealInfo &&
              
              !$route.query.preview)
        "
      >
        <div class="tw-w-32">
          <DealButton text="Continue" class="tw-text-sm" :disabled="loading" />
        </div>
      </div>
    </form>
  </Accordion>
</template>

<script>
  import DragNDrop from "@/components/general/DragNDrop";
  import InputField from "@/components/general/InputField";
  import TextField from "@/components/general/TextField";
  import Dropdown from "@/components/general/Dropdown";
  import Datepicker from "vue3-datepicker";
  import DealButton from "@/components/deals/DealsButton";
  import Accordion from "@/components/setup/Accordion";
  import { mapActions, mapGetters } from "vuex";
  import InputFieldLabel from "@/components/general/InputFieldLabel";
  import { industries, stages, companyTypes } from "@/utils/helpers.js";
  export default {
    name: "AssetInfo",
    emits: ["input", "is-active"],
    data() {
      return {
        info: {
          title: "Asset Information",
          description: "",
        },
        uploadFile: false,
        loading: false,
        assetInfo: {
          name: null,
          type: "Company",
          industry: null,
          dateOfIncorporation: null,
          company_type: null,
          stage: null,
          about: null,
          image: "",
        },
        dateOfIncorporation: new Date(),
        dateActive: null,
        industries,
        stages,
        companyTypes,
        validateForm: false,
        validationResult: [],
        isValidated: false,
      };
    },
    components: {
      DragNDrop,
      InputField,
      Dropdown,
      Datepicker,
      TextField,
      DealButton,
      InputFieldLabel,
      Accordion,
    },
    inject: ["activeTab"],
    computed: {
      ...mapGetters("Asset", ["asset"]),
      ...mapGetters("Deals", ["dealInfo"]),
      isFilled() {
        return !Object.values(this.assetInfo).includes(null);
      },
    },
    watch: {
      asset(val) {
        if (val && !this.$route.query.new) {
          this.setValues();
        }
      },
      uploadFile(val) {
        if (val) {
          this.loading = true;
        }
      },
      dateOfIncorporation(val, old) {
        if (String(val) !== String(old)) {
          this.dateOfIncorporation = new Date(val);
          this.assetInfo.dateOfIncorporation = new Date(val)
            .toISOString()
            .split("T")[0];
        }
      },
      validationResult: {
        deep: true,
        handler(val) {
          if (
            val.length === Object.keys(this.assetInfo).length &&
            !val.includes(false)
          ) {
            this.uploadFile = true;
          }
        },
      },
    },
    mounted() {
      if (this.asset && this.$route.query.assetName) {
        this.setValues();
      } else {
        this.resetValues();
      }
      if (this.$route.query.name) {
        this.assetInfo.name = this.$route.query.name;
      }
      if (this.dateOfIncorporation) {
        this.dateOfIncorporation = new Date(this.dateOfIncorporation);
        this.assetInfo.dateOfIncorporation = new Date(this.dateOfIncorporation)
          .toISOString()
          .split("T")[0];
      }
      if (this.$route.query.assetName) {
        this.assetInfo.name = this.$route.query.assetName;
      }
    },
    methods: {
      ...mapActions("Asset", ["createAsset", "updateAsset", "fetchAsset"]),
      setImage(val) {
        this.assetInfo.image = val;
      },
      setActiveTab(title) {
        this.$emit("is-active", title);
      },
      setValues() {
        this.assetInfo.name = this.asset.name;
        this.assetInfo.type = this.asset.type;
        this.assetInfo.industry = this.asset.industry;
        this.assetInfo.dateOfIncorporation = new Date(
          this.asset.dateOfIncorporation
        );
        this.dateOfIncorporation = this.assetInfo.dateOfIncorporation;
        this.assetInfo.company_type = this.asset.company_type;
        this.assetInfo.stage = this.asset.stage;
        this.assetInfo.about = this.asset.about;
        this.assetInfo.image = this.asset.image;
      },
      resetValues() {
        this.assetInfo.name = null;
        this.assetInfo.type = "Company";
        this.assetInfo.industry = null;
        this.assetInfo.dateOfIncorporation = null;
        this.assetInfo.company_type = null;
        this.assetInfo.stage = null;
        this.assetInfo.about = null;
        this.assetInfo.image = "";
      },
      checkFormValidation() {
        this.validationResult = [];
        this.validateForm = true;
        this.isValidated = true;
        if (this.assetInfo.dateOfIncorporation) {
          this.handleValidation(true);
        } else {
          this.handleValidation(false);
        }
        if (this.assetInfo.type) {
          this.handleValidation(true);
        } else {
          this.handleValidation(false);
        }
        setTimeout(() => {
          this.validateForm = false;
        }, 1000);
      },
      handleValidation(value) {
        this.validationResult.push(value);
      },
      storeValue(val) {
        this.assetInfo[val.name] = val.value;
      },
      async addAsset(url) {
        this.assetInfo.image = url;
        this.uploadFile = false;
        this.loading = true;
        try {
          if (this.asset && this.asset._id && !this.$route.query.new) {
            await this.updateAsset({
              asset: this.assetInfo,
              assetId: this.asset._id,
            });
          } else {
            await this.createAsset(this.assetInfo);
            this.$router.push({
              name: "CreateAsset",
              query: { id: this.asset._id },
            });
          }
          this.$emit("is-active", "Investment Memo");

          this.fetchAsset(this.asset._id);
          this.loading = false;
        } catch (error) {
          this.loading = false;
          return error;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .asset-info {
    height: 72px;
    border: 1px solid #f1f3f6;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 800ms ease-out 0s;
    max-width: 818px;
    overflow-y: hidden;
    margin-left: auto;
    margin-right: auto;
    &--long {
      height: 900px;
      overflow-y: scroll;
    }
    &__button {
      width: 24px;
      height: 24px;
      &:hover {
        background: inherit;
      }
    }
  }
  .required {
    color: indianred;
  }

  @media (min-width: 768px) {
    .asset-info {
      &__form {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
</style>
