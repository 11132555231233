<template>
  <div id="back-btn">
    <button
      @click="$router.go(-1)"
      class="tw-w-8 tw-h-8 tw-rounded-full tw-flex tw-items-center hover:tw-bg-transparent"
    >
      <img src="@/assets/img/arrow-back.svg" alt="arrow" />
    </button>
  </div>
</template>

<script>
  export default {
    name: "BackBtn",
  };
</script>

<style lang="scss" scoped></style>
