<template>
  <InputFieldLabel class="tw-mb-8" :label="name" :inputActive="inputActive">
    <section
      class="editor tw-pl-5 tw-pt-5 tw-h-60 tw-overflow-y-scroll tw-relative"
    >
      <editor-content
        :editor="editor"
        class="tw-w-full tw-overflow-y-scroll editor__textarea"
      />
      <div
        class="editor__nav tw-overflow-x-scroll tw-mb-4 tw-absolute tw-w-full"
      >
        <div
          class="tw-flex tw-gap-x-3 tw-justify-center tw-overflow-x-scroll tw-w-min editor__nav tw-pl-5"
          v-if="editor"
        >
          <button
            class="hover:tw-bg-white hover:tw-border-primary hover:tw-border tw-h-10 tw-px-4 tw-py-4 tw-flex tw-items-center tw-rounded tw-w-max"
            type="button"
            title="Bold"
            @click="
              editor
                .chain()
                .focus()
                .toggleBold()
                .run()
            "
            :class="{ 'is-active': editor.isActive('bold') }"
          >
            <img
              src="https://res.cloudinary.com/djalafcj9/image/upload/v1650985884/getequityV2/wysiwyg%20icons/Vector_ryjtqe.svg"
              alt="Bold"
            />
          </button>
          <button
            class="hover:tw-bg-white hover:tw-border-primary hover:tw-border tw-h-10 tw-px-4 tw-py-4 tw-flex tw-items-center tw-rounded tw-w-max"
            title="bullet point"
            type="button"
            @click="
              editor
                .chain()
                .focus()
                .toggleBulletList()
                .run()
            "
            :class="{ 'is-active': editor.isActive('bulletList') }"
          >
            <img
              src="https://res.cloudinary.com/djalafcj9/image/upload/v1650985879/getequityV2/wysiwyg%20icons/Vector_2_ksq6b5.svg"
              alt="bullet point"
            />
          </button>
          <!-- <button
          class="hover:tw-bg-white hover:tw-border-primary hover:tw-border tw-h-10 tw-px-4 tw-py-4 tw-flex tw-items-center tw-rounded tw-w-max"
          type="button"
          @click="
            editor
              .chain()
              .focus()
              .toggleOrderedList()
              .run()
          "
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          ordered list
        </button> -->
          <button
            class="hover:tw-bg-white hover:tw-border-primary hover:tw-border tw-h-10 tw-px-4 tw-py-4 tw-flex tw-items-center tw-rounded tw-w-max"
            type="button"
            title="line break"
            @click="
              editor
                .chain()
                .focus()
                .setHardBreak()
                .run()
            "
          >
            <img
              src="https://res.cloudinary.com/djalafcj9/image/upload/v1650985877/getequityV2/wysiwyg%20icons/Vector_3_wndgyf.svg"
              alt="line break"
            />
          </button>
          <button
            class="hover:tw-bg-white hover:tw-border-primary hover:tw-border tw-h-10 tw-px-4 tw-py-4 tw-flex tw-items-center tw-rounded tw-w-max"
            :class="{ 'is-active': editor.isActive('underline') }"
            title="underline"
            type="button"
            @click="
              editor
                .chain()
                .focus()
                .toggleUnderline()
                .run()
            "
          >
            <img
              src="https://res.cloudinary.com/djalafcj9/image/upload/v1650985882/getequityV2/wysiwyg%20icons/Vector_1_dyopln.svg"
              alt="underline"
            />
          </button>
          <button
            class="hover:tw-bg-white hover:tw-border-primary hover:tw-border tw-h-10 tw-px-4 tw-py-4 tw-flex tw-items-center tw-rounded"
            type="button"
            title="undo"
            @click="
              editor
                .chain()
                .focus()
                .undo()
                .run()
            "
          >
            <img
              src="https://res.cloudinary.com/djalafcj9/image/upload/v1650985875/getequityV2/wysiwyg%20icons/Vector_4_q48tvo.svg"
              alt="undo"
            />
          </button>
          <button
            class="hover:tw-bg-white hover:tw-border-primary hover:tw-border tw-h-10 tw-px-4 tw-py-4 tw-flex tw-items-center tw-rounded"
            type="button"
            title="redo"
            @click="
              editor
                .chain()
                .focus()
                .redo()
                .run()
            "
          >
            <img
              src="https://res.cloudinary.com/djalafcj9/image/upload/v1650985873/getequityV2/wysiwyg%20icons/Vector_5_iamppy.svg"
              alt="redo"
            />
          </button>
        </div>
      </div>
    </section>
  </InputFieldLabel>
</template>

<script>
  import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";
import InputFieldLabel from "./InputFieldLabel";
  export default {
    name: "WysiwygEditor",
    components: {
      EditorContent,
      InputFieldLabel,
    },
    data() {
      return {
        inputActive: false,
        editor: null,
      };
    },
    props: {
      name: {
        type: String,
        required: true,
        default: () => "",
      },
      placeholder: {
        type: String,
        required: true,
        default: () => "",
      },
      content: {
        type: String,
        required: false,
        default: () => "",
      },
    },
    watch: {
      content(newContent) {
        const isSame = this.editor.getHTML() === newContent;

        if (isSame) {
          return;
        }
        if (newContent?.length > 0) {
          this.inputActive = true;
        }

        this.editor.commands.setContent(newContent, false);
      },
    },

    // Line breaks, Bold, Underline, Bullet points.

    mounted() {
      this.editor = new Editor({
        content: this.content,
        extensions: [StarterKit, Underline],
        onUpdate: () => {
          if (this.editor.getHTML().length > 0) {
            this.inputActive = true;
          } else {
            this.inputActive = false;
          }
          this.$emit("update:modelValue", {
            name: this.name,
            value: this.editor.getHTML(),
          });

          // JSON
          // this.$emit('update:modelValue', this.editor.getJSON())
        },
        onFocus: () => {
          this.inputActive = true;
        },
        onBlur: () => {
          this.editor.getHTML().length === 0
            ? (this.inputActive = false)
            : (this.inputActive = true);
        },
      });
    },

    beforeUnmount() {
      this.editor.destroy();
    },
  };
</script>

<style lang="scss">
  .editor {
    border: 1px solid #dde1e9;
    &__nav {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      z-index: 2;
      background-color: #fff;
      bottom: 0;
      left: 0;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__textarea {
      max-height: 200px;
      padding-bottom: 50px;
    }
  }

  .ProseMirror {
    min-height: 60px;
    outline: none;
    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0 1rem;
    }
    ol {
      list-style-type: decimal !important;
    }

    ul {
      list-style-type: disc;
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }
  }
  .is-active {
    @apply tw-border;
  }
</style>
