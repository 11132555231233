<template>
  <Modal :show="show" @close-modal="closeModal">
    <div class="asset-search tw-w-full" v-if="showDraft">
      <h1 class="tw-font-medium tw-mb-6 tw-text-lg asset-search__heading">
        Choose from your previously created assets
      </h1>
      <div class="asset-draft__container tw-mb-6">
        <router-link
          v-for="draft in draftAssets"
          :key="draft._id"
          :to="{ name: 'CreateAsset', query: { id: draft._id } }"
        >
          <div class="asset-draft__item tw-p-8 tw-mb-2 tw-cursor-pointer">
            {{ draft.name }}
          </div>
        </router-link>
      </div>
      <router-link :to="{ name: 'CreateAsset', query: { new: true } }">
        <DealsButton text="Create a new Asset" />
      </router-link>
      <p class="tw-font-medium tw-mt-6 tw-text-black tw-text-center">OR</p>
      <p
        class="tw-text-center tw-mt-3 tw-text-black-light tw-cursor-pointer"
        @click="showDraft = false"
      >
        Search for another asset
      </p>
    </div>

    <div class="asset-search tw-w-full tw-relative" v-else>
      <h1
        class="tw-mb-1 tw-text-xl tw-flex tw-font-CircularStd-medium tw-font-medium tw-text-secondary"
      >
        <button
          class="tw-w-8 tw-h-8 hover:tw-bg-white"
          @click="showDraft = true"
        >
          <img src="@/assets/img/back-icon.svg" alt="" />
        </button>
        Asset Name
      </h1>
      <p class="tw-mb-8 tw-ml-8 tw-text-xs tw-text-black-light">
        Click on an asset to preview its details
      </p>
      <form @submit.prevent="" class="">
        <InputField
          label="Name of Asset"
          name="asset"
          :validate="false"
          class="tw-text-sm"
          @input-changed="storeValue"
        />
        <template
          v-if="
            (asset.length > 1 && !assets) ||
              (asset.length > 1 && assets.length === 0)
          "
        >
          <p
            class="tw-text-center tw-text-sm tw-mt-6 tw-mb-6 tw-text-black-light"
          >
            Is the asset not listed?
          </p>
          <router-link
            :to="{ name: 'CreateAsset', query: { new: true, name: asset } }"
          >
            <DealsButton :disabled="!asset" text="Add New Asset" />
          </router-link>
        </template>
      </form>
      <div
        class="tw-mt-3 tw-rounded asset-search-results__div tw-absolute tw-pt-3"
        v-if="assets && assets.length !== 0 && asset.length !== 0"
      >
        <div
          class="tw-flex tw-justify-end tw-mb-4 tw-absolute tw-right-[16px] tw-top-[16px]"
        >
          <img
            src="@/assets/img/close-dark.svg"
            alt="close"
            class="tw-cursor-pointer"
            @click="showDraft = true"
          />
        </div>
        <router-link
          :to="{ name: 'CreateAsset', query: { id: asset._id, preview: true } }"
          class="tw-flex tw-items-center tw-mb-6 tw-cursor-pointer asset-search-results__item"
          v-for="asset in assets"
          :key="asset._id"
        >
          <img
            :src="asset.image"
            :alt="asset.name"
            class="tw-w-8 tw-h-8 tw-rounded-full tw-object-contain"
          />
          <p
            class="tw-font-CircularStd-medium tw-font-medium tw-ml-5 tw-text-black"
          >
            {{ asset.name }}
          </p>
        </router-link>
      </div>
    </div>
  </Modal>
</template>

<script>
  import Modal from "@/components/general/Modal";
  import InputField from "@/components/general/InputField";
  import DealsButton from "@/components/deals/DealsButton";
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: "AssetSearch",
    components: {
      Modal,
      InputField,
      DealsButton,
    },
    data() {
      return {
        asset: "",
        showDraft: false,
      };
    },
    emits: ["close-asset-search", "asset-selected"],
    props: {
      show: {
        type: Boolean,
        required: true,
      },
    },
    watch: {
      show(val) {
        if (val) {
          this.fetchAssetDraft();
        }
      },
      asset(val) {
        if (val) {
          this.searchAsset(val);
        }
      },
      draftAssets(val) {
        if (val && val.length !== 0) {
          this.showDraft = true;
        }
      },
    },
    computed: {
      ...mapGetters("Asset", ["draftAssets", "assets"]),
    },
    methods: {
      ...mapActions("Asset", ["fetchAssetDraft", "searchAsset"]),
      storeValue(val) {
        this[val.name] = val.value;
      },
      closeModal() {
        this.asset = "";
        this.$emit("close-asset-search");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .asset-search {
    width: 300px;
    padding-bottom: 40px;
    &-results {
      &__div {
        max-height: 200px;
        overflow-y: scroll;
        background: #fff;
        box-shadow: 0px 2px 4px rgba(44, 73, 137, 0.06);
        width: 100%;
        padding-left: 39px;
        padding-right: 39px;
      }
      &__item {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .asset-draft {
    &__container {
      max-height: 200px;
      overflow-y: scroll;
    }
    &__item {
      background: #f7f9fd;
      border-radius: 4px;
      &:hover {
        background: #375cb1;
        color: #fff;
      }
    }
  }

  @media (min-width: 600px) {
    .asset-search {
      width: 435px;
    }
  }
</style>
