<template>
  <InputFieldLabel
    :label="label"
    :inputActive="inputActive"
    @label-click="focusInput"
  >
    <input
      :type="type"
      :name="label"
      :ref="name"
      :id="name"
      :readonly="disabled"
      :max="maxValue ? maxValue : null"
      :min="minValue ? minValue : null"
      step="0.01"
      class="tw-w-full tw-rounded custom-form__input tw-pl-6 tw-text-secondary"
      :class="[
        height,
        border ? border : 'custom-form__input--default',
        v$.value.$error ? 'custom-form__input--error' : null,
        disabled ? 'tw-cursor-not-allowed' : null,
      ]"
      v-model="value"
      @focus="inputActive = true"
      @input="$emit('input')"
      @blur="!value || value.length === 0 ? (inputActive = false) : null"
    />
    <p class="tw-text-red-error" v-if="v$.value.$error">
      {{ v$.$errors[0].$message }}
    </p>
  </InputFieldLabel>
</template>

<script>
  import InputFieldLabel from "./InputFieldLabel";
  import { required, helpers } from "@vuelidate/validators";
  import useValidate from "@vuelidate/core";
  import { isNumber, formatAmountToDollar } from "@/utils/helpers.js";
  export default {
    name: "RangeField",
    data() {
      return {
        inputActive: false,
        value: null,
        v$: useValidate(),
        isValid: true,
      };
    },
    components: {
      InputFieldLabel,
    },
    validations() {
      return {
        value: {
          required: this.required ? required : false,
          numericIfNumber: helpers.withMessage(
            "This field only accepts numbers",
            this.numericIfNumber
          ),
          validateMaxValue: helpers.withMessage(
            `${
              this.maxValueErrorMessage ||
              "This is more than the maximum value of"
            } ${this.maxValue}`,
            this.validateMaxValue
          ),
          validateMinValue: helpers.withMessage(
            `This field requires a minimum value of ${this.minValue}`,
            this.validateMinValue
          ),
        },
      };
    },
    props: {
      label: {
        type: String,
        required: true,
        default: () => "",
      },
      // value that the input value should not be grater than
      maxValue: {
        type: [Number, String],
        required: false,
      },
      minValue: {
        type: [Number, String],
        required: false,
      },
      maxValueErrorMessage: {
        type: String,
        required: false,
      },
      validate: {
        type: Boolean,
        required: true,
      },
      disabled: {
        type: Boolean,
        required: false,
      },
      type: {
        type: String,
        required: false,
      },
      //border color class for the input
      border: {
        type: String,
        required: false,
      },
      //'type' can be text but still only accept numbers(one reason is because in order to format input, you need to make the text a string otherwise the input field would flag "," in "7,000,000"), hence this prop;
      validateAsNumber: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      name: {
        type: String,
        required: true,
        default: () => "",
      },
      currentValue: {
        required: false,
        default: () => "",
      },
      required: {
        type: Boolean,
        required: false,
        default: () => true,
      },
      height: {
        type: String,
        required: false,
        default: () => "tw-h-12",
      },
    },
    emits: ["input-changed", "input", "is-valid"],
    watch: {
      value(val) {
        if ((val && val.length > 0) || (val && val > 0)) {
          this.inputActive = true;
          this.isValid = true;
        }
        if (!val || val.length === 0) {
          this.isValid = false;
        }
        this.$emit("input-changed", { name: this.name, value: val });
      },
      currentValue(val) {
        if (val !== this.value) {
          this.value = val;
        }
      },
      validate(val) {
        if (val) {
          this.validateInput();
        }
      },
    },
    methods: {
      isNumber,
      formatAmountToDollar,
      focusInput() {
        this.$refs[this.name].focus();
      },
      validateInput() {
        this.v$.value.$touch();
        //check if field is required
        if (this.required) {
          this.isValid = !this.v$.value.$error ? true : false;
        } else {
          this.isValid = true;
        }
        this.$emit("is-valid", this.isValid);
      },
      numericIfNumber(value) {
        if (this.type === "number") {
          //check if value passes as a number and return true or false
          return !isNaN(value);
        } else if (this.validateAsNumber) {
          //first remove commas from number
          const number = value.split(",").join("");
          return !isNaN(Number(number));
        }
        return true;
      },
      validateMaxValue(value) {
        if (this.maxValue) {
          const number = String(value).split(",").join("");
          const maxValue = String(this.maxValue).split(",").join("");
          const isLessThanMaxValue = Number(number) <= maxValue;
          return isLessThanMaxValue;
        }
        return true;
      },
      validateMinValue(value) {
        if (this.minValue) {
          const number = String(value).split(",").join("");
          const minValue = String(this.minValue).split(",").join("");
          const isGreaterThanMinValue = Number(number) >= minValue;
          return isGreaterThanMinValue;
        }
        return true;
      },
    },
    mounted() {
      this.isValid = this.value ? true : false;
      this.value = this.currentValue;
    },
  };
</script>

<style lang="scss" scoped>
  .custom-form {
    &__input {
      transition: all 500ms ease-in-out;
      padding-left: 28px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &--default {
        border: 1px solid #e1e6f0;
      }

      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
      }
      &--error {
        border: 1px solid #db4343;
      }
      &:focus {
        border: 1px solid #4d84ff;
      }
    }
  }
</style>
