<template>
  <section>
    <SmallLoader v-if="loading" />
    <template v-else>
      <div v-if="spvId">
        <div
          class="tw-max-w-[416px] tw-mx-auto"
          v-if="spvInfo && spvInfo.status === 'Pending'"
        >
          <img
            src="@/assets/img/bell.svg"
            class="tw-w-20 tw-h-24 tw-block tw-mx-auto"
          />
          <p class="tw-text-secondary tw-mt-5 tw-text-center">
            The SPV you have attached to this deal is currently being reviewed.
            To change this SPV, please reach out to us.
          </p>
          <a href="mailto:support@getequity.io">
            <button
              class="tw-rounded tw-text-secondary tw-mt-10 tw-font-CircularStd-medium tw-font-medium tw-h-12 tw-bg-blue-bg200 tw-w-full tw-max-w-[260px] tw-mx-auto tw-block"
            >
              Contact Support
            </button>
          </a>
        </div>
        <div
          class="tw-w-full"
          v-else-if="spvInfo && spvInfo.status === 'Uploaded'"
        >
          <div class="tw-flex tw-gap-x-4 tw-items-start">
            <img src="@/assets/img/spv.svg" alt="" />
            <div class="tw-w-full tw-max-w-[625px]">
              <div class="tw-w-max tw-border-b tw-border-[#D9DCE8] tw-pb-4">
                <h1
                  class="tw-text-secondary tw-font-CircularStd-medium tw-font-medium tw-text-lg"
                >
                  {{ spvInfo.name }}
                </h1>
                <p class="tw-text-primary">{{ managementType }}</p>
              </div>
              <div class="tw-my-9 tw-flex tw-gap-x-6 lg:tw-justify-between">
                <div class="tw-w-max">
                  <p class="tw-text-sm tw-text-gray-light2 tw-mb-1">
                    Country of Incorporation
                  </p>
                  <p
                    class="tw-text-secondary tw-font-CircularStd-medium tw-font-medium"
                  >
                    {{ spvInfo.country }}
                  </p>
                </div>
                <div class="tw-w-max">
                  <p class="tw-text-sm tw-text-gray-light2 tw-mb-1">
                    Tax Identification Number
                  </p>
                  <p
                    class="tw-text-secondary tw-font-CircularStd-medium tw-font-medium"
                  >
                    {{ spvInfo.tin }}
                  </p>
                </div>
                <div class="tw-w-max">
                  <p class="tw-text-sm tw-text-gray-light2 tw-mb-1">
                    Date of Creation
                  </p>
                  <p
                    class="tw-text-secondary tw-font-CircularStd-medium tw-font-medium"
                  >
                    {{ formatISODate(spvInfo.createdAt) }}
                  </p>
                </div>
              </div>
              <DocumentViewer :doc="spvInfo" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="tw-max-w-[300px] tw-mx-auto">
        <EmptyState>
          <template v-slot:message>
            <p class="tw-text-gray tw-mt-2 tw-text-center ">
              This deal does not have an SPV attached to it.
            </p>
          </template>
        </EmptyState>
        <button
          class="tw-rounded tw-bg-primary tw-text-white tw-mt-6 tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-tracking-[-0.12px] tw-h-12 tw-w-36 tw-mx-auto tw-block tw-mb-4"
          @click="showUploadModal = true"
        >
          Upload SPV
        </button>
        <p class="tw-text-black tw-text-center">Or</p>
        <p
          class="tw-text-[#06206B] tw-mt-4 tw-text-sm tw-font-CircularStd-medium tw-font-medium tw-tracking-[-0.12px] tw-w-full tw-text-center"
        >
          Contact
          <a
            target="_blank"
            class="tw-underline"
            href="mailto:support@getequity.io"
            >support@getequity.io</a
          >
          to request an SPV
        </p>
      </div>
      <SpvUploadModal
        v-if="showUploadModal"
        @close-modal="showUploadModal = false"
        @show-success-modal="addSpvToDeal"
      />
      <SpvLinkSuccess
        v-if="showSuccessModal"
        @close-modal="showSuccessModal = false"
      />
    </template>
  </section>
</template>

<script setup>
  import { computed, ref } from "vue";
  import EmptyState from "../notification/EmptyState.vue";
  import SpvUploadModal from "./SpvUploadModal.vue";
  import SpvLinkSuccess from "@/components/spv/SpvLinkSuccess.vue";
  import DocumentViewer from "../general/DocumentViewer.vue";
  import { useRoute } from "vue-router";
  import { useStore } from "vuex";
  import { formatISODate } from "@/utils/helpers";

  const props = defineProps({
    spvId: {
      type: String,
      required: false,
      default: () => "",
    },
  });
  const route = useRoute();
  const store = useStore();

  const loading = computed(() => store.getters["loading"]);
  const spvId = computed(() => props.spvId);
  const spvInfo = computed(() => store.getters["Spv/spvInfo"]);
  const orgName = computed(
    () => store.state.organisationModule.organisationDetails.name
  );
  const managementType = computed(() =>
    spvInfo.value?.should_manage_spv
      ? "Managed by GetEquity"
      : `Managed by ${orgName.value}`
  );

  const showUploadModal = ref(false);
  const showSuccessModal = ref(false);

  const addSpvToDeal = async (response) => {
    const spv = response.data.data;
    const spvId = spv._id;
    const id = route.params.dealId;

    try {
      await store.dispatch("Deals/updateDealRequest", {
        id,
        deal: { spv: spvId },
      });
      await store.dispatch("Deals/fetchDealInfo", id);
      showUploadModal.value = false;
      showSuccessModal.value = true;
    } catch (error) {
      return error;
    }
  };
  const getSpvInfo = async () => {
    await store.dispatch("Spv/fetchSpvRequest", spvId.value);
  };
  if (spvId.value) {
    getSpvInfo();
  }
</script>

<style lang="scss" scoped></style>
