<template>
  <div
    class="tw-bg-[#FAFBFF] tw-border tw-border-dashed tw-border-[#C2D3FF] tw-rounded tw-p-4 md:tw-p-6 tw-flex md:tw-pr-10 tw-items-center"
  >
    <img
      src="https://res.cloudinary.com/djalafcj9/image/upload/v1658395421/getequityV2/doc-pin_sms6y6.svg"
      alt="logo"
    />
    <div class="tw-ml-2">
      <p
        class="tw-text-black tw-font-CircularStd-medium tw-text-medium tw-tracking-[-0.12px]"
      >
        {{ doc.name }}
      </p>
      <p class="tw-text-sm tw-text-gray-light2">PDF</p>
    </div>
    <button
      class="tw-text-primary tw-text-sm tw-font-CircularStd-medium tw-text-medium hover:tw-bg-transparent tw-ml-auto hover:tw-text-primary hover:tw-underline tw-flex tw-gap-x-2 tw-items-center tw-h-6"
      @click="downloadDoc"
    >
      <img src="@/assets/img/download-icon.svg" />
      Download
    </button>
  </div>
</template>

<script setup>
  const { doc } = defineProps({
    doc: {
      type: Object,
      required: true,
    },
  });

  const downloadDoc = () => {
    const url = doc.url;
    const name = doc.name;
    const a = document.createElement("a");

    a.setAttribute("href", url);

    a.setAttribute("download", `${name} SPV.pdf`);
    a.click();
  };
</script>

<style lang="scss" scoped></style>
