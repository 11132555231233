<template>
  <Accordion
    :info="info"
    @is-active="setActiveTab"
    :verified="isFilled"
    icon="https://res.cloudinary.com/djalafcj9/image/upload/v1668692227/getequityV2/fou-info_wiqluf.svg"
  >
    <form
      @submit.prevent="checkFormValidation"
      class="tw-flex tw-justify-between tw-mt-12 md:tw-px-8 lg:tw-px-16 tw-flex-wrap socials__form tw-relative"
    >
      <Loader v-if="loading" />

      <div class="tw-mb-6 md:tw-flex tw-gap-4 tw-w-full">
        <LinkInput
          linkPrefix="https://"
          label="Company website"
          :validate="validateForm"
          class="tw-mb-6 tw-w-full md:tw-w-1/2 md:tw-mb-0"
          name="website"
          :currentValue="socials.website"
          @input-changed="storeValue"
          @is-valid="handleValidation"
        />
        <LinkInput
          linkPrefix="https://facebook.com/"
          label="Facebook"
          :validate="validateForm"
          class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0"
          :required="false"
          name="facebook"
          :currentValue="socials.facebook"
          @input-changed="storeValue"
          @is-valid="handleValidation"
        />
      </div>
      <div class="tw-mb-6 md:tw-flex tw-flex-wrap tw-gap-4 tw-w-full">
        <LinkInput
          linkPrefix="https://twitter.com/"
          label="Twitter handle"
          :validate="validateForm"
          class="tw-mb-6 md:tw-w-1/2 md:tw-mb-0 md:tw-max-w-[336px]"
          :required="false"
          name="twitter"
          :currentValue="socials.twitter"
          @input-changed="storeValue"
          @is-valid="handleValidation"
        />
      </div>
      <div
        class="tw-w-full tw-mt-8"
        v-if="
          ($route.name === 'CreateAsset' && !$route.query.preview) ||
            ($route.name !== 'CreateAsset' &&
              !dealInfo &&
              !$route.query.preview) ||
            ($route.name !== 'CreateAsset' &&
              dealInfo &&
              
              !$route.query.preview)
        "
      >
        <div class="tw-w-32">
          <DealButton text="Continue" class="tw-text-sm" :disabled="loading" />
        </div>
      </div>
    </form>
  </Accordion>
</template>

<script>
import LinkInput from "@/components/general/LinkInput";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import DealButton from "@/components/deals/DealsButton";
import { mapActions, mapGetters } from "vuex";
import Accordion from "@/components/setup/Accordion";

export default {
  name: "Socials",
  emits: ["is-active"],
  data() {
    return {
      info: {
        title: "Socials",
        description: "",
      },
      loading: false,
      socials: {
        facebook: "",
        twitter: "",
        website: "",
      },
      validateForm: false,
      validationResult: [],
    };
  },
  components: {
    LinkInput,
    DealButton,
    Accordion,
  },
  inject: ["activeTab"],
  watch: {
    asset(val) {
      if (
        (val && val.website && this.$route.query.assetName) ||
        (val && val.website && this.$route.name === "DealInfo") ||
        (val &&
          val.website &&
          this.$route.name === "CreateAsset" &&
          !this.$route.query.new)
      ) {
        this.setValues();
      }
    },
    validationResult: {
      deep: true,
      handler(val) {
        if (
          val.length === Object.keys(this.socials).length &&
          !val.includes(false)
        ) {
          this.saveSocials();
        }
      },
    },
  },
  mounted() {
    if (
      this.asset &&
      this.asset?.website &&
      this.$route.query.assetName &&
      !this.$route.query.new
    ) {
      this.setValues();
    } else {
      this.socials = {
        facebook: "",
        twitter: "",
        website: "",
      };
    }
  },
  computed: {
    ...mapGetters("Asset", ["asset"]),
    ...mapGetters("Deals", ["dealInfo"]),
    isFilled() {
      return (
        !Object.values(this.socials).includes(null) &&
        !Object.values(this.socials).includes("")
      );
    },
  },
  methods: {
    ...mapActions("Asset", ["updateAsset", "fetchAsset"]),
    ...mapActions(["showToast"]),
    setActiveTab(title) {
      this.$emit("is-active", title);
    },
    storeValue(val) {
      this.socials[val.name] = val.value;
    },
    setValues() {
      this.socials.website = this.asset.website;
      this.socials.twitter = this.asset.twitter;
      this.socials.facebook = this.asset.facebook;
    },
    checkFormValidation() {
      this.validationResult = [];
      this.validateForm = true;
      setTimeout(() => {
        this.validateForm = false;
      }, 1000);
    },

    handleValidation(value) {
      this.validationResult.push(value);
    },
    async saveSocials() {
      this.loading = true;
      this.socials.facebook?.trim() === "" ? delete this.socials.facebook : "";
      this.socials.twitter?.trim() === "" ? delete this.socials.twitter : "";
      try {
        const response = await this.updateAsset({
          asset: this.socials,
          assetId: this.$route.query.id ? this.$route.query.id : this.asset._id,
        });
        const { message } = response.data;
        this.showToast({
          description: message,
          display: true,
          type: "success",
        });
        if (this.$route.name === "CreateAsset") {
          this.$router.push({
            name: "CreateDeal",
            query: { assetId: this.$route.query.id },
          });
        } else {
          this.fetchAsset(this.asset._id);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        return error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.socials {
  height: 72px;
  border: 1px solid #f1f3f6;
  box-sizing: border-box;
  border-radius: 4px;
  transition: height 800ms ease-out 0s;
  overflow-y: hidden;
  max-width: 818px;
  margin-left: auto;
  margin-right: auto;
  &--long {
    overflow-y: scroll;
    height: 350px;
  }
  &__button {
    width: 24px;
    height: 24px;
    &:hover {
      background: inherit;
    }
  }
  &__input {
    &::placeholder,
    &--empty {
      color: #a3a8b3;
    }
    border: 1px solid #dde1e9;
    box-sizing: border-box;
    border-radius: 4px;
  }
  &__form {
    gap: 20px;
    justify-content: center;
  }
  &-form {
    &__div {
      max-width: 335px;
      flex-basis: 48%;
    }
  }
}

.member {
  &__button {
    &:hover {
      background: transparent;
      color: inherit;
    }
  }
}

.required {
  color: indianred;
}
@media (min-width: 768px) {
  .gap {
    gap: 20px;
  }

  .socials {
    &__form {
      gap: 20px;
      padding-left: 0;
      padding-right: 0;
    }
    &-form__div {
      flex-basis: 48%;
      max-width: 335px;
      &--half {
        width: 48%;
        max-width: 335px;
      }
    }
  }
}
</style>
