<template>
  <section
    class="tw-bg-white tw-rounded-[5px] tw-border tw-border-[#F0F4FD] tw-py-5 tw-px-4 md:tw-px-[30px] tw-mb-5"
  >
    <button
      class="hover:tw-bg-white tw-w-full tw-flex  md:tw-flex-row tw-gap-y-3 md:tw-gap-y-0 "
      :class="[
        isOpen ? 'tw-mb-9' : null,
        isEditable ? 'tw-cursor-pointer' : 'tw-cursor-not-allowed',
        info.description
          ? 'md:tw-items-start tw-flex-col'
          : 'tw-items-center tw-flex tw-justify-start tw-gap-x-3',
      ]"
      @click="isEditable ? (isOpen = !isOpen) : null"
    >
      <div class="md:tw-mr-[16px] tw-self-center">
        <img
          src="https://res.cloudinary.com/djalafcj9/image/upload/v1653916132/getequityV2/Vector_si2wy8.svg"
          v-if="verified"
          alt="checked"
          class="tw-w-6 tw-h-6"
        />
        <img :src="icon" v-else class="tw-min-w-[24px] tw-w-6 tw-h-6" />
      </div>
      <div
        class="tw-max-w-[306px] md:tw-max-w-full md:tw-mr-auto lg:tw-w-[692px]"
      >
        <h2
          class="tw-text-[#1A2139] tw-text-left tw-font-CircularStd-medium tw-font-medium"
          :class="[info.description ? 'tw-mb-1' : null]"
        >
          {{ info.title }}
        </h2>
        <p
          class="tw-text-[#8791A7] tw-text-sm tw-text-left"
          v-if="info.description"
        >
          {{ info.description }}
        </p>
        <div
          class="tw-text-[15px] tw-text-left tw-font-normal tw-mt-4"
          v-if="status"
        >
          <p class="tw-text-[#27AE60]" v-if="status === 'Approved'">Approved</p>
          <p class="tw-text-[#FFA126]" v-if="status === 'Pending'">
            Pending Approval
          </p>
          <p class="tw-text-[#C63434]" v-if="status === 'Rejected'">Rejected</p>
        </div>
      </div>
      <button
        class="tw-bg-primary tw-rounded tw-border tw-border-[#DDE1E9] tw-h-[37px] tw-w-[111px] tw-text-sm tw-text-white tw-px-2 tw-w-block"
        v-if="showButton"
        @click="$emit('button-click')"
      >
        {{ verified ? "Change" : "Link" }}
      </button>
      <button
        class="tw-w-[30px] tw-h-[30px] hover:tw-bg-white"
        :class="[info.description ? null : 'tw-ml-auto']"
        v-else-if="!verified"
      >
        <transition name="slide-fade" mode="out-in">
          <img
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1653919595/getequityV2/Frame_qun1u1.svg"
            v-if="isOpen"
          />
          <img
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1653918409/getequityV2/Frame_jhtrqy.svg"
            v-else
          />
        </transition>
      </button>
    </button>
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-show="isOpen"
        class="md:tw-max-w-[85%] lg:tw-max-w-[730px] tw-mx-auto tw-mb-[50px]"
      >
        <slot></slot>
      </div>
    </transition>
  </section>
</template>

<script>
  export default {
    name: "Accordion",
    data() {
      return {
        isOpen: false,
      };
    },
    inject: ["activeTab"],
    emits: ["button-click", "is-active"],
    props: {
      info: {
        type: Object,
        required: true,
      },
      verified: {
        type: Boolean,
        required: true,
      },
      showButton: {
        type: Boolean,
        required: false,
      },
      icon: {
        type: String,
        required: false,
        default: () => {
          return "https://res.cloudinary.com/djalafcj9/image/upload/v1653916136/getequityV2/check-mark-svgrepo-com_1_iqypwa.svg";
        },
      },
      //determines the state of the checked image
      status: {
        type: String,
        required: false,
      },
    },
    watch: {
      isOpen(val) {
        if (val) {
          // emit event to close other accordions
          this.$emit("is-active", this.info.title);
        }
      },
      activeTab() {
        this.checkActiveTab();
      },
    },
    computed: {
      isEditable() {
        return !(this.status === "Approved" || this.status === "Pending");
      },
    },
    mounted() {
      this.checkActiveTab();
    },
    methods: {
      start(el) {
        el.style.height = el.scrollHeight + "px";
      },
      end(el) {
        el.style.height = "";
      },
      checkActiveTab() {
        // listen for a new open accordion and close if the current title doesn't match emitted title
        if (
          this.activeTab === this.info.title &&
          this.info.title !== "Bank Account (Optional)"
        ) {
          this.isOpen = true;
        } else {
          this.isOpen = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .slide-fade-enter-active {
    transition: all 0.2s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    opacity: 0;
  }

  .accordion-enter-active,
  .accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
  }
  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
    opacity: 0;
    padding: 0;
  }
</style>
