<template>
  <div>
    <InputFieldLabel
      :label="label"
      :inputActive="inputActive"
      :isDropdown="true"
      class=""
    >
      <div class="tw-relative">
        <select
          :ref="name"
          :name="name"
          :disabled="disabled"
          :id="name"
          :required="required"
          v-model="value"
          class="form__select tw-w-full tw-rounded tw-pl-5 tw-text-secondary tw-h-12"
          :class="[
            v$.value.$error ? 'form__select--error' : null,
            border ? border : 'form__select--default',
          ]"
          @blur="!value || value.length === 0 ? (inputActive = false) : null"
        >
          <template v-if="isObject">
            <option
              v-for="industry in options"
              :key="industry[name]"
              :value="industry[objectValue.value]"
            >
              {{ industry[objectValue.label] }}
            </option>
          </template>
          <option
            v-else
            v-for="industry in options"
            :key="industry"
            :value="industry"
          >
            {{ industry }}
          </option>
        </select>
        <img
          src="https://res.cloudinary.com/djalafcj9/image/upload/v1653918409/getequityV2/Frame_jhtrqy.svg"
          class="tw-absolute tw-right-2 sm:tw-right-4 tw-top-3"
          alt="chevron icon"
          v-if="!value"
        />
      </div>
    </InputFieldLabel>
    <p class="tw-text-red-error tw-font-normal" v-if="v$.value.$error">
      {{ v$.$errors[0].$message }}
    </p>
  </div>
</template>

<script>
  import InputFieldLabel from "./InputFieldLabel";
  import { required } from "@vuelidate/validators";
  import useValidate from "@vuelidate/core";
  export default {
    name: "SelectDropdown",
    components: {
      InputFieldLabel,
    },
    data() {
      return {
        inputActive: false,
        v$: useValidate(),
        value: "",
      };
    },
    validations() {
      return {
        value: {
          required,
        },
      };
    },
    props: {
      name: {
        type: String,
        required: true,
        default: () => "",
      },
      objectValue: {
        type: Object,
        required: false,
        default: () => {},
      },
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
      isObject: {
        type: Boolean,
        required: false,
        default: false,
      },
      label: {
        type: String,
      },
      disabled: {
        type: Boolean,
        required: false,
      },
      validate: {
        type: Boolean,
        required: true,
      },
      currentValue: {
        type: String,
        required: false,
        default: () => "",
      },
      border: {
        type: String,
        required: false,
      },
      //border color class for the input
      options: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    watch: {
      value(val) {
        if (val && val.length > 0) {
          this.inputActive = true;
          this.$emit("input");
          this.isValid = true;
        }
        if (!val) {
          this.isValid = false;
        }
        this.$emit("input-changed", { name: this.name, value: val });
      },
      currentValue(val) {
        if (val !== this.value) {
          this.value = val;
        }
      },
      validate(val) {
        if (val) {
          this.validateInput();
        }
      },
    },
    emits: ["input-changed", "input", "is-valid"],
    methods: {
      validateInput() {
        this.v$.value.$touch();
        this.isValid = !this.v$.value.$error ? true : false;
        this.$emit("is-valid", this.isValid);
      },
    },
    mounted() {
      this.isValid = this.value ? true : false;
      this.value = this.currentValue;
    },
  };
</script>

<style lang="scss" scoped>
  .form {
    &__select {
      z-index: 1;
      position: absolute;
      transition: all 500ms ease-in-out;
      & option:disabled {
        color: #a3a8b3;
      }
      &--default {
        border: 1px solid #e1e6f0;
      }
      &--error {
        border: 1px solid #db4343;
      }
      &:hover {
        border: 1px solid #4d84ff;
      }
    }
  }
</style>
