<template>
  <table id="deal-members-table" class="tw-w-full tw-mb-8 tw-relative">
    <Loader v-if="loading" />
    <thead class="header">
      <tr class="header-row">
        <th
          class="header-data tw-font-normal tw-py-6"
          ref="tableHead"
          :style="gridStyling"
        >
          <div
            class="tw-justify-self-start tw-pl-10"
            :class="[header === 'id' ? 'tw-hidden' : null]"
            v-for="(header, index) in tableHeaders"
            :key="'header' + index"
          >
            {{ header }}
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr class="body-row">
        <td class="body-data" ref="tableRow">
          <div
            class="data tw-py-6 tw-border-t tw-border-gray-light hover:tw-bg-gray-lightest"
            :ref="rowDetails"
            v-for="(details, index) in tableRows"
            :key="'row' + index"
            :style="gridStyling"
          >
            <div
              class="tw-self-center tw-pl-10"
              :class="[detail.includes('data-id') ? 'tw-hidden' : null]"
              v-for="(detail, index) in details"
              :key="'colunm' + index"
            >
              <p v-html="detail" class="tw-text-sm"></p>
            </div>
            <div class="tw-relative tw-flex tw-justify-center">
              <button
                @click.stop="setOptionsIndex(details)"
                class="tw-flex tw-self-center  hover:tw-bg-transparent tw-w-4 tw-h-4 tw-flex tw-items-center"
              >
                <img
                  src="https://res.cloudinary.com/djalafcj9/image/upload/v1635311248/getequityV2/options-horizontal_ps9ovq.webp"
                  class="tw-cursor-pointer"
                  alt="share"
                  loading="lazy"
                />
              </button>
              <div
                class="actions tw-p-2 tw-py-3"
                v-if="showOptions && selectedMember === details.id"
                @mouseleave="showOptions = false"
              >
                <template v-if="$route.name === 'DealInfo'">
                  <p
                    class="tw-text-gray tw-text-base tw-mb-2 tw-cursor-pointer tw-flex"
                    @click="setDealInfo(details)"
                  >
                    <img
                      class="tw-mr-2"
                      src="@/assets/img/member-profile.svg"
                      alt="profile"
                    />
                    Send deal
                  </p>
                  <p
                    class="tw-text-gray tw-text-base tw-cursor-pointer tw-flex"
                    @click="confirmAction('Remove', details.id, details.status)"
                  >
                    <img
                      class="tw-mr-2"
                      src="@/assets/img/close-circle.svg"
                      alt="close"
                    />
                    Remove member
                  </p>
                </template>
                <p
                  class="tw-text-gray tw-text-base tw-cursor-pointer tw-flex"
                  v-if="$route.name === 'Members'"
                  @click="
                    confirmAction(
                      details.status === 'Active' ? 'Deactivate' : 'Activate',
                      details.id,
                      details.status
                    )
                  "
                >
                  <img
                    class="tw-mr-2"
                    src="@/assets/img/close-circle.svg"
                    alt="profile"
                    v-if="details.status === 'Active'"
                  />
                  <img
                    v-else
                    class="tw-mr-2"
                    src="@/assets/img/success.svg"
                    alt="profile"
                  />
                  {{ details.status === "Active" ? "Deactivate" : "Activate" }}
                  member
                </p>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <Pagination
        :totalPage="totalPage"
        :currentPage="currentPage"
        :maxVisible="9"
        @pageChange="gotoPage"
      />
    </tfoot>
    <SendDeal
      v-if="sendDeal && member"
      :show="sendDeal"
      :member="member"
      :dealInfo="dealInfo"
      @close-send-deal="sendDeal = false"
    />
    <Modal
      :show="showConfirmModal && confirmationType === 'Remove'"
      @close-modal="showConfirmModal = false"
    >
      <div class="confirmation__modal">
        <h1
          class="tw-text-left tw-font-medium tw-text-xl tw-text-secondary tw-mb-6"
        >
          Remove member?
        </h1>

        <p class="tw-text-black-light tw-mb-8">
          This will remove this user from your deal room
        </p>
        <button
          @click="removeFromDeal"
          class="confirmation__button confirmation__button--red tw-font-medium tw-text-white tw-w-full tw-mb-8"
        >
          Yes Remove
        </button>
        <p
          class="tw-text-sm tw-text-black-light tw-text-center tw-cursor-pointer"
          @click="showConfirmModal = false"
        >
          No, nevermind
        </p>
      </div>
    </Modal>
    <Modal
      :show="showConfirmModal && confirmationType === 'Deactivate'"
      @close-modal="showConfirmModal = false"
    >
      <div class="confirmation__modal">
        <h1
          class="tw-text-left tw-font-medium tw-text-xl tw-text-secondary tw-mb-6"
        >
          Deactivate member?
        </h1>

        <p class="tw-text-black-light tw-mb-8">
          This will remove this user from this deal and deactivate their account
        </p>
        <button
          @click="changeUserStatus"
          class="confirmation__button confirmation__button--red tw-font-medium tw-text-white tw-w-full tw-mb-8"
        >
          Yes Deactivate
        </button>
        <p
          class="tw-text-sm tw-text-black-light tw-text-center tw-cursor-pointer"
          @click="showConfirmModal = false"
        >
          No, nevermind
        </p>
      </div>
    </Modal>
    <Modal
      :show="showConfirmModal && confirmationType === 'Activate'"
      @close-modal="showConfirmModal = false"
    >
      <div class="confirmation__modal">
        <h1
          class="tw-text-left tw-font-medium tw-text-xl tw-text-secondary tw-mb-6"
        >
          Activate member?
        </h1>

        <p class="tw-text-black-light tw-mb-8">
          This will give this user access to participate in this deal room
        </p>
        <button
          @click="changeUserStatus"
          class="confirmation__button confirmation__button--red tw-font-medium tw-text-white tw-w-full tw-mb-8"
        >
          Yes Activate
        </button>
        <p
          class="tw-text-sm tw-text-black-light tw-text-center tw-cursor-pointer"
          @click="showConfirmModal = false"
        >
          No, nevermind
        </p>
      </div>
    </Modal>
  </table>
</template>

<script>
  import Pagination from "@/components/navigation/Pagination.vue";
  import Modal from "../general/Modal.vue";
  import SendDeal from "@/components/members/SendDeal";
  import { mapGetters, mapActions } from "vuex";
  export default {
    name: "DealMembersTable",
    components: {
      Pagination,
      SendDeal,
      Modal,
    },
    props: {
      tableHeaders: { type: Array, default: () => [], required: true },
      tableRows: { type: Array, default: () => [], required: true },
      columnNumber: { type: Number, default: () => 0, required: true },
      columnWidth: { type: Number, default: () => 0, required: true },
      totalPage: { type: Number, default: () => 0, required: true },
      currentPage: { type: Number, default: () => 0, required: true },
      hasStatus: { type: Boolean, required: false, default: () => true },
    },
    emits: ["gotoPage", "update-members"],
    data() {
      return {
        rows: [],
        type: "",
        colunmSize: "",
        showOptions: false,
        selectedMember: "",
        sendDeal: false,
        loading: false,
        member: null,
        showConfirmModal: false,
        confirmationType: "",
        memberInfo: null,
      };
    },
    watch: {
      showOptions(val) {
        if (!val) {
          this.selectedMember = null;
        }
      },
    },
    computed: {
      ...mapGetters("Deals", ["dealInfo"]),
      gridStyling() {
        return {
          "grid-template-columns": `${200}px ${200}px ${200}px ${120}px repeat(${
            this.columnNumber
          }, ${0}px)`,
        };
      },
    },
    methods: {
      ...mapActions("Deals", [
        "deactivateMember",
        "activateMember",
        "removeMember",
      ]),
      ...mapActions(["showToast"]),
      rowDetails(el) {
        this.rows.push(el);
      },
      setOptionsIndex(details = null) {
        if (details.id === this.selectedMember) {
          this.showOptions = false;
          this.selectedMember = null;
          return;
        } else if (details.id) {
          this.selectedMember = details.id;
          this.showOptions = true;
          return;
        } else {
          this.selectedMember = null;
        }
      },
      setDealInfo(member) {
        this.member = member;
        this.sendDeal = true;
      },
      confirmAction(type, id, status) {
        this.memberInfo = { userId: id, status };
        this.confirmationType = type;
        this.showConfirmModal = true;
      },
      async changeUserStatus() {
        const { userId, status } = this.memberInfo;
        const userIdFilter = userId.split("/")[1];
        if (status === "Active") {
          this.disableUser(userIdFilter);
        } else {
          this.enableUser(userIdFilter);
        }
      },
      async disableUser(userId) {
        this.loading = true;
        try {
          const response = await this.deactivateMember(userId);
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.$emit("update-members");
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      },
      async enableUser(userId) {
        this.showConfirmModal = false;
        this.loading = true;
        try {
          const response = await this.activateMember(userId);
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.$emit("update-members");
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      },
      async removeFromDeal() {
        const { userId } = this.memberInfo;
        this.showConfirmModal = false;
        this.loading = true;
        const userIdFilter = userId.split("/")[1];
        try {
          const response = await this.removeMember(userIdFilter);
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.$emit("update-members");
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      },
      gotoPage(page) {
        this.$emit("gotoPage", page);
      },
    },
  };
</script>

<style lang="scss" scoped>
  #deal-members-table {
    @include fadeIn;
    .header {
      .header-row {
        .header-data {
          display: grid;
          column-gap: 2rem;
        }
      }
    }
    .table-body {
      .body-row {
        .body-data {
          .data {
            display: grid;
            column-gap: 2rem;
            font-size: 0.7rem;
          }
          // Added with javascript
          .active,
          .pending,
          .deactivated {
            text-align: center;
            padding: 0.5rem;
            margin-right: 1.4rem;
            border-radius: 5px;
          }
          .active {
            color: $success;
            background-color: $success-light;
          }
          .pending {
            color: $warning;
            background-color: $warning-light;
          }
          .deactivated {
            color: $error;
            background-color: $error-light;
          }
        }
      }
    }
    .actions {
      box-shadow: 0px 2px 4px rgba(44, 73, 137, 0.06);
      border-radius: 4px;
      max-height: 80px;
      position: absolute;
      right: 50px;
      background: #fff;
      width: 164px;
      top: 30px;
      z-index: 2;
    }
    .confirmation {
      &__text {
        color: orange;
      }
      &__button {
        height: 48px;
        border-radius: 4px;
        &--red {
          background: #eb5757;
        }
      }
      &__modal {
        width: 300px;
        @media (min-width: 768px) {
          width: 350px;
        }
      }
    }
  }
</style>
