<template>
  <Modal @close="$emit('close-modal')">
    <template v-slot:content>
      <div class="tw-w-full tw-mb-10">
        <button
          @click="$emit('show-spv-options')"
          class="tw-flex tw-gap-x-3 tw-items-center tw-text-base tw-text-secondary tw-font-CircularStd-medium tw-font-medium hover:tw-bg-white hover:tw-text-secondary"
        >
          <img src="@/assets/img/arrow-back.svg" alt="" /> Go Back
        </button>
      </div>
      <h1 class="tw-text-secondary tw-font-bold tw-text-[22px] tw-mb-6">
        Select an SPV to link to your deal
      </h1>
      <Search
        placeholder="Search"
        :show-search-field="true"
        :use-full-width="true"
        @search-input="findSpv"
        class="tw-mb-8"
      />
      <SmallLoader v-if="loading" />
      <template v-else>
        <template v-if="spvs.length > 0">
          <SpvListCard
            v-for="spv in spvs"
            :key="spv._id"
            :spv="spv"
            class="tw-mb-4"
            @show-info="$emit('show-info', spv)"
          />
        </template>
        <p
          class="tw-text-center"
          v-else-if="searchResult && searchResult.length === 0"
        >
          No SPV with that name exists
        </p>
        <p class="tw-text-center" v-else>No SPV found</p>
      </template>
    </template>
  </Modal>
</template>

<script setup>
  import Modal from "@/components/general/MediumModal";
  import SpvListCard from "./SpvListCard.vue";
  import Search from "../general/Search.vue";
  import SmallLoader from "../notification/SmallLoader.vue";
  import { computed, ref } from "vue";
  import { useStore } from "vuex";
  import { useSearch } from "@/composables/search";

  defineEmits(["close-modal", "show-spv-options", "show-info"]);

  const store = useStore();
  const { showSearchField } = useSearch();

  const searchResult = ref(null);
  const spvList = computed(() => store.getters["Spv/spvs"]);
  const spvCount = computed(() => store.getters["Spv/spvCount"]);
  const currentSpvPage = computed(() => store.getters["Spv/currentSpvPage"]);
  const totalSpvPages = computed(() => store.getters["Spv/totalSpvPages"]);
  const loading = computed(() => store.getters["loading"]);

  const spvs = computed(() => {
    if (searchResult.value) {
      return searchResult.value;
    }
    return spvList.value;
  });

  const getSpvList = async () => {
    await store.dispatch("Spv/fetchSpvs");
  };
  const findSpv = async (object) => {
    const name = object.value;
    if (name) {
      const response = await store.dispatch("Spv/searchSpvRequest", name);
      const { data: spv } = response.data;
      searchResult.value = spv;
    } else {
      searchResult.value = null;
      getSpvList();
    }
  };

  getSpvList();
</script>

<style lang="scss" scoped></style>
