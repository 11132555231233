<template>
  <div class="tw-flex">
    <slot v-if="position === 'second'"></slot>
    <div
      class="tw-grow dropdown tw-text-center tw-text-sm tw-flex tw-items-center tw-justify-center"
      :class="[
        position === 'first'
          ? 'dropdown--full-border'
          : 'dropdown--half-border',
      ]"
    >
      <p class="tw-text-sm tw-text-[#1a2139] tw-truncate">{{ currentValue }}</p>
    </div>
    <slot v-if="position === 'first'"></slot>
  </div>
</template>

<script>
export default {
  name: "comboField",
  props: {
    name: {
      type: String,
      required: false,
    },
    position: {
      type: String,
      required: false,
      default: () => {
        return "second";
      },
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    currentValue: {
      type: String,
      required: false,
      default: () => "",
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  box-sizing: border-box;
  &--half-border {
    border: 1px solid #dde1e9;
    background: #f0f4fd;
    text-transform: capitalize;
    border-radius: 0px 4px 4px 0px;
    @apply tw-h-12 tw-w-4/12;
  }
  &--full-border {
    border-radius: 4px;
    width: 80px;
    @media (min-width: 1024px) {
      width: min-content;
    }

    padding: 0 10px;
    flex-grow: 1;
    @apply tw-h-10;
    background-color: #e6edff;
  }
}
</style>
