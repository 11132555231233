<template>
	<section
		class="tw-py-5 tw-mb-6 tw-mx-auto tw-max-w-[818px] tw-font-bold tw-text-lg"
	>
		<h1 class="tw-text-secondary">Deal Information</h1>
		<p class="tw-text-sm tw-font-light tw-text-black-light tw-mb-10">
			Fill in the following deal details for the company you want to raise for
		</p>
		<div class="create-deal tw-py-5 tw-bg-white tw-px-6">
			<div
				class="create-deal-heading__div tw-flex tw-justify-between tw-items-center"
			>
				<h2
					class="create-deal__heading tw-text-secondary tw-flex tw-items-center tw-font-CircularStd-medium tw-font-medium"
				>
					<img src="@/assets/img/asset.svg" class="tw-mr-2" alt="asset icon" />
					Deal Information
				</h2>
			</div>
			<form
				@submit.prevent="checkFormValidation"
				class="create-deal__form tw-flex tw-justify-between tw-mt-12 md:tw-px-8 tw-flex-wrap tw-relative"
			>
				<Loader v-if="loading" />
				<div class="tw-w-full md:tw-w-48 tw-mb-6 md:tw-mb-0">
					<DragNDrop
						accept="image/*"
						:currentValue="deal.image"
						@file-added="setImage"
						:startUpload="uploadFile"
						@reupload="uploadFile = false"
						@upload-complete="setImageLink"
						@is-valid="handleValidation"
						:validate="validateForm"
					>
					</DragNDrop>
				</div>
				<div
					class="tw-w-full md:tw-w-8/12 md:tw-p-4 lg:tw-pb-8 lg:tw-px-0 lg:tw-w-[439px] tw-pt-0 lg:tw-pt-0"
				>
					<InputField
						label="Deal Name"
						:currentValue="deal.name"
						class="tw-mb-6"
						name="name"
						@input-changed="storeValue"
						:validate="validateForm"
						@is-valid="handleValidation"
					/>
					<InputField
						label="Token Symbol"
						class="tw-mb-6"
						:maxLength="5"
						:currentValue="deal.symbol"
						name="symbol"
						@input-changed="storeValue"
						:validate="validateForm"
						@is-valid="handleValidation"
					/>
					<Dropdown
						label="Investment Type"
						class="tw-mb-6"
						:currentValue="deal.investment_type"
						name="investment_type"
						:options="investmentTypes"
						@input-changed="storeValue"
						:validate="validateForm"
						@is-valid="handleValidation"
					/>
					<Dropdown
						label="Currency"
						class="tw-mb-6"
						:currentValue="deal.currency"
						name="currency"
						:options="currencies"
						v-bind="{
							isObject: true,
							objectValue: { label: 'name', value: 'value' },
						}"
						@input-changed="storeValue"
						:validate="validateForm"
						@is-valid="handleValidation"
					/>
					<Dropdown
						label="Country"
						class="tw-mb-6"
						:currentValue="deal.country"
						name="country"
						:options="countryRegionState"
						v-bind="{
							isObject: true,
							objectValue: { label: 'countryName', value: 'countryName' },
						}"
						@input-changed="storeValue"
						:validate="validateForm"
						@is-valid="handleValidation"
					/>
					<InputField
						v-if="
							deal.investment_type !== 'Debt' &&
							deal.investment_type !== 'Fixed Interest'
						"
						:label="`Valuation Cap (${deal.currency})`"
						type="text"
						:currentValue="valuation"
						:minValue="minValuation"
						name="valuation"
						:validateAsNumber="true"
						class="tw-mb-6"
						@input-changed="storeValue"
						:validate="validateForm"
						@is-valid="handleValidation"
					/>
					<template
						v-if="
							deal.investment_type === 'Debt' ||
							deal.investment_type === 'Fixed Interest'
						"
					>
						<InputField
							label="Interest rate (% p.a)"
							:minValue="0"
							:maxValue="100"
							type="text"
							valueType="percentage"
							:maxValueErrorMessage="`This field cannot be greater than`"
							:currentValue="deal.interest"
							name="interest"
							:validateAsNumber="true"
							class="tw-mb-6"
							@input-changed="storeValue"
							:validate="validateForm"
							@is-valid="handleValidation"
						/>
						<InputField
							label="Investment Period (in Days)"
							:minValue="1"
							type="text"
							valueType="months"
							:currentValue="deal.tenor"
							name="tenor"
							:validateAsNumber="true"
							class="tw-mb-6"
							@input-changed="storeValue"
							:validate="validateForm"
							@is-valid="handleValidation"
						/>
						<Dropdown
							label="Payout Frequency"
							class="tw-mb-6"
							:currentValue="deal.payout_frequency"
							name="payout_frequency"
							:options="payoutFrequencies"
							v-bind="{
								isObject: true,
								objectValue: { label: 'name', value: 'value' },
							}"
							@input-changed="storeValue"
							:validate="validateForm"
							@is-valid="handleValidation"
						/>
					</template>

					<Dropdown
						label="Risk Level"
						class="tw-mb-6"
						:currentValue="deal.risk"
						name="risk"
						:options="riskTypes"
						@input-changed="storeValue"
						:validate="validateForm"
						@is-valid="handleValidation"
					/>
					<InputField
						v-if="deal.investment_type === 'SAFE'"
						label="Discount (%)"
						type="text"
						valueType="percentage"
						:currentValue="deal.discount"
						name="discount"
						:validateAsNumber="true"
						class="tw-mb-6"
						@input-changed="storeValue"
						:validate="validateForm"
						@is-valid="handleValidation"
					/>

					<InputField
						type="text"
						:label="`Amount To Be Raised (${deal.currency})`"
						:currentValue="raise_amount"
						name="raise_amount"
						:maxValueErrorMessage="`This field cannot be greater than valuation of`"
						:minValue="0"
						:maxValue="deal.valuation"
						@input-changed="storeValue"
						:validateAsNumber="true"
						:validate="validateForm"
						@is-valid="handleValidation"
					/>
					<!-- <p
						v-if="valuation"
						class="tw-text-xs tw-text-black-light tw-font-normal"
					>
						Minimum {{ formatAmountToDollar(minRaise, 2, deal.currency) }}
					</p> -->
					<div class="tw-flex tw-mt-6 tw-p-4 info">
						<img
							src="@/assets/img/alert-orange.svg"
							alt="alert icon"
							class="tw-w-5 tw-h-5 tw-mr-2"
						/>
						<p class="tw-text-sm tw-font-normal">
							The amount you are raising should not be greater than your
							valuation. Each token will be priced at
							{{ formatAmountToDollar(getTokenPrice, 2, deal.currency) }}.
						</p>
					</div>
					<div class="">
						<div
							class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between"
						>
							<div class="tw-w-full price__div">
								<h2
									class="tw-text-sm tw-font-medium tw-text-black tw-mb-4 tw-mt-6"
								>
									Minimum Investment Amount
								</h2>
								<InputField
									label="Min Amount"
									:currentValue="min_buy"
									class="tw-mb-6"
									name="min_buy"
									@input-changed="storeValue"
									:validateAsNumber="true"
									:validate="validateForm"
									@is-valid="handleValidation"
								/>
							</div>
							<div class="tw-w-full price__div">
								<h2
									class="tw-text-sm tw-font-medium tw-text-black tw-mb-4 tw-mt-6"
								>
									Maximum Investment Amount
								</h2>
								<InputField
									label="Max Amount"
									:currentValue="max_buy"
									class="tw-mb-6"
									name="max_buy"
									:required="true"
									:minValue="deal.min_buy"
									@input-changed="storeValue"
									:validateAsNumber="true"
									:validate="validateForm"
									@is-valid="handleValidation"
								/>
							</div>
						</div>
					</div>
					<!-- <div class="tw-mb-3">
          <h2 class="tw-text-sm tw-font-medium tw-mt-8 tw-text-black tw-mb-4">
            Sell Price
          </h2>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between">
            <div class="tw-w-full price__div">
              <InputField
                label="Minimum price"
                :currentValue="deal.min_sell"
                name="min_sell"
                :class="[v$.deal.min_sell.$error ? null : 'tw-mb-5']"
                @input-changed="storeValue"
                @input="v$.deal.min_sell.$touch()"
              />
              <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  v$.deal.min_sell.$dirty && v$.deal.min_sell.required.$invalid
                "
              >
                This field is required
              </p>
              <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  v$.deal.min_sell.$dirty && v$.deal.min_sell.numeric.$invalid
                "
              >
                This field only accepts numbers
              </p>
              <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  (v$.deal.min_sell.$dirty &&
                    v$.deal.min_sell.maxValue.$invalid &&
                    deal.max_sell &&
                    deal.max_sell.length > 0) ||
                    (v$.deal.min_sell.$dirty &&
                      v$.deal.min_sell.maxValue.$invalid &&
                      deal.max_sell > 0)
                "
              >
                This field cannot be greater than Maximum sell price (${{
                  deal.max_sell
                }})
              </p>
            </div>
            <div class="tw-w-full price__div">
              <InputField
                label="Maximum price"
                :currentValue="deal.max_sell"
                name="max_sell"
                :class="[v$.deal.max_sell.$error ? null : 'tw-mb-5']"
                @input-changed="storeValue"
                @input="v$.deal.max_sell.$touch()"
              />
              <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  v$.deal.max_sell.dirty && v$.deal.max_sell.required.$invalid
                "
              >
                This field is required
              </p>
              <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  v$.deal.max_sell.$dirty && v$.deal.max_sell.numeric.$invalid
                "
              >
                This field only accepts numbers
              </p>
              <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  (v$.deal.max_sell.$dirty &&
                    v$.deal.max_sell.minValue.$invalid &&
                    deal.min_buy &&
                    deal.min_buy.length > 0) ||
                    (v$.deal.max_sell.$dirty &&
                      v$.deal.max_sell.minValue.$invalid &&
                      deal.min_buy > 0)
                "
              >
                This field cannot be less than Minimum sell pric
                  deal.min_buy
                }})
              </p>
            </div>
          </div>
        </div> -->
					<p
						class="tw-text-black tw-flex tw-items-center tw-mb-3 tw-font-medium tw-cursor-pointer"
						@click="deal.secondaries = !deal.secondaries"
					>
						<input
							class="tw-mr-3 create-deal__checkbox"
							type="checkbox"
							name="secondaries"
							id="secondaries"
							v-model="deal.secondaries"
						/>
						Allow Secondaries
						<Tooltip
							@mouseover="currentVisible = secondariesTooltip"
							@mouseleave="currentVisible = ''"
							:currentVisible="currentVisible"
							:text="secondariesTooltip"
						/>
					</p>
					<p
						class="tw-text-black tw-flex tw-items-center tw-mb-3 tw-font-medium tw-cursor-pointer"
						@click="deal_access = !deal_access"
					>
						<input
							class="tw-mr-3 create-deal__checkbox tw-flex"
							type="checkbox"
							name="deal_access"
							id="deal_access"
							v-model="deal_access"
						/>
						Make this deal private (to selected users)
						<Tooltip
							@mouseover="currentVisible = dealAccessText"
							@mouseleave="currentVisible = ''"
							:currentVisible="currentVisible"
							:text="dealAccessText"
						/>
					</p>
					<p
						class="tw-text-black tw-flex tw-items-center tw-mb-3 tw-font-medium tw-cursor-pointer"
						@click="
							dealInfo?.status === 'Approved'
								? null
								: (deal.closed = !deal.closed)
						"
					>
						<input
							class="tw-mr-3 create-deal__checkbox"
							type="checkbox"
							:disabled="dealInfo?.status === 'Approved'"
							name="closed"
							id="closed"
							v-model="deal.closed"
						/>
						Is this an already closed deal?
						<Tooltip
							@mouseover="currentVisible = closedTooltip"
							@mouseleave="currentVisible = ''"
							:currentVisible="currentVisible"
							:text="closedTooltip"
						/>
					</p>
					<template v-if="plan && plan.type.toLowerCase() === 'fixed'">
						<div class="tw-flex tw-my-3 tw-p-4 info">
							<img
								src="@/assets/img/alert-orange.svg"
								alt="alert icon"
								class="tw-w-5 tw-h-5 tw-mr-2"
							/>
							<p class="tw-text-sm tw-font-normal">
								Note that GetEquity charges a {{ plan.percentage }}% transaction
								fee based on your subscription plan.
							</p>
						</div>
						<div
							class="tw-flex tw-flex-wrap tw-gap-y-3 md:tw-gap-y-0 md:tw-gap-x-5 tw-w-full md:tw-items-start"
						>
							<p
								class="tw-text-black tw-flex tw-items-center tw-mb-3 tw-font-medium tw-cursor-pointer"
								@click="buy_fee = !buy_fee"
							>
								<input
									class="tw-mr-3 create-deal__checkbox tw-flex"
									:checked="dealInfo?.buy_fee !== 1"
									type="checkbox"
									name="buy_fee"
									id="buy_fee"
									v-model="buy_fee"
								/>
								Defer fees to investors
								<Tooltip :text="buyFeesText" />
							</p>
							<div
								class="tw-flex-grow md:tw-flex-grow-0 md:tw-basis-1/2 md:tw-w-1/2"
								v-show="buy_fee"
							>
								<RangeField
									label="Percentage to defer"
									:validate="validateForm"
									class="tw-mb-1 tw-w-full"
									type="number"
									name="buy_fee"
									:currentValue="deal.buy_fee"
									:minValue="1"
									:maxValue="percentageMax"
									:validateAsNumber="true"
									maxValueErrorMessage="Percentage cannot be greater than"
									@is-valid="handleValidation"
									@input-changed="storeValue"
								/>
								<p
									class="tw-mt-2 tw-text-[#323E67] tw-text-[13px] tw-leading-[150%] tw-font-[450]"
								>
									Range: 1% - {{ percentageMax }}%
								</p>
							</div>
						</div>
					</template>
					<div
						class="tw-flex tw-flex-wrap tw-gap-y-3 md:tw-gap-y-0 md:tw-gap-x-5 tw-w-full md:tw-items-start"
					>
						<p
							class="tw-text-black tw-flex tw-items-center tw-mb-3 tw-font-medium tw-cursor-pointer"
							@click="type = !type"
						>
							<input
								class="tw-mr-3 create-deal__checkbox tw-flex"
								:checked="dealInfo?.type === 'Public'"
								type="checkbox"
								name="type"
								id="type"
								v-model="type"
							/>
							Show this deal on the general GetEquity market
							<Tooltip :text="typeText" />
						</p>
					</div>
					<div class="tw-w-full tw-mt-8">
						<div class="tw-w-32">
							<DealButton
								:text="dealInfo?.status === 'Approved' ? 'Update' : 'Continue'"
								class="tw-text-sm"
							/>
						</div>
					</div>
				</div>
			</form>
			<DealSpvOptions
				:show="showSpvOptions"
				v-if="showSpvOptions"
				:dealId="dealId"
				@close-spv-options="$router.push({ name: 'Deals' })"
				@show-spv-list="switchModal('spvList')"
				@upload-spv-doc="switchModal('uploadSpv')"
			/>
			<SpvListModal
				v-if="showSpvList"
				@close-modal="showSpvList = false"
				@show-spv-options="switchModal('spvOptions')"
				@show-info="showInfoModal"
			/>
			<SpvInfo
				v-if="showInfo"
				:spv="spv"
				:showBack="true"
				@close-spv-info="switchModal('spvList')"
				@use-spv="linkSpv"
			/>
			<SpvLinkSuccess v-if="showSuccessModal" @close-modal="openDeal" />
			<SpvUploadModal
				@close-modal="switchModal('spvOptions')"
				@show-success-modal="linkDeal"
				v-if="showUploadSpv"
			/>
			<Modal
				:show="showDealPreview"
				v-if="showDealPreview"
				@close-modal="showDealPreview = false"
			>
				<div class="create-deal-preview__modal">
					<h1 class="tw-text-xl tw-text-secondary tw-leading-8">
						{{ dealInfo?.status === "Approved" ? "Update" : "Request" }} Summary
					</h1>
					<div class="tw-flex tw-justify-between tw-mt-4 border-bottom">
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light">Token Name</h2>
							<p class="tw-text-base">{{ deal.name }}</p>
						</div>
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light tw-text-right">
								Token Symbol
							</h2>
							<p class="tw-text-base tw-text-right">{{ deal.symbol }}</p>
						</div>
					</div>
					<div class="tw-flex tw-justify-between tw-mt-4 border-bottom">
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light">Risk Level</h2>
							<p class="tw-text-base">{{ deal.risk }}</p>
						</div>
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light tw-text-right">
								Deal Currency
							</h2>
							<p class="tw-text-base tw-text-right">
								{{ getCurrency(deal.currency) }}
							</p>
						</div>
					</div>
					<div class="tw-flex tw-justify-between tw-mt-4 border-bottom">
						<div
							class=""
							v-if="
								deal.investment_type === 'Debt' ||
								deal.investment_type === 'Fixed Interest'
							"
						>
							<h2 class="tw-text-xs tw-text-black-light">Investment Type</h2>
							<p class="tw-text-base">{{ deal.investment_type }}</p>
						</div>
						<div class="" v-else>
							<h2 class="tw-text-xs tw-text-black-light">Valuation Cap</h2>
							<p class="tw-text-base">
								{{ deal.currency }}
								{{ new Intl.NumberFormat().format(deal.valuation) }}
							</p>
						</div>
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light tw-text-right">
								Amount of Shares To Sell at
								{{
									formatAmountToDollar(getTokenPrice, 2, deal.currency)
								}}/share
							</h2>
							<p class="tw-text-base tw-text-right">
								{{
									new Intl.NumberFormat().format(
										deal.raise_amount / getTokenPrice
									)
								}}
							</p>
						</div>
					</div>
					<div class="tw-flex tw-justify-between tw-mt-4 border-bottom">
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light">
								Minimum Investment Amount
							</h2>
							<p class="tw-text-base">
								{{ formatAmountToDollar(deal.min_buy, 2, deal.currency) }}
							</p>
						</div>
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light">
								Maximum Investment Amount
							</h2>
							<p class="tw-text-base tw-text-right">
								{{ formatAmountToDollar(deal.max_buy, 2, deal.currency) }}
							</p>
						</div>
					</div>
					<div class="tw-flex tw-justify-between tw-mt-4 border-bottom">
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light tw-text-right">
								Deal type
							</h2>
							<p class="tw-text-base tw-text-left">
								{{ deal.deal_access }}
							</p>
						</div>
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light tw-text-right">
								Is this a Closed deal?
							</h2>
							<p class="tw-text-base tw-text-right">
								{{ deal.closed ? "YES" : "NO" }}
							</p>
						</div>
					</div>
					<div
						class="tw-flex tw-justify-between tw-mt-4 border-bottom"
						v-if="
							deal.investment_type === 'Debt' ||
							deal.investment_type === 'Fixed Interest'
						"
					>
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light tw-text-right">
								Interest Rate
							</h2>
							<p class="tw-text-base tw-text-left">{{ deal.interest }}%</p>
						</div>
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light tw-text-right">
								Investment Period
							</h2>
							<p class="tw-text-base tw-text-right">{{ deal.tenor }} days</p>
						</div>
					</div>
					<div class="tw-flex tw-justify-between tw-mt-4 border-bottom" v-else>
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light tw-text-left">
								Investment type
							</h2>
							<p class="tw-text-base tw-text-left">
								{{ deal.investment_type }}
							</p>
						</div>
						<div class="" v-if="deal.investment_type === 'SAFE'">
							<h2 class="tw-text-xs tw-text-black-light tw-text-right">
								Discount Rate
							</h2>
							<p class="tw-text-base tw-text-right">{{ deal.discount }} %</p>
						</div>
						<div class="" v-else>
							<h2 class="tw-text-xs tw-text-black-light tw-text-right">
								Country
							</h2>
							<p class="tw-text-base tw-text-right">
								{{ deal.country }}
							</p>
						</div>
					</div>
					<div
						v-if="deal.investment_type === 'SAFE'"
						class="tw-flex tw-justify-between tw-mt-4 border-bottom"
					>
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light tw-text-left">
								Country
							</h2>
							<p class="tw-text-base tw-text-left">
								{{ deal.country }}
							</p>
						</div>
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light tw-text-right">
								Payout Frequency
							</h2>
							<p class="tw-text-base tw-text-right">
								{{ deal.payout_frequency }}
							</p>
						</div>
					</div>
					<div v-else class="tw-flex tw-justify-between tw-mt-4">
						<div class="">
							<h2 class="tw-text-xs tw-text-black-light tw-text-left">
								Payout Frequency
							</h2>
							<p class="tw-text-base tw-text-left">
								{{ deal.payout_frequency }}
							</p>
						</div>
					</div>
					<div
						class="tw-flex tw-flex-wrap tw-mt-8 md:tw-justify-between md:tw-w-96 tw-mx-auto"
					>
						<button
							class="tw-w-full tw-mb-4 md:tw-mb-0 md:tw-w-44 tw-font-medium tw-text-sm preview-button"
							@click="showDealPreview = false"
						>
							Go Back
						</button>
						<div class="tw-w-full md:tw-w-44">
							<DealButton
								:text="
									dealInfo?.status === 'Approved'
										? 'Confirm Update'
										: 'Confirm Request'
								"
								class="tw-text-sm"
								:loading="loading"
								@click="processUpload"
							/>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	</section>
</template>
<script setup>
import DealButton from "@/components/deals/DealsButton";
import DragNDrop from "@/components/general/DragNDrop";
import Dropdown from "@/components/general/Dropdown";
import InputField from "@/components/general/InputField";
import Modal from "@/components/general/Modal";
import RangeField from "@/components/general/RangeField";
import DealSpvOptions from "@/components/spv/DealSpvOptions.vue";
import SpvInfo from "@/components/spv/SpvInfo.vue";
import SpvLinkSuccess from "@/components/spv/SpvLinkSuccess.vue";
import SpvListModal from "@/components/spv/SpvListModal.vue";
import SpvUploadModal from "@/components/spv/SpvUploadModal.vue";
import countryRegionState from "@/utils/countryRegionData";
import { convertToLocale, formatAmountToDollar } from "@/utils/helpers.js";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
const props = defineProps({
	tokenId: {
		type: String,
		required: false,
		default: () => "",
	},
});

const tokenId = computed(() => props.tokenId);

const showSpvList = ref(false);
const showSpvOptions = ref(false);
const showInfo = ref(false);
const showSuccessModal = ref(false);
const spv = ref(null);
const showUploadSpv = ref(false);
const uploadFile = ref(false);
const loading = ref(false);
const valuation = ref(null);
const min_buy = ref("");
const max_buy = ref("");
const raise_amount = ref(null);
const total_raised = ref(null);
const currentVisible = ref("");
const secondariesTooltip = ref(
	"Secondaries (Secondary market) allow investors to sell some of their initially bought shares to other investors at a price agreed by the buyer and seller.  Limited partners do not have a way to sell their own share of the deal anytime they like for cash or any other asset."
);
const dealAccessText = ref(
	"This deal would be available to only selected members of your dealroom"
);
const buyFeesText = ref(
	"When selected, buy fee for investors is 3%, else it remains 1%."
);

const closedTooltip = ref(
	"Check this box if the funds have already been raised and you just want to assign to investors."
);
const typeText = ref(
	"Allow for anyone on the GetEquity platform to invest in this deal"
);
const dealId = ref("");
const deal_access = ref(false);
const suggestedSymbolName = ref("");
const tokenNameItems = ref(null);
const assetName = ref("");
const showDealPreview = ref(false);
const validateForm = ref(false);
const isValidated = ref(false);
const validationResult = ref([]);
// const minRaise = ref(10000);
const minValuation = ref(25000);
const deal = reactive({
	name: null,
	symbol: null,
	image: null,
	raise_amount: null,
	valuation: null,
	min_buy: null,
	max_buy: null,
	buy_fee: 1,
	platform_fee: 0,
	payout_frequency: "",
	// min_sell: null,
	// max_sell: null,
	type: "Private",
	closed: false,
	asset_id: null,
	secondaries: false,
	deal_access: false,
	investment_type: "",
	interest: null,
	tenor: null,
	discount: null,
	risk: null,
	currency: "USD",
	country: null,
});
const type = ref(false);
const buy_fee = ref(null);
const interest = ref(0);
const percentageMax = ref(3);
const investmentTypes = ref(["Equity", "Debt", "SAFE", "Fixed Interest"]);
const riskTypes = ref(["Low", "Medium", "High"]);
const currencies = ref([
	{ name: "United States Dollars", value: "USD" },
	{
		name: "Nigerian Naira",
		value: "NGN",
	},
	{
		name: "Kenyan Shillings",
		value: "KES",
	},
]);
const payoutFrequencies = ref([
	{ name: "Daily", value: "Daily" },
	{
		name: "Weekly",
		value: "Weekly",
	},
	{
		name: "Monthly",
		value: "Monthly",
	},
	{
		name: "Quarterly",
		value: "Quarterly",
	},
	{
		name: "Annually",
		value: "Annually",
	},
]);

const store = useStore();

const route = useRoute();
const router = useRouter();

const asset = computed(() => store.getters["Asset/asset"]);
const dealInfo = computed(() => store.getters["Deals/dealInfo"]);
const organisationDetails = computed(
	() => store.getters["organisationModule/organisationDetails"]
);
const transactionFees = computed(
	() => store.getters["organisationModule/transactionFees"]
);

const valuationFormatted = computed(() => {
	let valuationFormatted = null;
	if (valuation.value) {
		const valuationInteger = Number(
			String(valuation.value).split(",").join("")
		);
		deal.valuation = valuationInteger;
		valuationFormatted = convertToLocale(valuationInteger);
	}
	return valuationFormatted;
});

const minBuyFormatted = computed(() => {
	let minBuyFormatted = null;
	if (min_buy.value) {
		const min_buy_integer = Number(String(min_buy.value).split(",").join(""));
		deal.min_buy = min_buy_integer;
		minBuyFormatted = convertToLocale(min_buy_integer);
	}
	return minBuyFormatted;
});
const maxBuyFormatted = computed(() => {
	let maxBuyFormatted = null;
	if (max_buy.value) {
		const max_buy_integer = Number(String(max_buy.value).split(",").join(""));
		deal.max_buy = max_buy_integer;
		maxBuyFormatted = convertToLocale(max_buy_integer);
	}
	return maxBuyFormatted;
});

const raiseAmountFormatted = computed(() => {
	let raiseAmountFormatted = null;
	if (raise_amount.value) {
		const raise_amount_integer = Number(
			String(raise_amount.value).split(",").join("")
		);
		deal.raise_amount = raise_amount_integer;
		if (
			deal.investment_type === "Debt" ||
			deal.investment_type === "Fixed Interest"
		) {
			deal.valuation = raise_amount_integer + 1;
		}
		raiseAmountFormatted = convertToLocale(raise_amount_integer);
	}
	return raiseAmountFormatted;
});

const totalRaisedAmountFormatted = computed(() => {
	let totalRaisedAmountFormatted = null;
	if (total_raised.value) {
		const total_raised_integer = Number(
			String(total_raised.value).split(",").join("")
		);
		deal.total_raised = total_raised_integer;
		totalRaisedAmountFormatted = convertToLocale(total_raised_integer);
	}
	return totalRaisedAmountFormatted;
});

const plan = computed(() => store.getters["organisationModule/plan"]);

const getTokenPrice = computed(() => {
	const currency = deal.currency || 'USD';
	let value = null;
	switch (currency) {
		case "USD":
			value = 10;
			break;
		case "NGN":
			value = 10000;
			break;
		case "KES":
			value = 100;
			break;
	}
	return value;
});

const setImage = (value) => {
	deal.image = value;
};

const openDeal = () => {
	showSuccessModal.value = false;
	router.push({
		name: "DealInfo",
		params: { dealId: dealId.value },
	});
};

const checkFormValidation = () => {
	validationResult.value = [];
	validateForm.value = true;
	isValidated.value = true;
	if (deal.asset_id) {
		handleValidation(true);
	} else {
		handleValidation(false);
	}

	if (deal.deal_access) {
		handleValidation(true);
	} else {
		handleValidation(false);
	}
	if (deal.closed) {
		//optional so it will always be true
		handleValidation(true);
	} else {
		handleValidation(true);
	}
	if (
		deal.investment_type === "Debt" ||
		deal.investment_type === "Fixed Interest"
	) {
		handleValidation(true); //tenor
		handleValidation(true); // interest
	} else {
		delete deal.interest;
		delete deal.tenor;
		delete deal.payout_frequency;
	}
	if (deal.investment_type !== "SAFE") {
		delete deal.discount;
	}
	//deal type
	handleValidation(true);
	//secondaries is optional so it would always have a value of either true or false
	handleValidation(true);
	if (plan.value.type.toLowerCase() === "fixed") {
		//platform fees would have a default value of `${percentageMax.value}` so it would always be true
		handleValidation(true);
	} else {
		handleValidation(true);
		handleValidation(true);
	}
	setTimeout(() => {
		validateForm.value = false;
	}, 1000);
};

const handleValidation = (value) => {
	validationResult.value.push(value);
};

const getDealInfo = async (id) => {
	loading.value = true;
	try {
		await Promise.all([
			store.dispatch("Deals/fetchDealInfo", id),
			store.dispatch("organisationModule/getTransactionFees"),
			store.dispatch("organisationModule/getOrganisationPlan"),
		]);
		prefillForm();
		loading.value = false;
	} catch (error) {
		loading.value = false;
		return error;
	}
};

const prefillForm = () => {
	deal.name = dealInfo.value.name || "";
	deal.symbol = dealInfo.value.symbol;
	deal.image = dealInfo.value.image;
	deal.raise_amount = dealInfo.value.raise_amount;
	raise_amount.value = convertToLocale(deal.raise_amount);
	deal.valuation = dealInfo.value.valuation;
	valuation.value = convertToLocale(deal.valuation);
	deal.asset_id = dealInfo.value.asset._id;
	deal.secondaries = dealInfo.value.secondaries;
	deal.closed = dealInfo.value.closed;
	deal.deal_access = dealInfo.value.deal_access;
	deal.type = dealInfo.value.type;
	deal.risk = dealInfo.value.risk;
	deal.country = dealInfo.value.country;
	deal.currency = dealInfo.value.currency;
	deal.investment_type =
		dealInfo.value.investment_type === "Safe"
			? dealInfo.value.investment_type.toUpperCase()
			: dealInfo.value.investment_type;
	if (
		deal.investment_type === "Debt" ||
		deal.investment_type === "Fixed Interest"
	) {
		deal.interest = dealInfo.value.interest;
		deal.tenor = dealInfo.value.tenor;
	}
	if (deal.investment_type === "SAFE") {
		deal.discount = dealInfo.value.discount;
	}
	if (dealInfo.value.buy_fee) {
		deal.buy_fee = dealInfo.value.buy_fee;
		buy_fee.value = dealInfo.value.buy_fee > 1;
	}
	interest.value = dealInfo.value.interest;
	if (dealInfo.value.deal_access === "Private") {
		deal_access.value = true;
	} else {
		deal_access.value = false;
	}
	if (dealInfo.value.min_trade) {
		min_buy.value = convertToLocale(dealInfo.value.min_trade.buy);
		// deal.min_sell = dealInfo.value.min_trade.sell;
	}
	if (dealInfo.value.max_trade) {
		max_buy.value = convertToLocale(dealInfo.value.max_trade.buy);
		// deal.max_sell = dealInfo.value.max_trade.sell;
	}
	if (dealInfo.value.payout_frequency) {
		deal.payout_frequency = dealInfo.value.payout_frequency;
	}
	dealId.value = dealInfo.value._id;
};

const setValues = () => {
	deal.name = asset.value.name;
	deal.asset_id = asset.value._id;
	deal.image = asset.value.image;
};

const storeValue = ({ name, value }) => {
	if (name === "valuation") {
		valuation.value = value;
	} else if (name === "interest") {
		interest.value = value;
	} else if (name === "raise_amount") {
		raise_amount.value = value;
	} else if (name === "min_buy") {
		min_buy.value = value;
	} else if (name === "max_buy") {
		max_buy.value = value;
	} else {
		deal[name] = value;
	}
};

const createTokenSymbol = () => {
	suggestedSymbolName.value = "";
	assetName.value = asset.value.name;
	const tokenName = assetName.value;
	let tempSymbol = suggestedSymbolName.value;
	const tokenNameSplit = tokenName?.split(" ");
	if (tokenNameSplit) {
		tokenNameSplit.map((item) => {
			if (tempSymbol.length < 6) {
				tempSymbol += item.charAt(0).toUpperCase();
			}
		});
	}
	suggestedSymbolName.value = tempSymbol;
	tokenNameItems.value = tokenNameSplit;
	deal.symbol = tempSymbol;
};

const checkSuggestedSymbolLength = () => {
	let tempSymbol = suggestedSymbolName.value;
	const tokenNameSplit = tokenNameItems.value;
	const suggestedSymbolNameLength = tempSymbol.length.toString();
	const mergedTokenName = tokenNameSplit.join("");
	const mergedTokenNameLength = mergedTokenName.length;
	const secondLetter = tokenNameSplit[0].charAt(1);
	const midPoint = Math.round(mergedTokenNameLength / 2);
	const middleLetter = mergedTokenName.charAt(midPoint);
	const endPoint = mergedTokenNameLength - 1;
	const lastLetter = mergedTokenName.charAt(endPoint);
	switch (suggestedSymbolNameLength) {
		case "1":
			tempSymbol += secondLetter.toUpperCase();
			break;
		case "2":
			tempSymbol += middleLetter.toUpperCase();
			break;
		case "3":
			tempSymbol += lastLetter.toUpperCase();
			break;
	}
	suggestedSymbolName.value = tempSymbol;
	deal.symbol = tempSymbol;
};

const processUpload = () => {
	loading.value = true
	uploadFile.value = true;
	showDealPreview.value = false;
};

const setImageLink = (url) => {
	deal.image = url;
	uploadFile.value = false;
	checkRequestType();
};

const checkRequestType = () => {
	if (route.params.dealId || dealInfo.value?.status === "Approved") {
		updateDeal();
	} else {
		createDeal();
	}
};

const createDeal = async () => {
	loading.value = true;
	if (
		deal.investment_type !== "Debt" &&
		deal.investment_type !== "Fixed Interest"
	) {
		delete deal.interest;
		delete deal.payout_frequency;
		delete deal.tenor;
	}
	if (deal.investment_type !== "SAFE") {
		delete deal.discount;
	}
	if (plan.value.type.toLowerCase() !== "fixed") {
		delete deal.platform_fee;
		delete deal.buy_fee;
	}
	const action = store.dispatch("Deals/createDealRequest", deal);
	try {
		const response = await action;
		const { message, data } = response.data;
		store.dispatch("showToast", {
			description: message,
			display: true,
			type: "success",
		});
		loading.value = false;
		dealId.value = data._id;
		showSpvOptions.value = true;
	} catch (error) {
		loading.value = false;
		return error;
	}
};

const updateDeal = async () => {
	loading.value = true;
	// if (deal.investment_type === "SAFE") {
	//   deal.investment_type = "Safe";
	// }
	const object = {
		name: deal.name,
		symbol: deal.symbol,
		image: deal.image,
		raise_amount: deal.raise_amount,
		valuation: deal.valuation,
		min_buy: deal.min_buy,
		closed:
			dealInfo.value.status === "Approved"
				? dealInfo.value.closed
				: deal.closed,
		max_buy: deal.max_buy,
		buy_fee: deal.buy_fee,
		type: deal.type,
		platform_fee: deal.platform_fee,
		secondaries: deal.secondaries,
		deal_access: deal.deal_access,
		investment_type: deal.investment_type,
		interest: deal.interest,
		tenor: deal.tenor,
		discount: deal.discount,
		spv: deal.spv,
		risk: deal.risk,
		currency: deal.currency,
		country: deal.country,
		payout_frequency: deal.payout_frequency,
	};
	if (plan.value.type.toLowerCase() !== "fixed") {
		delete object.platform_fee;
		delete object.buy_fee;
	}
	if (
		deal.investment_type !== "Debt" &&
		deal.investment_type !== "Fixed Interest"
	) {
		delete object.interest;
		delete object.tenor;
		delete object.payout_frequency;
	}
	if (deal.investment_type !== "SAFE") {
		delete object.discount;
	}
	const action = store.dispatch("Deals/updateDealRequest", {
		deal: object,
		id: dealInfo.value._id,
	});
	try {
		const response = await action;
		const { message, data } = response.data;
		store.dispatch("showToast", {
			description:
				dealInfo.value.status === "Approved"
					? "Your token has been updated successfully!"
					: message,
			display: true,
			type: "success",
		});
		loading.value = false;
		if (data.spv) {
			router.push({ name: "DealInfo", params: { dealId: data._id } });
		} else if (dealInfo.value.status !== "Approved") {
			showSpvOptions.value = true;
		}
	} catch (error) {
		loading.value = false;
		return error;
	}
};

const switchModal = (modalToShow) => {
	switch (modalToShow) {
		case "spvList":
			showSpvList.value = true;
			showInfo.value = false;
			showSuccessModal.value = false;
			showSpvOptions.value = false;
			showUploadSpv.value = false;
			break;
		case "spvOptions":
			showSpvList.value = false;
			showSpvOptions.value = true;
			showSuccessModal.value = false;
			showInfo.value = false;
			showUploadSpv.value = false;
			break;
		case "spvInfo":
			showInfo.value = true;
			showSpvList.value = false;
			showSuccessModal.value = false;
			showSpvOptions.value = false;
			showUploadSpv.value = false;
			break;
		case "success":
			showSuccessModal.value = true;
			showInfo.value = false;
			showSpvList.value = false;
			showSpvOptions.value = false;
			showUploadSpv.value = false;
			break;
		case "uploadSpv":
			showUploadSpv.value = true;
			showSuccessModal.value = false;
			showInfo.value = false;
			showSpvList.value = false;
			showSpvOptions.value = false;
			break;
	}
};
const linkDeal = (response) => {
	const spv = response.data.data;
	linkSpv(spv);
};
const linkSpv = async (spv) => {

	const object = {
		name: deal.name,
		symbol: deal.symbol,
		image: deal.image,
		raise_amount: deal.raise_amount,
		valuation: deal.valuation,
		min_buy: deal.min_buy,
		closed:
			dealInfo.value.status === "Approved"
				? dealInfo.value.closed
				: deal.closed,
		max_buy: deal.max_buy,
		buy_fee: deal.buy_fee,
		type: deal.type,
		platform_fee: deal.platform_fee,
		secondaries: deal.secondaries,
		deal_access: deal.deal_access,
		investment_type: deal.investment_type,
		interest: deal.interest,
		tenor: deal.tenor,
		discount: deal.discount,
		risk: deal.risk,
		currency: deal.currency,
		country: deal.country,
		payout_frequency: deal.payout_frequency,
		spv: spv._id,

	}
	const payload = {
		id: dealId.value,
		deal: object,
	};
	await store.dispatch("Deals/updateDealRequest", payload);
	switchModal("success");
};
const showInfoModal = (object) => {
	spv.value = object;
	switchModal("spvInfo");
};

const getCurrency = (symbol) => {
	let currency = null;
	switch (symbol) {
		case "NGN":
			currency = "Nigerian Naira";
			break;
		case "USD":
			currency = "United States Dollar";
			break;
		case "KES":
			currency = "Kenyan Shillings";
			break;
	}
	return currency;
};

onMounted(() => {
	if (asset.value) {
		setValues();
		createTokenSymbol();
	}
	if (route.params.dealId) {
		getDealInfo(route.params.dealId);
	} else {
		store.dispatch("organisationModule/getTransactionFees");
		store.dispatch("organisationModule/getOrganisationPlan");
	}
	if (route.query.assetId) {
		store.dispatch("Deals/setDealInfo", null);
	}
	deal.platform_fee = percentageMax.value;
	deal.deal_access = "Public";
});

watch(asset, (value) => {
	if (value) {
		setValues();
		createTokenSymbol();
	}
});
watch(interest, (value) => {
	deal.interest = Number(value);
});
watch(suggestedSymbolName, () => {
	checkSuggestedSymbolLength();
});
watch(deal_access, (value) => {
	deal.deal_access = value ? "Private" : "Public";
});
watch(dealInfo, () => {
	prefillForm();
});
watch(valuationFormatted, (value) => {
	valuation.value = value;
});
watch(raiseAmountFormatted, (value) => {
	raise_amount.value = value;
	deal.raise_amount;
});
watch(minBuyFormatted, (value) => {
	min_buy.value = value;
});
watch(type, (value) => {
	if (value) {
		deal.type = "Public";
	} else {
		deal.type = "Private";
	}
});
watch(maxBuyFormatted, (value) => {
	max_buy.value = value;
});
watch(
	validationResult,
	(value) => {
		console.log({deal: Object.keys(deal).length, validation: value.length});
		if (!value.includes(false) && value.length === Object.keys(deal).length) {
			if (!buy_fee.value) {
				deal.platform_fee = percentageMax.value;
				deal.buy_fee = transactionFees.value * 100;
			}
			showDealPreview.value = true;
		}
	},
	{ deep: true }
);
watch(transactionFees, (val) => {
	if (val && !deal.buy_fee && !buy_fee.value) {
		deal.platform_fee = percentageMax.value;
		deal.buy_fee = val * 100;
	}
});
watch(buy_fee, (val) => {
	if (!val && !deal.buy_fee) {
		deal.platform_fee = percentageMax.value;
		deal.buy_fee = transactionFees.value * 100;
	}
});
watch(
	() => deal.buy_fee,
	(val) => {
		if ((buy_fee.value && val && val > 0) || deal.buy_fee) {
			if (val > 1) {
				deal.platform_fee = percentageMax.value - deal.buy_fee;
			} else {
				deal.platform_fee = percentageMax.value;
			}
		}
	}
);
watch(plan, (val) => {
	percentageMax.value = val.percentage;
});
if (route.query.assetId) {
	store.dispatch("Asset/fetchAsset", route.query.assetId);
}
convertToLocale;
formatAmountToDollar;
</script>

<style lang="scss" scoped>
.create-deal {
	border: 1px solid #f1f3f6;
	box-sizing: border-box;
	border-radius: 4px;
	transition: all 800ms ease-out 0s;
	max-width: 818px;
	margin-left: auto;
	margin-right: auto;
	&__button {
		width: 24px;
		height: 24px;
		&:hover {
			background: inherit;
		}
	}
	&__checkbox {
		accent-color: #020b27;
	}
	&-preview__modal {
		width: 300px;
		@media (min-width: 768px) {
			width: 430px;
		}
	}
}

.preview-button {
	background: #f0f4fd;
	border-radius: 4px;
	height: 44px;
	&:hover {
		color: inherit;
	}
}

.border-bottom {
	border-bottom: 1px solid #dde1e9;
	padding-bottom: 15px;
}

.required {
	color: indianred;
}

.info {
	background: #fdf2e7;
	border-radius: 4px;
	color: #694521;
}

@media (min-width: 768px) {
	.create-deal {
		&__form {
			padding-left: 40px;
			padding-right: 40px;
		}
	}
}

@media (min-width: 1024px) {
	.price__div {
		width: 49%;
		max-width: 210px;
	}
}
</style>
