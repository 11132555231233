<template>
  <div>
    <ComboField
      :currentValue="linkPrefix"
      class="tw-border tw-border-[#DDE1E9] tw-rounded tw-flex tw-items-center tw-pl-[3px] tw-h-[46px]"
      :class="[v$.value.$error ? 'custom-form__input--error' : null]"
      position="first"
    >
      <InputFieldLabel
        :label="label"
        :inputActive="inputActive"
        @label-click="focusInput"
        class="tw-flex-grow tw-h-[40px]"
        :required="required"
      >
        <input
          type="text"
          :name="label"
          :ref="name"
          :id="name"
          :readonly="disabled"
          class="tw-w-full tw-rounded custom-form__input tw-pl-6 tw-text-secondary tw-border-0"
          :class="[height, disabled ? 'tw-cursor-not-allowed' : null]"
          v-model="value"
          @focus="inputActive = true"
          @input="$emit('input')"
          @blur="!value || value.length === 0 ? (inputActive = false) : null"
        />
      </InputFieldLabel>
    </ComboField>
    <p class="tw-text-red-error tw-w-full" v-if="v$.value.$error">
      {{ v$.$errors[0].$message }}
    </p>
  </div>
</template>

<script>
  import InputFieldLabel from "./InputFieldLabel";
  import useValidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import ComboField from "./ComboField.vue";
  export default {
    name: "LinkInput",
    data() {
      return {
        inputActive: false,
        value: null,
        v$: useValidate(),
        isValid: true,
      };
    },
    components: {
      InputFieldLabel,
      ComboField,
      ComboField,
    },
    validations() {
      return {
        value: {
          required: this.required ? required : false,
        },
      };
    },
    emits: ["input-changed", "input", "is-valid"],
    watch: {
      value(val) {
        if ((val && val.length > 0) || (val && val > 0)) {
          this.inputActive = true;
          this.isValid = true;

          this.$emit("input-changed", {
            name: this.name,
            value: `${this.linkPrefix}${val}`,
          });
        }
        if (!val || val.length === 0) {
          this.isValid = false;
        }
      },
      currentValue(val) {
        this.getValue(val);
      },
      validate(val) {
        if (val) {
          this.validateInput();
        }
      },
    },
    props: {
      label: {
        type: String,
        required: true,
        default: () => "",
      },
      required: {
        type: Boolean,
        required: false,
        default: () => true,
      },
      currentValue: {
        required: false,
        default: () => "",
      },
      validate: {
        type: Boolean,
        required: true,
      },
      linkPrefix: {
        type: String,
        required: false,
        default: () => {
          return "https://";
        },
      },
      name: {
        type: String,
        required: true,
        default: () => "",
      },
      height: {
        type: String,
        required: false,
        default: () => "tw-h-[40px]",
      },
      disabled: {
        type: Boolean,
        required: false,
      },
    },
    mounted() {
      this.getValue(this.currentValue);
    },
    methods: {
      focusInput() {
        this.$refs[this.name].focus();
      },
      getValue(currentValue) {
        if (currentValue !== this.value) {
          if (currentValue.includes("https://")) {
            const valueSplit = currentValue.split("/");
            switch (this.name) {
              case "website":
                this.value = valueSplit[2];
                break;
              case "facebook":
                this.value = valueSplit[3];
                break;
              case "twitter":
                this.value = valueSplit[3];
                break;

              default:
                this.value = currentValue.split("/")[2];
                break;
            }
          } else {
            this.value = currentValue;
          }
        }
      },
      validateInput() {
        this.v$.value.$touch();
        //check if field is required
        if (this.required) {
          this.isValid = !this.v$.value.$error ? true : false;
        } else {
          this.isValid = true;
        }
        this.$emit("is-valid", this.isValid);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .custom-form {
    &__input {
      transition: all 500ms ease-in-out;
      padding-left: 28px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: 0;
      }
      &--default {
        border: 1px solid #e1e6f0;
      }
      &--error {
        border: 1px solid #db4343;
      }
    }
  }
</style>
