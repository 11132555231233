<template>
  <div class="tw-relative tw-flex tw-flex-col">
    <label :for="label"></label>
    <select
      class="tw-border-black-light2 tw-h-12 tw-rounded tw-p-2 tw-cursor-pointer tw-text-secondary focus:tw-border-primary"
      name="country code"
      id="country-code"
      v-model="state"
      required
    >
      <option value="" disabled>Select State</option>
      <option
        class="tw-flex tw-items-center"
        v-for="(state, index) in states"
        :value="state.name"
        :key="index"
      >
        <p>
          {{ state.name }}
        </p>
      </option>
    </select>
    <img
      src="@/assets/img/solid-chevron-down.svg"
      class="tw-absolute tw-right-2 tw-bottom-6 tw--z-10"
      alt="chevron icon"
    />
  </div>
</template>

<script>
  import countryList from "@/utils/countryRegionData.js";

  export default {
    name: "OnboardingStateInput",

    emits: ["set"],

    props: {
      label: { type: String, default: () => "", required: true },
      country: { type: String, default: () => "", required: true },
      currentValue: {
        type: String,
        required: false,
      },
    },

    data() {
      return {
        countryList,
        states: [],
        state: "",
      };
    },

    methods: {
      emitState(state) {
        this.$emit("set", state);
      },
      getStateByProperty() {
        this.state = this.states.filter((stateObj) => {
          return (
            this.currentValue === stateObj.name ||
            this.currentValue === stateObj.shortCode
          );
        })[0]?.name;
      },
    },

    mounted() {
      this.countryList.map((country) => {
        if (
          this.country === country.countryName ||
          this.country === country.countryShortCode
        ) {
          this.states = country.regions;
          this.state = "";
        }
      });
    },

    watch: {
      country(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.countryList.map((country) => {
            if (
              this.country === country.countryName ||
              this.country === country.countryShortCode
            ) {
              this.states = country.regions;
              this.state = "";
            }
          });
        }
      },
      currentValue(val) {
        if (val) {
          this.getStateByProperty();
        }
      },
      states(val) {
        if (this.currentValue) {
          this.getStateByProperty();
        }
      },

      state(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.emitState(newValue);
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
