<template>
  <section class="asset tw-mt-8 lg:tw-px-8 deals md:tw-px-16">
    <div
      class="md:tw-flex tw-gap-x-5 asset-heading__div tw-items-center tw-justify-between"
    >
      <div class="" v-if="!$route.params.dealId">
        <h1 class="asset__heading tw-font-bold tw-text-xl tw-mb-1">
          Asset Details
        </h1>
        <p class="tw-text-sm tw-text-black-light">
          Fill in the following details of the asset you’re requesting a token
          for and we’ll take it from there.
        </p>
      </div>
      <div class="tw-w-36" v-if="showButton">
        <CustomButton
          title="Customize Asset"
          @click="createNewAsset"
          :loading="loading"
        />
      </div>
    </div>
    <section class="asset__form tw-mt-10">
      <AssetInfo @is-active="setActiveTab" />
      <template
        v-if="
          ($route.name === 'CreateAsset' && $route.query?.id) ||
            $route.name === 'DealInfo'
        "
      >
        <InvestmentMemo @is-active="setActiveTab" />
        <Address @is-active="setActiveTab" :isOpen="false" />
        <FounderInfo @is-active="setActiveTab" :isOpen="false" />
        <Socials @is-active="setActiveTab" :isOpen="false" />
      </template>
    </section>
    <div class="asset-heading__div">
      <div class="tw-w-36 tw-ml-auto" v-if="showButton">
        <CustomButton
          title="Continue"
          @click="
            $router.push({ name: 'CreateDeal', query: { assetId: asset._id } })
          "
          :loading="loading"
        />
      </div>
    </div>
  </section>
</template>

<script>
import AssetInfo from "./AssetInfo";
import InvestmentMemo from "./InvestmentMemo";
import Address from "./Address";
import FounderInfo from "./FounderInfo";
import Socials from "./Socials";
import { mapActions, mapGetters } from "vuex";
import CustomButton from "@/components/general/BtnComponent";
import { computed } from "vue";
export default {
  name: "AssetDetails",
  data() {
    return {
      currentSection: "",
      showButton: false,
      loading: false,
      activeTab: "",
    };
  },
  components: {
    AssetInfo,
    InvestmentMemo,
    Address,
    FounderInfo,
    Socials,
    CustomButton,
  },
  provide() {
    return {
      // explicitly provide a computed property
      activeTab: computed(() => this.activeTab),
    };
  },
  created() {
    if (this.$route.query.id) {
      this.fetchAsset(this.$route.query.id);
    }
    if (this.$route.query.preview) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
  },
  computed: {
    ...mapGetters("Asset", ["asset"]),
  },
  methods: {
    ...mapActions(["showToast"]),
    ...mapActions("Asset", ["fetchAsset", "createAsset"]),
    handleClick(obj) {
      if (obj.val) {
        this.currentSection = obj.component;
      } else {
        this.currentSection = "";
      }
    },
    setActiveTab(title) {
      this.activeTab = title;
    },
    cleanObject(object) {
      delete object._id;
      delete object.owner;
      delete object.owner_model;
      delete object.createdAt;
      delete object.updatedAt;
      delete object.verified;
      delete object.__v;
      delete object.uploader_model;
      delete object.uploader;
      delete object.name;
      delete object.asset;
      delete object.kyc_request;
      delete object.entity_type;
      delete object.organisation;
      return object;
    },
    purgeObject(object) {
      delete object.founders;
      delete object.memo;
      delete object.documents;
      return object;
    },
    async createNewAsset() {
      const name = this.asset.name;
      let object = this.cleanObject(this.asset);
      let { founders, memo, documents } = object;
      object = this.purgeObject(object);
      this.loading = true;
      founders = this.cleanObject(founders);
      memo = this.cleanObject(memo);
      const updatedFounders = [];
      const updatedDocuments = [];
      founders.forEach((founder) => {
        updatedFounders.push(this.cleanObject(founder));
      });
      documents.forEach((document) => {
        updatedDocuments.push(this.cleanObject(document));
      });
      documents = this.cleanObject(documents);
      object = {
        name,
        ...object,
        founders: updatedFounders,
        investment_memo: { ...memo, documents: updatedDocuments },
      };
      try {
        const response = await this.createAsset(object);

        const { data, message } = response.data;
        this.showToast({
          description: message,
          display: true,
          type: "success",
        });
        const id = data._id;
        this.$router.push({ name: "CreateAsset", query: { id } });
        this.showButton = false;
        this.currentSection = "AssetInfo";
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.asset {
  min-height: calc(100vh - 120px);
  padding-bottom: 80px;
  &-heading__div {
    max-width: 818px;
    margin-left: auto;
    margin-right: auto;
  }
  &__form {
    max-width: 818px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1280px) {
  .asset {
    height: calc(100vh - 120px);
    overflow-y: scroll;
  }
}
</style>
