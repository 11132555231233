<template>
  <div id="deal-transaction-table">
    <div>
      <TableHead
        :options="options"
        :tab="option"
        label="options"
        @emitOption="emitOption"
      />
    </div>
    <div class="tw-w-full tw-overflow-auto">
      <table class="table tw-w-full">
        <thead v-if="tableRows.length !== 0" class="header">
          <tr class="header-row">
            <th
              class="header-data tw-font-normal tw-py-6"
              ref="tableHead"
              :style="gridStyling"
            >
              <div
                class="tw-justify-self-start"
                v-for="(header, index) in tableHeaders"
                :key="'header' + index"
              >
                {{ header }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr class="body-row">
            <td class="body-data tw-min-h-full" ref="tableRow">
              <div
                class="data tw-py-6 tw-border-t tw-border-gray-light hover:tw-bg-gray-lightest tw-cursor-pointer"
                v-for="(details, index) in tableRows"
                :key="'row' + index"
                :style="gridStyling"
                @click="viewTransaction(details)"
              >
                <span class="tw-justify-self-end">
                  <img
                    v-if="
                      details?.type.includes('Mint') ||
                        details?.type.includes('Fund') ||
                        details?.type.includes('Carry') ||
                        details?.type.includes('Fund') ||
                        details?.type.includes('Buy') ||
                        details?.type.includes('Trade Reversal')
                    "
                    src="https://res.cloudinary.com/djalafcj9/image/upload/v1634440971/getequityV2/in-arrow_fwjcx8.webp"
                    class="tw-ml-2 tw-w-8"
                    alt="in arrow"
                    loading="lazy"
                  />
                  <img
                    v-if="
                      details?.type.includes('Withdraw') ||
                        details?.type.includes('Debit Wallet') ||
                        details?.type.includes('Send') ||
                        details?.type.includes('Fee') ||
                        details?.type.includes('Sell') ||
                        details?.type.includes('Plan')
                    "
                    src="https://res.cloudinary.com/djalafcj9/image/upload/v1634440971/getequityV2/out-arrow_nsqg5i.webp"
                    class="tw-ml-2 tw-w-8"
                    alt="out arrow"
                    loading="lazy"
                  />
                </span>
                <div
                  class="tw-self-center"
                  v-for="(detail, index) in details"
                  :key="'colunm' + index"
                >
                  <p
                    v-html="detail"
                    class="tw-text-sm"
                    :class="{
                      [detail.toLowerCase()]: availableStatuses.includes(
                        detail
                      ),
                    }"
                  ></p>
                </div>
              </div>
              <EmptyState v-if="tableRows.length === 0 && !smallLoading">
                <template v-slot:message>
                  <p class="message tw-text-center tw-text-gray tw-my-3">
                    There has been no transaction on your account.
                  </p>
                </template>
              </EmptyState>
              <SmallLoader
                v-if="smallLoading && table === 'dealTransactionTable'"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tw-flex tw-justify-end tw-mr-2">
      <Pagination
        :totalPage="pages"
        :currentPage="page"
        :maxVisible="5"
        @pageChange="gotoPage"
      />
    </div>
    <ViewTransactionModal
      v-if="showViewTransactionModal"
      class="tw-z-20"
      @close="showViewTransactionModal = !showViewTransactionModal"
    >
      <template v-slot:content>
        <div class="header">
          <h3 class="tw-text-xl tw-text-secondary">Transaction Summary</h3>
          <p class="tw-text-gray tw-text-lg tw-mt-2">
            {{ transactionRef }}
          </p>
        </div>
        <div class="tw-mt-16">
          <p
            class="tw-flex tw-justify-between tw-text-secondary tw-border-b tw-pb-3.5"
          >
            {{ description }}
            <img
              :src="image"
              class="tw-w-5 tw-h-5 tw-cursor-pointer"
              alt="token"
            />
          </p>
        </div>
        <div class="tw-border-b tw-pb-3.5 tw-my-4">
          <div class="tw-flex tw-justify-between">
            <p class="tw-text-black-light tw-text-xs">Amount</p>
            <p class="tw-text-black-light tw-text-xs">Type</p>
          </div>
          <div class="tw-flex tw-justify-between">
            <p>{{ amount }}</p>
            <p>{{ type }}</p>
          </div>
        </div>
        <div class="tw-pb-10">
          <div class="tw-flex tw-justify-between">
            <p class="tw-text-black-light tw-text-xs">Date</p>
            <p class="tw-text-black-light tw-text-xs">Status</p>
          </div>
          <div class="tw-flex tw-justify-between">
            <p>{{ date }}</p>
            <p
              :class="{
                'tw-text-success': status === 'Completed',
                'tw-text-warning': status === 'Pending',
                'tw-text-error': status === 'Failed',
              }"
            >
              {{ status }}
            </p>
          </div>
        </div>
      </template>
    </ViewTransactionModal>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from "vuex";
  import TableHead from "@/components/tables/TableHeader";
  import ViewTransactionModal from "@/components/general/MediumModal";
  import EmptyState from "@/components/notification/EmptyState";
  import Pagination from "@/components/navigation/Pagination";
  import {
    formatAmountToDollar,
    formatISOTime,
    formatISODate,
    truncate,
    removeUnderscore,
  } from "@/utils/helpers.js";

  export default {
    name: "DealTransactionTable",

    components: {
      Pagination,
      TableHead,
      ViewTransactionModal,
      EmptyState,
    },

    data() {
      return {
        showViewTransactionModal: false,
        transactionRef: "",
        image: "",
        amount: "",
        type: "",
        date: "",
        status: "",
        option: "",
        availableStatuses: ["Pending", "Completed", "Failed"],
        options: ["All transactions", "Completed", "Pending"],
        tableHeaders: ["", "Details", "Reference", "Amount", "Status"],
        tableRows: [],
        table: "",
        currentOption: "",
      };
    },

    computed: {
      ...mapState({
        smallLoading: (state) => state.smallLoading,
      }),
      ...mapGetters("Deals", [
        "DealTransactions",
        "dealInfo",
        "totalTransactions",
        "page",
        "pages",
      ]),
      gridStyling() {
        return {
          "grid-template-columns": `${50}px repeat(${4}, ${220}px)`,
        };
      },
    },

    methods: {
      ...mapActions("Deals", ["fetchDealTransactions"]),

      viewTransaction(details) {
        this.transactionRef = details.reference.split(">")[1].split("<")[0];
        this.description = details.details.split(">")[1].split("<")[0];
        this.image = details.image.split(">")[1].split("<")[0];
        this.amount = details.amount;
        this.type = details.type.split(">")[1].split("<")[0];
        this.date = details.details.split(">")[4].split("<")[0];
        this.status = details.status;
        this.showViewTransactionModal = true;
      },

      emitOption(option) {
        const id = this.dealInfo?.token?._id;
        this.table = "dealTransactionTable";
        this.tableRows = [];
        let type;
        let limit;
        let query;

        switch (option) {
          case "All transactions":
            this.currentOption = null;
            this.option = "All transactions";
            query = { page: 1, id, limit: 10 };
            this.fetchDealTransactions(query);
            break;
          case "Completed":
            this.currentOption = option;
            this.option = "Completed";
            query = { page: 1, id, status: option, limit: 10 };
            this.fetchDealTransactions(query);
            break;
          case "Pending":
            this.currentOption = option;
            this.option = "Pending";
            query = { page: 1, id, status: option, limit: 10 };
            this.fetchDealTransactions(query);
            break;

          default:
            break;
        }
      },

      updateTableRow(transactions) {
        // this.table = "";
        this.tableRows = transactions.map((transaction) => {
          let type = removeUnderscore(transaction.type);
          switch (type) {
            case "Debit Sell Wallet":
              type = "Debit Wallet";
              break;

            case "Fund Sell Wallet":
              type = "Fund Wallet";
              break;
            default:
              break;
          }
          let name = `${transaction.token.name}`;
          switch (name) {
            case "GetEquity USD Token":
              name = "USD";
              break;
            default:
              break;
          }
          let volume = transaction.volume.toLocaleString();
          let details = truncate(`${type}: ${volume} ${name}`, 30);
          let amount;
          if (transaction.price) {
            amount = transaction.price * transaction.volume;
          } else {
            amount = transaction.token?.price.buy * transaction.volume;
          }
          return {
            details: `<span class="tw-font-bold">${details}</span> <br />
          <span class="tw-text-black-light tw-text-xs">${formatISODate(
            transaction.createdAt
          )}  ${formatISOTime(transaction.createdAt)}</span>`,
            reference:
              type === "Fee" ||
              type === "Debit Wallet" ||
              type === "Send" ||
              type === "Withdraw" ||
              type === "Sell" ||
              type === "Plan"
                ? `<span class="tw-text-xs tw-mb-1">${truncate(
                    transaction.reference,
                    25
                  )}</span> <br />
          <span class="tw-text-xs tw-text-error">Debit</span>`
                : `<span class="tw-text-xs tw-mb-1">${truncate(
                    transaction.reference,
                    25
                  )}</span> <br />
          <span class="tw-text-xs tw-text-success">Credit</span>`,
            amount: formatAmountToDollar(amount, 2, "USD"),
            status: transaction.status,
            type: `<span class="tw-hidden">${type}</span>`,
            image: `<span class="tw-hidden">${transaction.token?.image}</span>`,
          };
        });
      },

      gotoPage(tablePage) {
        this.tableRows = [];
        const id = this.dealInfo.token;
        const query = {
          page: tablePage,
          id,
          status: this.currentOption,
          limit: 10,
        };
        this.fetchDealTransactions(query);
      },
    },

    watch: {
      DealTransactions(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.updateTableRow(this.DealTransactions);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #deal-transaction-table {
    @include fadeIn;

    .table {
      .header {
        .header-row {
          .header-data {
            display: grid;
            column-gap: 2rem;
          }
        }
      }
      .table-body {
        .body-row {
          .body-data {
            .data {
              display: grid;
              column-gap: 2rem;
              font-size: 0.7rem;

              // Added with javascript
              .completed,
              .pending,
              .failed {
                text-align: left;
                padding: 0.5rem;
                border-radius: 5px;
              }
              .completed {
                color: $success;
                background-color: $success-light;
                max-width: 5.4rem;
              }
              .pending {
                color: $warning;
                background-color: $warning-light;
                max-width: 4.2rem;
              }
              .failed {
                color: $error;
                background-color: $error-light;
                max-width: 3.6rem;
              }
            }
          }
        }
      }
    }
  }
</style>
