<template>
  <div class="add-member tw-bg-white tw-p-12">
    <h1
      class="tw-font-medium tw-text-xl tw-text-secondary tw-leading-8 tw-mb-8"
    >
      Add member
    </h1>
    <form @submit.prevent="sendInvite">
      <InputField
        label="Search for member"
        class="tw-mb-4"
        :currentValue="memberName"
        :validate="false"
        name="memberName"
        @input-changed="findMember"
      />
      <div
        v-show="showMembersResult"
        class="member-result tw-bg-white tw-w-full"
      >
        <div
          class="member-result__div tw-bg-white tw-cursor-pointer tw-p-2"
          @click="setMember(member)"
          v-for="member in members"
          :key="member._id"
        >
          <p>
            {{ member.fname + " " + member.lname }}
          </p>
        </div>
      </div>
      <span
        class="tw-w-5 tw-h-5 add-member__button tw-cursor-pointer"
        @click="$emit('show-member-form', false)"
      >
        <img src="@/assets/img/close-dark.svg" alt="close icon" />
      </span>

      <button
        type="submit"
        class="tw-w-full tw-bg-primary tw-rounded tw-text-sm tw-text-white tw-p-4"
        :disabled="loading || !memberName"
      >
        <span v-show="!loading">Add member</span>
        <img
          v-show="loading"
          class="tw-m-auto"
          src="@/assets/img/spinner.svg"
          alt="animated spinner"
          @click="sendInvite"
        />
      </button>
    </form>
  </div>
</template>

<script>
  import InputField from "@/components/general/InputField";
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: "AddMemberModal",
    data() {
      return {
        memberName: null,
        showMembersResult: false,
        loading: false,
      };
    },
    components: {
      InputField,
    },
    emits: ["show-member-form"],
    computed: {
      ...mapGetters("Deals", ["members", "dealInfo"]),
    },
    watch: {
      members(val) {
        if (val.length > 0) {
          this.showMembersResult = true;
        }
      },
    },
    methods: {
      ...mapActions("Deals", [
        "fetchMemberByName",
        "inviteMember",
        "fetchDealMembers",
      ]),
      ...mapActions(["showToast"]),
      async findMember(obj) {
        const name = obj.value;
        if (name.length === 0) {
          this.showMembersResult = false;
        } else {
          try {
            await this.fetchMemberByName(name);
          } catch (error) {
            return error;
          }
        }
      },
      setMember(member) {
        this.memberName = `${member.fname} ${member.lname}`;
        this.member = member._id;
        this.showMembersResult = false;
      },
      async sendInvite() {
        const members = [this.member];
        this.loading = true;
        try {
          const response = await this.inviteMember({
            members,
            id: this.dealInfo.token._id,
          });
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.loading = false;
          this.fetchDealMembers(this.dealInfo.token._id);
          this.$emit("show-member-form", false);
        } catch (error) {
          this.loading = false;

          return error;
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
.add-member {
  position: absolute;
  box-shadow: 0px 2px 4px rgba(44, 73, 137, 0.06);
  border-radius: 8px;
  z-index: 3;
  right: 50px;
  width: 100vw;
  max-width: 405px;
  &__button {
    position: absolute;
    right: 24px;
    top: 24px;
    &:hover {
      background: transparent;
    }
  }
}
.member-result {
  position: absolute;
  max-width: 300px;
  &__div {
    border: 1px solid #001140;
    widows: 100%;
  }
}
</style>
