<template>
  <div
    id="deals-card"
    class="tw-bg-white tw-rounded tw-mb-6"
    v-if="deal"
    :class="[componentType === 'button' ? 'deal-card__div' : null]"
  >
    <router-link
      :to="{ name: 'DealInfo', params: { dealId: deal._id } }"
      v-if="componentType === 'link'"
    >
      <div class="tw-p-4">
        <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
          <div class="tw-flex tw-items-center">
            <h1
              class="deal__heading tw-font-semibold tw-text-sm tw-text-black tw-truncate"
            >
              {{ deal.name }}
            </h1>
            <div
              class="tw-ml-1 tw-box-border tw-flex tw-items-center tw-rounded tw-px-1 tw-py-0.5"
              :class="[deal.status.toLowerCase()]"
            >
              <p class="status tw-text-xs">
                {{ deal.status }}
              </p>
            </div>
          </div>
          <img
            :src="deal.image"
            :alt="deal.name"
            class="tw-w-6 tw-h-6 tw-rounded"
          />
        </div>
        <div class="tw-flex tw-flex-wrap tw-justify-between">
          <div
            class="tw-flex tw-w-full tw-bg-gray-dark tw-h-1 tw-mt-2 tw-rounded-md"
          >
            <div
              class="tw-rounded-md tw-bg-primary tw-transition-all"
              :style="{ flexBasis: `${totalRaisedPercentage}%` }"
            ></div>
          </div>
          <p class="tw-text-xs tw-mt-1 tw-text-gray">
            {{
              totalRaisedPercentage !== 0
                ? totalRaisedPercentage.toFixed(2)
                : 0
            }}% raised
          </p>
          <p class="tw-text-xs tw-mt-1 tw-text-gray">
            <template v-if="deal.token?.total_raised"
              >{{ formatAmountToDollar(totalRaised, 2, currency) }}</template
            >
            <template v-else> {{ formatAmountToDollar(totalRaised, 2, currency) }}</template>

            of
            <template v-if="deal.token?.raise_amount">
              {{
                deal.token.raise_amount > 0
                  ? formatAmountToDollar(deal.token.raise_amount, 2, currency)
                  : 0
              }}
            </template>
            <template v-else>
              {{
                deal.raise_amount > 0 ? formatAmountToDollar(deal.raise_amount, 2, currency) : 0
              }}
            </template>
          </p>
        </div>
        <div class="tw-mt-6 tw-flex tw-justify-between">
          <div class="">
            <h2 class="tw-text-left tw-text-black-light tw-text-sm">
              Minimum Investment
            </h2>
            <p class="tw-font-CircularStd-medium tw-font-medium tw-text-left">
              {{ formatAmountToDollar(deal.min_trade.buy, 2, currency) }}
            </p>
          </div>
          <div class="">
            <h2 class="tw-text-sm tw-text-black-light tw-text-right tw-text-sm">
              Investment Type
            </h2>
            <p class="tw-font-CircularStd-medium tw-font-medium tw-text-right">
              ${{ deal.investment_type }}
            </p>
          </div>
          <!-- <div
          >
            <h2 class="tw-text-sm tw-text-black-light tw-text-right tw-text-sm">
              Market cap
            </h2>
            <p class="tw-font-CircularStd-medium tw-font-medium tw-text-right">
              ${{ deal.valuation ? convertToLocale(deal.valuation) : 0 }}
            </p>
          </div> -->
        </div>
      </div>
    </router-link>
    <div class="tw-p-4" v-else>
      <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
        <div class="tw-flex tw-items-center">
          <h1
            class="deal__heading tw-font-semibold tw-text-sm tw-text-black tw-truncate"
          >
            {{ deal.name }}
          </h1>
          <div
            class="tw-ml-1 tw-box-border tw-flex tw-items-center tw-rounded tw-px-1 tw-py-0.5"
            :class="[deal.status.toLowerCase()]"
          >
            <p class="status tw-text-xs">
              {{ deal.status }}
            </p>
          </div>
        </div>
        <img
          :src="deal.image"
          :alt="deal.name"
          class="tw-w-6 tw-h-6 tw-rounded"
        />
      </div>
      <div class="tw-flex tw-flex-wrap tw-justify-between">
        <div
          class="tw-flex tw-w-full tw-bg-gray-dark tw-h-1 tw-mt-2 tw-rounded-md"
        >
          <div
            class="tw-rounded-md tw-bg-primary tw-transition-all"
            :style="{ flexBasis: `${totalRaisedPercentage}%` }"
          ></div>
        </div>
        <p class="tw-text-xs tw-mt-1 tw-text-gray">
          {{ totalRaisedPercentage }}% raised
        </p>
        <p class="tw-text-xs tw-mt-1 tw-text-gray">
          <template v-if="deal.token?.total_raised"
            >${{ convertToLocale(totalRaisedPercentage) }}</template
          >
          <template v-else>
            ${{ convertToLocale(totalRaisedPercentage) }}</template
          >

          of
          <template v-if="deal.token?.raise_amount">
            ${{
              deal.token.raise_amount > 0
                ? convertToLocale(deal.token.raise_amount)
                : 0
            }}
          </template>
          <template v-else>
            ${{
              deal.raise_amount > 0 ? convertToLocale(deal.raise_amount) : 0
            }}
          </template>
        </p>
      </div>
      <div class="tw-mt-6 tw-flex tw-justify-between">
        <div class="">
          <h2 class="tw-text-left tw-text-black-light tw-text-sm">
            Minimum Investment
          </h2>
          <p class="tw-font-CircularStd-medium tw-font-medium tw-text-left">
            {{ formatAmountToDollar(deal.min_trade.buy, 2, "USD") }}
          </p>
        </div>
        <div class="">
          <h2 class="tw-text-sm tw-text-black-light tw-text-right tw-text-sm">
            Market cap
          </h2>
          <p class="tw-font-CircularStd-medium tw-font-medium tw-text-right">
            ${{ deal.valuation ? convertToLocale(deal.valuation) : 0 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { convertToLocale, formatAmountToDollar } from "@/utils/helpers.js";
import { computed } from "vue";
  const props = defineProps({
    deal: {
      type: Object,
      required: true,
      default: () => {},
    },
    componentType: {
      type: String,
      required: false,
      default: () => "link",
    },
  });
  const deal = computed(() => props.deal);
  const totalRaised = computed(() => {
    let raised = null;
    if (deal.value.status === "Approved" && deal.value.token?.total_raised) {
      raised =
        deal.value.token?.total_raised && deal.value.token?.raise_amount
          ? deal.value.token.total_raised * deal.value.token.price.exchange
          : 0;
    } else {
      raised =
        deal.value?.total_raised && deal.value?.raise_amount
          ? deal.value.total_raised
          : 0;
    }
    return raised;
  });
  const totalRaisedPercentage = computed(() => {
    let raised = null;
    if (deal.value.status === "Approved" && deal.value.token?.total_raised) {
      raised =
        deal.value.token?.total_raised && deal.value.token?.raise_amount
          ? (deal.value.token.total_raised * deal.value.token.price.exchange / deal.value.token.raise_amount) *
            100
          : 0;
    } else {
      raised =
        deal.value?.total_raised && deal.value?.raise_amount
          ? (deal.value.total_raised / deal.value.raise_amount) * 100
          : 0;
    }
    return raised;
  });
  const currency = computed(() => deal.value.currency || 'USD');
</script>
<style lang="scss" scoped>
  #deals-card {
    @include fadeIn;

    @media screen and (min-width: 640px) {
      min-width: 300px;
    }

    .status {
      font-size: 9px;
    }

    .approved {
      color: #27ae60;
      background: rgba(39, 174, 96, 0.12);
    }
    .pending {
      color: #e2b93b;
      background: rgba(226, 185, 59, 0.12);
    }
    .rejected {
      color: #eb5757;
      background: rgba(235, 87, 87, 0.08);
    }
    .terminated {
      color: #958f8f;
      background: rgba(224, 224, 224, 0.3);
    }
  }

  .deal__heading {
    max-width: 250px;
  }

  .deal-card__div {
    background-color: #fafbff;
    border-radius: 4px;
    border: 1px solid #e6edff;
    transition: all 3ms ease-in-out;
    min-width: 100% !important;
    &:hover {
      cursor: pointer;
      border: 1px solid #4d84ff;
    }
  }
</style>
