<template>
  <table id="deals-table" class="tw-w-full tw-mb- tw-relative">
    <thead class="header">
      <tr class="header-row">
        <th class="header-data tw-font-normal tw-py-6" ref="tableHead">
          <div
            class="tw-justify-self-start tw-text-black-light tw-pl-10"
            :class="[header === 'id' ? 'tw-hidden' : null]"
            v-for="(header, index) in tableHeaders"
            :key="'header' + index"
          >
            {{ header }}
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr class="body-row">
        <td class="body-data" ref="tableRow">
          <div
            class="data tw-py-6 tw-border-t tw-border-gray-light hover:tw-bg-gray-lightest"
            :ref="rowDetails"
            v-for="(details, index) in tableRows"
            :key="'row' + index"
          >
            <div
              class="tw-self-center tw-pl-10"
              v-for="(detail, index) in details"
              :key="'colunm' + index"
            >
              <p v-html="detail" class="tw-text-sm"></p>
            </div>
            <div class="tw-relative tw-flex tw-items-center">
              <router-link
                :to="{
                  name: 'DealInfo',
                  params: { dealId: details.id.split('>')[1].split('<')[0] },
                }"
                class="tw-cursor-pointer tw-text-primary tw-text-sm"
              >
                Details
              </router-link>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "DealsTable",

  props: {
    tableHeaders: { type: Array, default: () => [], required: true },
    tableRows: { type: Array, default: () => [], required: true },
    columnNumber: { type: Number, default: () => 0, required: true },
    columnWidth: { type: Number, default: () => 0, required: true },
    // totalPage: { type: Number, default: () => 0, required: true },
    // currentPage: { type: Number, default: () => 0, required: true },
  },

  data() {
    return {
      rows: [],
      type: "",
      colunmSize: "",
      showOptions: false,
      selectedMember: "",
      sendDeal: false,
      loading: false,
      member: null,
      showConfirmModal: false,
      confirmationType: "",
      memberInfo: null,
    };
  },

  mounted() {
    this.setTableColumn();
  },

  methods: {
    rowDetails(el) {
      this.rows.push(el);
    },
    setTableColumn() {
      this.colunmSize = `repeat(${this.columnNumber}, ${
        this.columnWidth
      }px) ${0}px ${80}px`;
      this.setColumnStatus();
      this.$refs.tableHead.style.gridTemplateColumns = this.colunmSize;
      this.rows.forEach((row) => {
        row.style.gridTemplateColumns = this.colunmSize;
      });
    },

    setColumnStatus() {
      this.rows.forEach((row) => {
        let columns = row.querySelectorAll("p");
        if (columns[columns.length - 2].innerHTML === "Approved") {
          columns[columns.length - 2].classList.add("approved");
        } else if (columns[columns.length - 2].innerHTML === "Rejected") {
          columns[columns.length - 2].classList.add("rejected");
        } else if (columns[columns.length - 2].innerHTML === "Terminated") {
          columns[columns.length - 2].classList.add("terminated");
        } else {
          columns[columns.length - 2].classList.add("pending");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#deals-table {
  @include fadeIn;
  .header {
    .header-row {
      .header-data {
        display: grid;
        column-gap: 2rem;
      }
    }
  }
  .table-body {
    .body-row {
      .body-data {
        .data {
          display: grid;
          column-gap: 2rem;
          font-size: 0.7rem;
        }
        // Added with javascript
        .approved,
        .pending,
        .rejected,
        .terminated {
          text-align: center;
          padding: 0.5rem;
          margin-right: 1.4rem;
          border-radius: 5px;
        }
        .approved {
          color: $success;
          background-color: $success-light;
          max-width: 5rem;
        }
        .pending {
          color: $warning;
          background-color: $warning-light;
          max-width: 4.2rem;
        }
        .rejected {
          color: $error;
          background-color: $error-light;
          max-width: 5rem;
        }
        .terminated {
          color: $error;
          background-color: $error-light;
          max-width: 5rem;
        }
      }
    }
  }
}
</style>
