<template>
	<Accordion
		:info="info"
		@is-active="setActiveTab"
		:verified="isFilled"
		icon="https://res.cloudinary.com/djalafcj9/image/upload/v1668692227/getequityV2/inv-memo_tf6gnm.svg"
	>
		<form
			v-if="asset?.memo?.memo || !asset.memo"
			@submit.prevent="checkDocumentLength(true)"
			class="investment-memo__form tw-mt-12 tw-relative"
		>
			<Loader v-if="loading" />
			<div class="tw-flex tw-flex-wrap tw-justify-center gap">
				<div class="tw-w-full investment-memo-form__div tw-mb-5">
					<WysiwygEditor
						@update:modelValue="storeValue"
						class="tw-w-full tw-h-56"
						placeholder="Investment Memo"
						name="memo"
						:content="memo"
					/>
					<p
						class="tw-mb-5 tw-mt-1 tw-text-sm required"
						v-if="
							v$.investmentMemo.overview.$dirty &&
								v$.investmentMemo.overview.$errors.length > 0
						"
					>
						{{ v$.investmentMemo.overview.$errors[0].$message }}
					</p>
				</div>
			</div>

			<div class="lg:tw-flex tw-flex-wrap tw-justify-between">
				<div
					class="investment-memo-form__div investment-memo-form__div--half tw-mt-6 lg:tw-mt-0"
					v-for="(doc, index) in documents"
					:key="index"
				>
					<Dropdown
						:options="docTypes"
						:validate="false"
						@input-changed="storeValue($event, index)"
						label="Document Type"
						name="memoDoc"
						class="tw-mb-2"
					/>
					<template v-if="docType">
						<h3
							class="tw-text-sm tw-mb-6 tw-text-secondary tw-text-sm"
							v-if="doc.type === 'Pitch Deck'"
						>
							Upload {{ doc.type }} (Optional)
						</h3>
						<InputField
							class="tw-mb-6 tw-mt-6"
							:validate="false"
							label="Document name"
							:currentValue="doc.name"
							@input-changed="storeDocName(index, $event, true)"
							name="name"
							v-else
						/>
					</template>
					<DragNDrop
						accept=".pdf"
						:required="false"
						:currentValue="doc.url"
						@file-added="setDocument($event, index, false, true)"
						:startUpload="uploadDoc"
						@reupload="uploadDoc = false"
						@upload-complete="setDocument($event, index, true, true)"
					>
						<p
							class="tw-text-sm tw-text-secondary tw-w-full tw-text-center tw-mb-2"
							v-if="documents[0].url"
						>
							Drag and drop file
						</p>
					</DragNDrop>
					<div class="tw-w-full">
						<button
							type="button"
							@click="removeDocument(index, true)"
							class="tw-mt-3 tw-flex tw-h-8 tw-items-center member__button tw-cursor-pointer tw-text-sm tw-ml-2"
						>
							<img
								class="tw-mr-2"
								src="@/assets/img/delete.svg"
								alt="delete icon"
							/>Delete
						</button>
					</div>
					<!-- <p
        class="tw-mb-5 tw-mt-1 tw-text-sm required"
        v-if="
          v$.investmentMemo.documents.$dirty &&
            v$.investmentMemo.documents.$each.$response.$errors[0].url
              .length > 0
        "
      >
        {{
          v$.investmentMemo.documents.$each.$response.$errors[0].url[0]
            .$message
        }}
      </p> -->
				</div>
				<div class="tw-w-full">
					<button
						v-if="this.documents.length < 3"
						type="button"
						@click="addDocument(true)"
						class="tw-mt-3 tw-flex tw-h-8 tw-items-center member__button tw-cursor-pointer tw-text-sm tw-ml-2"
					>
						<img src="@/assets/img/add-circle.svg" class="tw-w-5 tw-h-5" />{{
							documents.length > 1 ? "Add other Documents" : "Add Document"
						}}
					</button>
				</div>
				<p
					class="tw-text-center tw-text-sm tw-text-secondary tw-w-full tw-mt-4"
					v-if="
						(!dealInfo && !$route.query.preview) ||
							(dealInfo &&
								!$route.query.preview)
					"
				>
					Document should be in good condition and clearly visible.
				</p>
			</div>

			<div
				class="tw-w-full tw-mt-8"
				v-if="
					($route.name === 'CreateAsset' && !$route.query.preview) ||
						($route.name !== 'CreateAsset' &&
							!dealInfo &&
							!$route.query.preview) ||
						($route.name !== 'CreateAsset' &&
							dealInfo &&
							!$route.query.preview)
				"
			>
				<div class="tw-w-32">
					<DealButton
						text="Continue"
						class="tw-text-sm"
						:disabled="
							($route.name === 'CreateAsset' && !$route.query?.id) ||
								!memo ||
								loading
						"
					/>
				</div>
			</div>
		</form>
		<form
			v-else
			@submit.prevent="checkDocumentLength"
			class="investment-memo__form tw-mt-12 tw-relative"
		>
			<Loader v-if="loading" />
			<div class="tw-flex tw-flex-wrap tw-justify-center gap">
				<div class="tw-w-full investment-memo-form__div tw-mb-5">
					<WysiwygEditor
						@update:modelValue="storeValue"
						class="tw-w-full tw-h-56"
						placeholder="Overview"
						name="overview"
						:content="investmentMemo.overview"
					/>
					<p
						class="tw-mb-5 tw-mt-1 tw-text-sm required"
						v-if="
							v$.investmentMemo.overview.$dirty &&
								v$.investmentMemo.overview.$errors.length > 0
						"
					>
						{{ v$.investmentMemo.overview.$errors[0].$message }}
					</p>
				</div>
				<div class="tw-w-full investment-memo-form__div tw-mb-5">
					<WysiwygEditor
						@update:modelValue="storeValue"
						class="tw-w-full tw-h-56"
						name="problem"
						:required="false"
						placeholder="Problem"
						:content="investmentMemo.problem"
					/>
					<!-- <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  v$.investmentMemo.problem.$dirty &&
                    v$.investmentMemo.problem.$errors.length > 0
                "
              >
                {{ v$.investmentMemo.problem.$errors[0].$message }}
              </p> -->
				</div>
			</div>
			<div class="tw-flex tw-flex-wrap tw-justify-center gap">
				<div class="tw-w-full investment-memo-form__div tw-mb-5">
					<WysiwygEditor
						@update:modelValue="storeValue"
						class="tw-w-full tw-h-56"
						name="solution"
						placeholder="Solution"
						:required="false"
						:content="investmentMemo.solution"
					/>
					<!-- <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  v$.investmentMemo.solution.$dirty &&
                    v$.investmentMemo.solution.$errors.length > 0
                "
              >
                {{ v$.investmentMemo.solution.$errors[0].$message }}
              </p> -->
				</div>
				<div class="tw-w-full investment-memo-form__div tw-mb-5">
					<WysiwygEditor
						@update:modelValue="storeValue"
						class="tw-w-full tw-h-56"
						name="opportunity"
						placeholder="Market Opportunity"
						:required="false"
						:content="investmentMemo.opportunity"
					/>
					<!-- <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  v$.investmentMemo.opportunity.$dirty &&
                    v$.investmentMemo.opportunity.$errors.length > 0
                "
              >
                {{ v$.investmentMemo.opportunity.$errors[0].$message }}
              </p> -->
				</div>
			</div>
			<div class="tw-flex tw-flex-wrap tw-justify-center gap">
				<div class="tw-w-full investment-memo-form__div tw-mb-8">
					<WysiwygEditor
						@update:modelValue="storeValue"
						class="tw-w-full tw-h-56"
						name="traction"
						placeholder="Traction"
						:required="false"
						:content="investmentMemo.traction"
					/>
					<!-- <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  v$.investmentMemo.traction.$dirty &&
                    v$.investmentMemo.traction.$errors.length > 0
                "
              >
                {{ v$.investmentMemo.traction.$errors[0].$message }}
              </p> -->
				</div>
				<div class="tw-w-full investment-memo-form__div tw-mb-4">
					<WysiwygEditor
						@update:modelValue="storeValue"
						class="tw-w-full tw-h-56"
						name="team"
						placeholder="Team members"
						:required="false"
						:content="investmentMemo.team"
					/>
					<!-- <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  v$.investmentMemo.team.$dirty &&
                    v$.investmentMemo.team.$errors.length > 0
                "
              >
                {{ v$.investmentMemo.team.$errors[0].$message }}
              </p> -->
				</div>
			</div>

			<div class="lg:tw-flex tw-flex-wrap tw-justify-between">
				<div
					class="investment-memo-form__div investment-memo-form__div--half tw-mt-6 lg:tw-mt-0"
					v-for="(doc, index) in investmentMemo.documents"
					:key="index"
				>
					<Dropdown
						:options="docTypes"
						:validate="false"
						@input-changed="storeValue($event, index)"
						label="Document Type"
						name="docType"
						class="tw-mb-2"
					/>
					<template v-if="docType">
						<h3
							class="tw-text-sm tw-mb-6 tw-text-secondary tw-text-sm"
							v-if="doc.type === 'Pitch Deck'"
						>
							Upload {{ doc.type }} (Optional)
						</h3>
						<InputField
							class="tw-mb-6 tw-mt-6"
							:validate="false"
							label="Document name"
							:currentValue="doc.name"
							@input-changed="storeDocName(index, $event)"
							name="name"
							v-else
						/>
					</template>
					<DragNDrop
						accept=".pdf"
						:required="false"
						:currentValue="doc.url"
						@file-added="setDocument($event, index)"
						:startUpload="uploadDoc"
						@reupload="uploadDoc = false"
						@upload-complete="setDocument($event, index, true)"
					>
						<p
							class="tw-text-sm tw-text-secondary tw-w-full tw-text-center tw-mb-2"
							v-if="!investmentMemo.documents[0].url"
						>
							Drag and drop file
						</p>
					</DragNDrop>
					<div class="tw-w-full">
						<button
							type="button"
							@click="removeDocument(index)"
							class="tw-mt-3 tw-flex tw-h-8 tw-items-center member__button tw-cursor-pointer tw-text-sm tw-ml-2"
						>
							<img
								class="tw-mr-2"
								src="@/assets/img/delete.svg"
								alt="delete icon"
							/>Delete
						</button>
					</div>
					<!-- <p
                class="tw-mb-5 tw-mt-1 tw-text-sm required"
                v-if="
                  v$.investmentMemo.documents.$dirty &&
                    v$.investmentMemo.documents.$each.$response.$errors[0].url
                      .length > 0
                "
              >
                {{
                  v$.investmentMemo.documents.$each.$response.$errors[0].url[0]
                    .$message
                }}
              </p> -->
				</div>
				<div class="tw-w-full">
					<button
						v-if="this.investmentMemo.documents.length < 3"
						type="button"
						@click="addDocument"
						class="tw-mt-3 tw-flex tw-h-8 tw-items-center member__button tw-cursor-pointer tw-text-sm tw-ml-2"
					>
						<img src="@/assets/img/add-circle.svg" class="tw-w-5 tw-h-5" />{{
							investmentMemo.documents.length > 1
								? "Add other Documents"
								: "Add Document"
						}}
					</button>
				</div>
				<p
					class="tw-text-center tw-text-sm tw-text-secondary tw-w-full tw-mt-4"
					v-if="
						(!dealInfo && !$route.query.preview) ||
							(dealInfo &&
								!$route.query.preview)
					"
				>
					Document should be in good condition and clearly visible.
				</p>
			</div>
			<div class="tw-mt-8">
				<h3 class="tw-text-base tw-mb-2 tw-text-secondary tw-text-lg">
					Competitors
				</h3>
				<div
					class="lg:tw-flex tw-flex-wrap tw-items-center tw-gap-x-6 tw-gap-y-3"
				>
					<div
						class="investment-memo-form__div--half tw-mb-2 lg:tw-mb-0"
						v-for="(competitor, index) in investmentMemo.competitors"
						:key="index"
					>
						<label for="competitor" class="tw-text-sm tw-mb-2 tw-block"
							>Competitor {{ index + 1 }}</label
						>
						<InputField
							label="Company Name"
							name="competitors"
							:required="false"
							:minLength="3"
							:validate="false"
							@input-changed="storeValue($event, index)"
							:currentValue="competitor"
						/>
						<div class="tw-w-full">
							<button
								v-if="investmentMemo.competitors.length > 1"
								type="button"
								@click="removeCompetitor(index)"
								class="tw-mt-3 tw-flex tw-h-8 tw-items-center member__button tw-cursor-pointer tw-text-sm tw-ml-2"
							>
								<img
									class="tw-mr-2"
									src="@/assets/img/delete.svg"
									alt="delete icon"
								/>Delete
							</button>
						</div>
					</div>
					<button
						v-if="this.investmentMemo.competitors.length < 5"
						type="button"
						@click="addCompetitor"
						class=" tw-flex tw-h-8 tw-items-center member__button tw-cursor-pointer tw-text-sm tw-text-[#2A62F5] tw-font-CircularStd-medium tw-font-medium tw-gap-x-1"
					>
						<img
							src="@/assets/img/add-circle-blue-round.svg"
							class="tw-w-5 tw-h-5"
						/>Add a Competitor
					</button>
				</div>
			</div>
			<div class="tw-mt-6">
				<h3 class="tw-text-base tw-mb-3 tw-text-secondary tw-text-lg">
					Pitch Video (optional)
				</h3>
				<InputField
					label="Youtube URL"
					:required="false"
					name="link"
					:validate="false"
					@input-changed="setPitchVideo"
					:currentValue="link"
				/>
				<button
					v-if="investmentMemo.pitch_url"
					@click="showPreview = true"
					class="tw-underline tw-text-sm tw-text-[#2A62F5] tw-font-CircularStd-medium tw-font-medium tw-mt-6 hover:tw-bg-white hover:tw-text-[#2A62F5]"
					type="button"
				>
					Preview Video
				</button>
			</div>
			<div
				class="tw-w-full tw-mt-8"
				v-if="
					($route.name === 'CreateAsset' && !$route.query.preview) ||
						($route.name !== 'CreateAsset' &&
							!dealInfo &&
							!$route.query.preview) ||
						($route.name !== 'CreateAsset' &&
							dealInfo &&
							!$route.query.preview)
				"
			>
				<div class="tw-w-32">
					<DealButton
						text="Continue"
						class="tw-text-sm"
						:disabled="
							($route.name === 'CreateAsset' && !$route.query?.id) ||
								v$.$invalid ||
								loading
						"
					/>
				</div>
			</div>
		</form>
		<PreviewModal v-if="showPreview" @close="showPreview = false">
			<template v-slot:content>
				<iframe
					class="tw-rounded-lg"
					width="100%"
					height="400"
					:src="investmentMemo.pitch_url"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe> </template
		></PreviewModal>
	</Accordion>
</template>

<script>
import DealButton from "@/components/deals/DealsButton";
import DragNDrop from "@/components/general/DragNDrop";
import Dropdown from "@/components/general/Dropdown";
import InputField from "@/components/general/InputField";
import PreviewModal from "@/components/general/PreviewModal";
import WysiwygEditor from "@/components/general/WysiwygEditor";
import Accordion from "@/components/setup/Accordion";
import { getValueType } from "@/utils/helpers";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
export default {
	name: "InvestmentMemo",
	emits: ["is-active"],
	data() {
		return {
			info: {
				title: "Investment Memo",
				description: "",
			},
			v$: useValidate(),
			loading: false,
			investmentMemo: {
				overview: null,
				problem: "",
				solution: "",
				opportunity: "",
				traction: "",
				team: "",
				competitors: [""],
				pitch_url: "",
				pitch_url_title: "Pitch Video",
				documents: [],
			},
			memo: "",
			documents: [],
			showPreview: false,
			link: null,
			uploadDoc: false,
			docUploaded: [],
			docType: null,
			docTypes: ["Pitch Deck", "Investment Memo", "Investment Brochure", "Other"],
		};
	},
	components: {
		DragNDrop,
		DealButton,
		WysiwygEditor,
		Dropdown,
		Accordion,
		InputField,
		PreviewModal,
	},
	inject: ["activeTab"],
	computed: {
		...mapGetters("Asset", ["asset"]),
		...mapGetters("Deals", ["dealInfo"]),
		isFilled() {
			return (
				!Object.values(this.investmentMemo)
					.slice(0, -3)
					.includes(null) &&
				!Object.values(this.investmentMemo)
					.slice(0, -3)
					.includes("")
			);
		},
	},
	watch: {
		asset(val) {
			if (val && val.memo && !this.$route.query.new) {
				this.setValues();
			}
		},
		uploadDoc(val) {
			if (val) {
				this.loading = true;
			}
		},
		"investmentMemo.documents[0].url"(val) {
			if (val && val.length > 1) {
				this.v$.investmentMemo.documents.touch();
			}
		},
		"investmentMemo.documents[1].url"(val) {
			if (val && val.length > 1) {
				this.v$.investmentMemo.documents.touch();
			}
		},
	},
	mounted() {
		if (
			this.asset &&
			this.asset.memo &&
			this.$route.query.assetName &&
			!this.$route.query.new
		) {
			this.setValues();
		} else {
			this.investmentMemo = {
				overview: null,
				problem: null,
				solution: null,
				opportunity: null,
				traction: null,
				team: null,
				competitors: [""],
				pitch_url: null,
				pitch_url_title: "Pitch Video",
				documents: [],
			};
		}
	},
	validations() {
		return {
			investmentMemo: {
				overview: {
					required,
				},
				// problem: {
				//   required,
				// },
				// solution: {
				//   required,
				// },
				// opportunity: {
				//   required,
				// },
				// traction: {
				//   required,
				// },
				// team: {
				//   required,
				// },
				// documents: {
				//   $each: helpers.forEach({
				//     url: {
				//       required,
				//     },
				//     type: {
				//       required,
				//     },
				//     name: {
				//       required,
				//     },
				//     _id: {
				//       requiredIf: requiredIf(minLength(1)),
				//     },
				//   }),
				// },
				// competitors: {
				//   required,
				// },
			},
		};
	},
	methods: {
		...mapActions("Asset", ["updateAsset", "fetchAsset"]),
		setActiveTab(title) {
			this.$emit("is-active", title);
		},
		// pass true if the button is for the new investment payload format
		checkDocumentLength(isNewFormat) {
			const condition = isNewFormat
				? this.documents.length > 0
				: this.investmentMemo.documents.length > 0;
			if (condition) {
				this.uploadDoc = true;
			} else {
				this.uploadInvestmentMemo();
			}
		},
		storeValue(val, index) {
			if (index !== undefined) {
				if (val.name === "competitors") {
					this.investmentMemo.competitors[index] = val.value;
				} else if (val.name === "docType") {
					this.docType = val.value;
					this.investmentMemo.documents[index].type = val.value;
					this.investmentMemo.documents[index].name = val.value;
				} else if (val.name === "memoDoc") {
					this.docType = val.value;
					this.documents[index].type = val.value;
					this.documents[index].name = val.value;
				} else {
					this.investmentMemo.team[index].name = val.value;
				}
			} else if (index === undefined && val.value === "<p></p>") {
				this.investmentMemo[val.name] = "";
			} else if (val.name === "memo") {
				this[val.name] = val.value;
			} else {
				this.investmentMemo[val.name] = val.value;
			}
		},

		setPitchVideo({ value }) {
			this.link = value;
			let videoCode;
			if (value.includes("=")) {
				videoCode = value.split("=");
				if (videoCode.length > 2) {
					videoCode = `https://www.youtube.com/embed/${
						videoCode[1].split("&")[0]
					}`;
				} else {
					videoCode = `https://www.youtube.com/embed/${
						videoCode[videoCode.length - 1]
					}`;
				}
			} else {
				videoCode = value.split("/");
				videoCode = `https://www.youtube.com/embed/${
					videoCode[videoCode.length - 1]
				}`;
			}
			this.investmentMemo.pitch_url = this.link.length > 0 ? videoCode : "";
		},
		addMemberField() {
			this.investmentMemo.team.push({ name: null });
		},
		removeMember(index) {
			this.investmentMemo.team.splice(index, 1);
		},
		// pass true if the button is for the new investment payload format
		storeDocName(index, { value }, isNewFormat) {
			if (isNewFormat) {
				this.documents[index].name = value;
			} else {
				this.investmentMemo.documents[index].name = value;
			}
		},
		addCompetitor() {
			this.investmentMemo.competitors.push("");
		},
		removeCompetitor(index) {
			this.investmentMemo.competitors.splice(index, 1);
		},
		// pass true if the button is for the new investment payload format
		addDocument(isNewFormat) {
			if (isNewFormat) {
				this.documents.push({
					type: "",
					url: "",
					name: "",
				});
			} else {
				this.investmentMemo.documents.push({
					type: "",
					url: "",
					name: "",
				});
			}
		},
		// pass true if the button is for the new investment payload format
		removeDocument(id, isNewFormat) {
			if (isNewFormat) {
				this.documents.splice(id, 1);
			} else {
				this.investmentMemo.documents.splice(id, 1);
			}
		},
		setDocument(val, index, uploadComplete, isNewFormat) {
			const docLength = isNewFormat
				? this.documents.length
				: this.investmentMemo.documents.length;
			if (isNewFormat) {
				this.documents[index].url = val;
			} else {
				this.investmentMemo.documents[index].url = val;
			}
			if (uploadComplete) {
				console.log({ uploadComplete, docLength });
				this.docUploaded.push(true);
				if (this.docUploaded.length === docLength) {
					this.uploadInvestmentMemo();
				}
			}
		},
		setValues() {
			const documents = [];
			this.asset.documents.map((doc) => {
				let obj = {
					type: doc.type,
					url: doc.url,
					_id: doc._id,
					name: doc.name,
				};
				documents.push(obj);
			});
			if (this.asset?.memo.memo) {
				this.memo = this.asset.memo.memo;
				this.documents = documents;
			} else {
				this.investmentMemo.overview = this.asset.memo.overview;
				this.investmentMemo.problem = this.asset.memo.problem;
				this.investmentMemo.solution = this.asset.memo.solution;
				this.investmentMemo.opportunity = this.asset.memo.opportunity;
				this.investmentMemo.traction = this.asset.memo.opportunity;
				this.investmentMemo.team = this.asset.memo.team;
				this.investmentMemo.competitors = this.asset.memo.competitors;
				this.investmentMemo.pitch_url = this.asset.memo.pitch_url;
				this.link = this.asset.memo.pitch_url;
				this.investmentMemo.pitch_url_title = this.asset.memo.pitch_url_title;
				// this.investmentMemo._id = this.asset.memo._id;
				// this.investmentMemo.documents = this.asset.documents
				this.investmentMemo.documents = documents;
			}
		},
		async uploadInvestmentMemo() {
			this.loading = true;
			this.docUploaded = [];
			this.uploadDoc = false;
			let asset = null;
			if (getValueType(this.asset.memo) === "object") {
				asset = this.investmentMemo;
				if (!this.investmentMemo.pitch_url) {
					delete this.investmentMemo.pitch_url;
					delete this.investmentMemo.pitch_url_title;
				}
			}
			asset = { memo: this.memo, documents: this.documents };
			try {
				await this.updateAsset({
					asset: { investment_memo: asset },
					assetId: this.$route.query.id ? this.$route.query.id : this.asset._id,
				});
				this.$emit("is-active", "Address");

				this.fetchAsset(
					this.$route.query.id ? this.$route.query.id : this.asset._id
				);
				this.loading = false;
			} catch (error) {
				this.loading = false;
				return error;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.investment-memo {
	&--long {
		height: 1700px;
		overflow-y: scroll;
	}
	&__button {
		width: 24px;
		height: 24px;
		&:hover {
			background: inherit;
		}
	}
}

.text-grey {
	color: #a3a8b3;
}

.member {
	&__button {
		&:hover {
			background: transparent;
			color: inherit;
		}
	}
}

.required {
	color: indianred;
}

@media (min-width: 768px) {
	.gap {
		gap: 20px;
	}

	.investment-memo {
		&__form {
			gap: 20px;
			padding-left: 0;
			padding-right: 0;
		}
	}
}
@media (min-width: 950px) {
	.investment-memo {
		&-form__div {
			&--half {
				width: 48%;
				max-width: 335px;
			}
		}
	}
}
</style>
