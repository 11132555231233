<template>
  <section>
    <div class="tw-flex tw-justify-between">
      <p class="tw-font-bold tw-text-secondary tw-text-xl">Token Updates</p>
      <button
        @click="showDealUpdate = true"
        class="tw-bg-primary tw-text-white tw-h-10 tw-rounded tw-px-4 tw-ml-auto tw-block"
      >
        Add Update
      </button>
    </div>
    <div
      class="tw-border-t tw-border-[#E1E6F0] tw-mt-6"
      v-if="tokenUpdates && tokenUpdates.length !== 0"
    >
      <div
        class="tw-border-b tw-border-[#E1E6F0] tw-py-8 tw-flex tw-gap-20"
        v-for="update in tokenUpdates"
        :key="update._id"
      >
        <img
          :src="dealInfo.image"
          :alt="dealInfo.name"
          class="tw-h-10 tw-w-10 tw-rounded-full"
        />
        <div>
          <h2
            class="tw-text-secondary tw-font-CircularStd-medium tw-text-lg tw-tracking-[-0.2px] tw-mb-2"
          >
            {{ update.title }}
          </h2>
          <p class="tw-text-sm tw-text-secondary tw-max-w-[700px]">
            {{ update.body }}
          </p>
          <div
            class="tw-text-sm tw-text-black-light tw-mr-12 tw-mt-4 tw-flex tw-gap-x-5"
          >
            <div class="tw-w-20">
              <span v-if="calculateDay(update.updatedAt) >= 1" class="tw-mr-1">
                {{ calculateDay(update.updatedAt) }} days
              </span>
              <span
                v-if="
                  calculateHour(update.updatedAt) >= 1 &&
                    calculateDay(update.updatedAt) < 1
                "
                class="tw-mr-1"
              >
                {{ calculateHour(update.updatedAt) }} hr
              </span>
              <span
                v-if="
                  calculateMin(update.updatedAt) < 59 &&
                    calculateHour(update.updatedAt) < 1 &&
                    calculateDay(update.updatedAt) < 1
                "
              >
                {{ calculateMin(update.updatedAt) }} min
              </span>
              ago
            </div>
            <a
              :href="update.link"
              target="_blank"
              class="tw-text-sm tw-text-[#135BFD] tw-flex tw-gap-x-2 tw-items-center tw-break-words"
            >
              <img src="@/assets/img/link.svg" alt="" /> {{ update.link }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <EmptyState v-else>
      <template v-slot:message>
        <p class="message tw-text-center tw-text-gray tw-my-3">
          No update found for this deal.
        </p>
      </template>
    </EmptyState>
    <DealUpdateModal
      v-if="showDealUpdate"
      @close-modal="showDealUpdate = false"
    />
  </section>
</template>

<script setup>
  import { computed, ref, watch } from "vue";
  import DealUpdateModal from "./DealUpdateModal.vue";
  import EmptyState from "../notification/EmptyState.vue";
  import { useStore } from "vuex";
  import {
    calculateDay,
    calculateHour,
    calculateMin,
  } from "@/utils/helpers.js";

  const store = useStore();

  const showDealUpdate = ref(false);

  const dealInfo = computed(() => store.getters["Deals/dealInfo"]);
  const tokenUpdates = computed(() => store.getters["Asset/tokenUpdate"]);

  const getCompanyUpdates = async () => {
    await store.dispatch("Asset/getTokenUpdate", {
      page: 1,
      limit: 10,
      id: dealInfo.value.token._id,
    });
  };
  watch(showDealUpdate, (value) => {
    if (!value) {
      getCompanyUpdates();
    }
  });
  getCompanyUpdates();
</script>

<style lang="scss" scoped></style>
