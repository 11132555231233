<template>
  <section id="members">
    <Loader v-if="loading" />
    <div v-else class="container tw-px-4">
      <div class="tw-bg-white tw-rounded-lg">
        <div class="tw-w-64 tw-my-10 tw-ml-auto">
          <DealsButton
            text="Add members to this deal"
            @click="$emit('show-member-form', true)"
          />
        </div>
        <div class="tw-p-4 md:tw-px-0">
          <div class="table-container">
            <MembersTable
              :hasStatus="false"
              v-if="dealMembers && dealMembers.length !== 0"
              :tableHeaders="tableHeaders"
              :tableRows="updateTableRow"
              :columnNumber="tableHeaders.length - 2"
              :columnWidth="250"
              :totalPage="1"
              :currentPage="1"
              @gotoPage="null"
              @update-members="getAllMembers"
            />
            <EmptyState v-if="dealMembers && dealMembers.length === 0">
              <template v-slot:message>
                <p
                  v-if="status === 'All members'"
                  class="message tw-text-center tw-text-gray tw-my-3"
                >
                  There are no members on this account.
                </p>
                <p
                  v-if="status === 'Waitlist'"
                  class="message tw-text-center tw-text-gray tw-my-3"
                >
                  There are no pending members on this account.
                </p>
                <p
                  v-if="status === 'Deactivated'"
                  class="message tw-text-center tw-text-gray tw-my-3"
                >
                  There are no deactivated members on this account.
                </p>
              </template>
            </EmptyState>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import MembersTable from "@/components/tables/DealMembersTable";
  import EmptyState from "@/components/notification/EmptyState";
  import { formatISOTime, formatISODate, truncate } from "@/utils/helpers.js";
  import { mapGetters, mapActions } from "vuex";
  import DealsButton from "@/components/deals/DealsButton";
  export default {
    name: "Members",
    components: {
      MembersTable,
      EmptyState,
      DealsButton,
    },
    data() {
      return {
        loading: false,
        tableHeaders: ["Name", "Email", "Date Joined", "Action", "id"],
        tableRows: [],
      };
    },
    emits: ["show-member-form", "add-member-form"],
    props: {
      tokenId: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapGetters("Deals", ["dealMembers"]),

      updateTableRow() {
        let updateTableRow = null;
        updateTableRow = this.dealMembers.map((member) => {
          let name = `${member.user.fname} ${member.user.lname}`;
          return {
            name: name,
            email: truncate(member.user.email, 25),
            joined: `${formatISODate(member.createdAt)}  ${formatISOTime(
              member.createdAt
            )}`,
            id: `data-id/${member._id}/${member.user._id}`,
          };
        });
        return updateTableRow;
      },
    },
    mounted() {
      if (this.tokenId) {
        this.getAllMembers();
      }
    },

    methods: {
      ...mapActions("Deals", ["fetchDealMembers"]),
      async getAllMembers() {
        this.loading = true;
        try {
          await this.fetchDealMembers(this.tokenId);
          this.loading = false;
        } catch (error) {
          this.loading = false;
          return error;
        }
      },
    },

    watch: {},
  };
</script>

<style lang="scss">
  #members {
    width: 100%;
    overflow: hidden;

    .container {
      @include fadeIn;

      @media screen and (min-width: 1024px) {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .table-container {
      overflow: auto;
    }

    .message {
      max-width: 250px;
    }
  }
</style>
