<template>
  <SideModal
    title="Add Deal Update"
    @close="$emit('close-modal')"
    :showBackButton="false"
  >
    <template v-slot:content>
      <form
        @submit.prevent="checkFormValidation"
        class="tw-w-full tw-max-w-[375px] tw-mx-auto"
      >
        <InputField
          class="tw-mb-6"
          name="title"
          label="Title"
          :validate="validate"
          @input-changed="setUpdate"
          @is-valid="handleValidation"
        />
        <TextField
          class="tw-mb-6"
          height="tw-h-40"
          :validate="validate"
          name="body"
          label="Update"
          @input-changed="setUpdate"
          @is-valid="handleValidation"
        />
        <p class="tw-font-bold tw-text-black">Add additional resource</p>
        <div class="tw-flex tw-gap-5 tw-mt-3 tw-mb-4">
          <label
            class="tw-cursor-pointer"
            :for="resource"
            v-for="(resource, index) in resourceTypes"
            :key="index"
          >
            <input
              type="radio"
              v-model="resourceType"
              name="resource"
              :id="resource"
              :value="resource"
            />
            {{ resource }}</label
          >
        </div>
        <LinkInput
          v-if="resourceType === 'Link'"
          class="tw-mb-6"
          name="link"
          label="Document link"
          :validate="validate"
          @input-changed="setUpdate"
          @is-valid="handleValidation"
        />
        <DragNDrop
          v-if="resourceType === 'Document'"
          name="link"
          accept=".pdf"
          :startUpload="uploadDoc"
          @is-valid="handleValidation"
          @re-upload="uploadDoc = false"
          @file-added="setDoc($event, index)"
          @upload-complete="setDocUrl($event, index)"
          :required="resourceType === 'Document'"
          :validate="validate"
          class="tw-mb-8"
        />
        <CustomButton title="Submit" :loading="smallLoading || uploadDoc" />
      </form>
    </template>
  </SideModal>
</template>

<script setup>
  import { computed, reactive, ref, watch } from "vue";
  import { useStore } from "vuex";
  import { useValidation } from "@/composables/formValidation";
  import SideModal from "../general/sidemodal/SideModal.vue";
  import InputField from "../general/InputField.vue";
  import TextField from "../general/TextField.vue";
  import LinkInput from "../general/LinkInput.vue";
  import DragNDrop from "../general/DragNDrop.vue";
  import CustomButton from "../general/BtnComponent.vue";

  const emit = defineEmits(["close-modal"]);

  const store = useStore();

  const {
    fields,
    handleValidation,
    checkFormValidation,
    validate,
    canSubmit,
  } = useValidation();

  fields.value = 3;

  const payload = reactive({
    title: null,
    body: null,
    link: null,
    private: false,
    type: "Organisation",
  });
  const resourceType = ref("Link");
  const uploadDoc = ref(false);
  const resourceTypes = ref(["Link", "Document"]);

  const dealInfo = computed(() => store.getters["Deals/dealInfo"]);
  const smallLoading = computed(() => store.getters["smallLoading"]);

  const setDoc = (doc) => {
    payload.link = doc;
  };
  const setUpdate = ({ name, value }) => {
    payload[name] = value;
  };

  const setDocUrl = (url) => {
    setDoc(url);
    addDealUpdate();
  };

  const addDealUpdate = async () => {
    const id = dealInfo.value.token._id;
    const response = await store.dispatch("Deals/addDealUpdate", {
      id,
      payload,
    });
    const { message } = response.data;
    store.dispatch("showToast", {
      description: message,
      display: true,
      type: "success",
    });
    emit("close-modal");
  };

  watch(canSubmit, (value) => {
    if (value && resourceType.value === "Document") {
      uploadDoc.value = true;
    } else {
      addDealUpdate();
    }
  });
</script>

<style lang="scss" scoped></style>
