<template>
  <SmallModal @close="$emit('close-modal')">
    <template v-slot:content>
      <div class="tw-mx-auto tw-max-w-[300px]">
        <img
          src="https://res.cloudinary.com/djalafcj9/image/upload/v1667563098/getequityV2/Group_35_spfcdv.png"
          class="tw-w-24 tw-h-24 tw-block tw-mx-auto tw-mb-6"
        />
        <h1
          class="tw-text-secondary tw-mb-5 tw-text-xl tw-font-CircularStd-medium tw-font-medium tw-text-center"
        >
          SPV linked successfully
        </h1>
        <p
          class="tw-text-base tw-text-black-light tw-leading-6 tw-text-center tw-font-normal tw-mb-8"
        >
          {{ text }}
        </p>
        <button
          class="tw-text-secondary tw-w-full tw-rounded tw-bg-blue-bg200 tw-h-12"
          @click="$emit('close-modal')"
        >
          Done
        </button>
      </div>
    </template>
  </SmallModal>
</template>

<script setup>
  import SmallModal from "../general/SmallModal.vue";

  defineEmits(["close-modal"]);

  const { text } = defineProps({
    text: {
      type: String,
      required: false,
      default: "Good job! We will take it from here. Just sit back and relax.",
    },
  });
</script>

<style lang="scss" scoped></style>
